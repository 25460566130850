import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import Select, { MultiValue } from 'react-select';
import Creatable from 'react-select/creatable';
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';
import '../../../../recruiter.scss';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import {
  clearListMETAReq,
  clearMetaPinCodeReq,
  editJobsReq,
  getMetaPinCodeReq,
  getStaffClientListReq,
  listMETAReq,
  uploadS3FilesReq,
} from '../../../../../../../../store/campusXcel/actions';
import {
  AddonPerkType,
  AmountTpye,
  APIHeader, ApiLIMIT, ListExpRange1, ListExpRange2, ListJobSchedule, ListJobType, ListVacancies, numberToWords, PayStructure, RecruitmentTimeline, SuppPayType, UserType,
} from '../../../../../../../../utils/constants';
import { ReviewPayPhase6TypeProps } from '../../../../recruiter.type';
import { ListStaffClientResultData } from '../../../../../../../../services/staffing/clients/list-clients/list-client.types';
import { UploadS3FilesDataInput } from '../../../../../../../../services/common/upload-s3-files/upload-s3-files.types';
import { getConfig } from '../../../../../../../../utils/config/config';
import { AllMETAResult } from '../../../../../../../../services/common/metadata-db/list-meta.types';
import { errorAlert } from '../../../../../../../../utils/alert';

const { AWS_CXUSER_BUCKET } = getConfig();

export const ReviewJobPhase6: React.FC<ReviewPayPhase6TypeProps> = ({
  // jobPhase,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  previewPh6,
  setPreviewPh6,
  apiReqTp,
  setApiReqTp,
  reqClientId,
  setLoadingHomeJob,
  setOperationTp,
  addJobRes,
  setAddJobRes,
}) => {
  const dispatch = useDispatch();
  const [initJobKeyword, setInitJobKeyword] = React.useState('');
  const [isSuppPay, setIsSuppPay] = React.useState(false);
  const [isOPerk, setIsOPerk] = React.useState(false);
  const [initOPerk, setInitOPerk] = React.useState('');
  const [initOSpay, setInitOSpay] = React.useState('');
  const [loadingMeta, setLoadingMeta] = React.useState(false);
  const [whichAPI, setWhichAPI] = React.useState('');
  const [showErrorsAlertRange, setErrorShowAlertRange] = React.useState(false);
  const [alertRangeMsg, setAlertRangeMsg] = React.useState('');
  const getMetaInfoResponse = useSelector((state: RootState) => state.campusXcel.listMetaData);
  const [skillListData, setSkillListData] = React.useState<AllMETAResult[]>([]);
  const [oSkillListData, setOSkillListData] = React.useState<AllMETAResult[]>([]);
  const [designationListData, setDesignationListData] = React.useState<AllMETAResult[]>([]);
  const [, setMultiSuppTp] = React.useState<MultiValue<{ label: string; value: string; }>>([]);
  const [, setMultiPerkTp] = React.useState<MultiValue<{ label: string; value: string; }>>([]);
  const [loadingPin, setLoadingPin] = React.useState(false);
  const getPinResponse = useSelector((state: RootState) => state.campusXcel.getMetaPinCode);
  const [previewViewMode, setPreviewViewMode] = React.useState(true);
  const [loadingClient, setLoadingClient] = React.useState(false);
  const [clientListData, setClientListData] = React.useState<ListStaffClientResultData[]>([]);
  const [, setMultiJobTp] = React.useState<MultiValue<{ label: string; value: string; }>>([]);
  const getClientListResponse = useSelector((state: RootState) => state.campusXcel.getStaffClientList);
  const updateJobPhase6Response = useSelector((state: RootState) => state.campusXcel.updateJob);
  const [htmlContent, setHtmlContent] = React.useState('');
  const uploadJDS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const [jDHtml, setJDHtml] = React.useState<UploadS3FilesDataInput>({
    fileData: new FormData(),
  });

  const handleEditorEvent = (content: string) => {
    setHtmlContent(content);
    const fileName = `${previewPh6.JobId}_JD.html`;
    const formData = new FormData();
    formData.append(fileName, new Blob([content], { type: 'text/html' }));
    setJDHtml({ ...jDHtml, fileData: formData });
  };

  React.useEffect(() => {
    const fetchHtmlContent = async () => {
      if (previewPh6.jdFileUrl) {
        try {
          const response = await fetch(previewPh6.jdFileUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const text = await response.text();
          setHtmlContent(text);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch HTML content:', error);
        }
      }
    };

    fetchHtmlContent();
  }, [previewPh6.jdFileUrl]);

  const handleEditMode = () => {
    setPreviewViewMode(true);
  };
  const handleViewMode = () => {
    setPreviewViewMode(false);
  };

  const handleChangePref = (event: any) => {
    setPreviewPh6({ ...previewPh6, [event.target.name]: event.target.value });
  };

  const handleCheckPlndDt = (status: boolean) => {
    setPreviewPh6({ ...previewPh6, isPlndDt: status });
  };

  // Handle ALl Input Request Data - Start
  React.useEffect(() => {
    if (previewPh6.forClnt !== '' && reqClientId !== '' && (apiReqTp === 'NEW-ADD-REQ-TO-PH6' || apiReqTp === 'PSTG6-req')) {
      setLoadingClient(true);
      dispatch(getStaffClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.RCRT,
        limit: 50,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: reqClientId,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [previewPh6.forClnt, reqClientId, apiReqTp]);

  const handleInputClientChange = (e:string) => {
    if (e) {
      dispatch(getStaffClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.RCRT,
        limit: 50,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: e,
        isSearch: 'YES',
        token: tokenData,
        clntTp: reqClientId,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  };
  const handleSelectClientData = (selectedOption: any) => {
    if (selectedOption) {
      setPreviewPh6({
        ...previewPh6,
        forClnt: selectedOption.value,
      });
    }
  };

  React.useEffect(() => {
    if (!getClientListResponse.isError && getClientListResponse.message === 'executed' && previewPh6.forClnt !== '' && reqClientId !== '' && (apiReqTp === 'NEW-ADD-REQ-TO-PH6' || apiReqTp === 'PSTG6-req')) {
      setClientListData(getClientListResponse.data.clntData);
      setLoadingClient(false);
    }
  }, [getClientListResponse.isError, getClientListResponse.message, previewPh6.forClnt, reqClientId, apiReqTp]);

  const clientNameoptions = clientListData.map((client) => ({
    label: `${client.nme}`,
    value: `${client.ClntId}`,
  }));

  const selectedClientNameOptions = clientNameoptions && clientNameoptions.find(
    (option) => (option.value === `${previewPh6.forClnt}`),
  );
  const handleCallPincodeChange = (event: any) => {
    if (event.target.value.length === 6) {
      setLoadingPin(true);
      dispatch(getMetaPinCodeReq({
        uTp: UserType.RCRT,
        token: tokenData,
        requestType: APIHeader.REQ_META_CITY_PIN,
        limit: ApiLIMIT.LIMIT,
        pinCode: event.target.value,
        cntryCd: 'IN',
      }));
    }
    setPreviewPh6({ ...previewPh6, pCd: event.target.value });
  };
  React.useEffect(() => {
    if (!getPinResponse.isError && getPinResponse.message === 'executed' && (apiReqTp === 'NEW-ADD-REQ-TO-PH6' || apiReqTp === 'PSTG6-req')) {
      setLoadingPin(false);
      if (getPinResponse.data.pin !== '') {
        setPreviewPh6({
          ...previewPh6, pCd: getPinResponse.data.pin, city: getPinResponse.data.cty, st: getPinResponse.data.st,
        });
      }

      dispatch(clearMetaPinCodeReq());
    }
  }, [getPinResponse.isError, getPinResponse.data.pin, getPinResponse.message]);

  const jobTpNameoptions = ListJobType.map((jobtp) => ({
    label: jobtp.label,
    value: jobtp.value,
  }));

  const handleAllJobType = () => previewPh6.jobTp.map((item) => {
    const foundOption = jobTpNameoptions.find((option) => option.value === item);
    return foundOption ? { label: foundOption.label, value: foundOption.value } : { label: item, value: item };
  });

  const handleSelectJobType = (e: MultiValue<{ label: string; value: string; }>) => {
    if (e && e.length) {
      const jobTypesV = e.map(({ value }) => value);
      setPreviewPh6({ ...previewPh6, jobTp: jobTypesV });
      setMultiJobTp(e);
    } else {
      setPreviewPh6({ ...previewPh6, jobTp: [] });
      setMultiJobTp([]);
    }
  };
  const scheduleNameoptions = ListJobSchedule.map((schedule) => ({
    label: schedule.label,
    value: schedule.value,
  }));

  const selectedScheduleNameOptions = scheduleNameoptions && scheduleNameoptions.find(
    (option) => (option.value === previewPh6.schdl),
  );

  const handleSelectScheduleData = (selectedOption: any) => {
    if (selectedOption) {
      setPreviewPh6({
        ...previewPh6,
        schdl: selectedOption.value,
      });
    }
  };

  const vacancyNameoptions = ListVacancies.map((vacancy) => ({
    value: vacancy.value,
    label: vacancy.label,
  }));

  const selectedVacancyNameOptions = vacancyNameoptions && vacancyNameoptions.find(
    (option) => (option.value === previewPh6.vacncy),
  );

  const handleSelectVacancyData = (selectedOption: any) => {
    if (selectedOption) {
      setPreviewPh6({
        ...previewPh6,
        vacncy: selectedOption.value,
      });
    }
  };
  const recruitTLNameoptions = RecruitmentTimeline.map((rcrtTL) => ({
    label: rcrtTL.label,
    value: rcrtTL.value,
  }));

  const selectedrecruitTLNameoptions = recruitTLNameoptions && recruitTLNameoptions.find(
    (option) => (option.value === previewPh6.timelne),
  );
  const handleSelectRecruitTimeline = (selectedOption: any) => {
    if (selectedOption) {
      setPreviewPh6({
        ...previewPh6,
        timelne: selectedOption.value,
      });
    }
  };
  const payNameoptions = PayStructure.map((pay) => ({
    label: pay.label,
    value: pay.value,
  }));

  const selectedPayNameOptions = payNameoptions && payNameoptions.find(
    (option) => (option.value === previewPh6.payBy),
  );
  const handleSelectPayData = (selectedOption: any) => {
    if (selectedOption) {
      setPreviewPh6({
        ...previewPh6,
        payBy: selectedOption.value,
      });
    }
  };
  const amountNameoptions = AmountTpye.map((amnt) => ({
    label: amnt.label,
    value: amnt.value,
  }));

  const selectedAmntNameOptions = amountNameoptions && amountNameoptions.find(
    (option) => (option.value === previewPh6.amntTp),
  );

  const handleSelectAmountTypeData = (selectedOption: any) => {
    if (selectedOption) {
      setPreviewPh6({
        ...previewPh6,
        amntTp: selectedOption.value,
      });
    }
  };

  const suppPayNameoptions = SuppPayType.map((suppPay) => ({
    label: suppPay.label,
    value: suppPay.value,
  }));

  const handleSelectSuppPayType = () => previewPh6.suppPay.map((item) => {
    const foundOption = suppPayNameoptions.find((option) => option.value === item);
    return foundOption ? { label: foundOption.label, value: foundOption.value } : { label: item, value: item };
  });

  const handleAllSuppPay = (e: MultiValue<{ label: string; value: string; }>) => {
    if (e && e.length) {
      const suppPayV = e.map(({ value }) => value);
      const hasOthersPay = suppPayV.includes('OTRS');
      const updatedOSPay = hasOthersPay ? [] : previewPh6.oSuppPay;

      setPreviewPh6({
        ...previewPh6,
        suppPay: suppPayV,
        oSuppPay: updatedOSPay,
      });
      setMultiSuppTp(e);
      setIsSuppPay(hasOthersPay);
    } else {
      setPreviewPh6({
        ...previewPh6,
        suppPay: [],
        oSuppPay: [],
      });
      setMultiSuppTp([]);
      setIsSuppPay(false);
    }
  };

  const handleKeyPressOSPay = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedOSpay = initOSpay.trim();
      if (trimmedOSpay) {
        const newOSpay = trimmedOSpay.split(',')
          .map((oSpay) => oSpay.trim())
          .filter((oSpay) => oSpay.length > 0);

        if (newOSpay.length > 0) {
          setPreviewPh6({
            ...previewPh6,
            oSuppPay: [...previewPh6.oSuppPay, ...newOSpay],
          });
        }
        setInitOSpay('');
      }
    }
  };

  const removeOSPay = (oSpayToRemove: string) => {
    setPreviewPh6({
      ...previewPh6,
      oSuppPay: previewPh6.oSuppPay.filter((oSpay) => oSpay !== oSpayToRemove),
    });
  };

  const handleChangeUpdateOSPay = (e: any) => {
    setInitOSpay(e.target.value);
  };
  const perkNameoptions = AddonPerkType.map((perk) => ({
    label: perk.label,
    value: perk.value,
  }));

  const handleSelectPerkType = () => previewPh6.perk.map((item) => {
    const foundOption = perkNameoptions.find((option) => option.value === item);
    return foundOption ? { label: foundOption.label, value: foundOption.value } : { label: item, value: item };
  });

  const handleAllPerk = (e: MultiValue<{ label: string; value: string; }>) => {
    if (e && e.length) {
      const perkV = e.map(({ value }) => value);
      const hasOthers = perkV.includes('OTRS');
      const updatedOSPay = hasOthers ? [] : previewPh6.oPerk;

      setPreviewPh6({
        ...previewPh6,
        perk: perkV,
        oPerk: updatedOSPay,
      });
      setMultiPerkTp(e);
      setIsOPerk(hasOthers);
    } else {
      setPreviewPh6({
        ...previewPh6,
        perk: [],
        oPerk: [],
      });
      setMultiPerkTp([]);
      setIsOPerk(false);
    }
  };

  const handleChangeUpdatePerk = (e: any) => {
    setInitOPerk(e.target.value);
  };

  const handleKeyPress = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && initOPerk.trim()) {
      e.preventDefault();
      const newPerks = initOPerk.split(',').map((perk) => perk.trim());
      setPreviewPh6({
        ...previewPh6,
        oPerk: [...previewPh6.oPerk, ...newPerks],
        // oPerk: '', // Clear input after adding
      });
      setInitOPerk('');
    }
  };

  const removePerk = (perkToRemove: string) => {
    setPreviewPh6({
      ...previewPh6,
      oPerk: previewPh6.oPerk.filter((perk) => perk !== perkToRemove),
    });
  };
  // Handle ALl Input Request Data - End
  const handleUpdatePhase6Final = (e: any) => {
    e.preventDefault();
    if (previewPh6.JobCd !== '' && previewPh6.JobId !== '') {
      setLoadingHomeJob(true);
      const isFileChanged = jDHtml.fileData.has(`${previewPh6.JobId}_JD.html`);
      if (!isFileChanged) {
        const filterURLDet = { ...previewPh6 };
        delete filterURLDet.jdFileUrl;
        dispatch(editJobsReq({
          inputBody: filterURLDet,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PREVIEW',
        }));
      } else {
        setLoadingHomeJob(true);
        dispatch(uploadS3FilesReq({
          bucketNme: AWS_CXUSER_BUCKET,
          folderName: `staff-jobs/${previewPh6.JobCd}/${previewPh6.JobId}`,
          fileNmeInit: `${previewPh6.JobId}_JD`,
          formData: jDHtml.fileData,
          requestType: '',
          isEdit: false,
        }));
      }
    }
  };

  React.useEffect(() => {
    if (
      uploadJDS3AwsResponse.fileName !== ''
      && previewPh6.JobCd !== '' && previewPh6.JobId !== ''
      && (apiReqTp === 'NEW-ADD-REQ-TO-PH6' || apiReqTp === 'PSTG6-req')
    ) {
      setLoadingHomeJob(false);
      const extractedFileName = uploadJDS3AwsResponse.fileName.split('/').pop();
      const updatedJDHtmlAndFile = {
        ...previewPh6,
        jdFile: extractedFileName || '',
      };

      setPreviewPh6(updatedJDHtmlAndFile);

      if (extractedFileName) {
        const filteredJDHtmlAndFile = { ...updatedJDHtmlAndFile };
        delete filteredJDHtmlAndFile.jdFileUrl;
        setLoadingHomeJob(true);
        dispatch(editJobsReq({
          inputBody: filteredJDHtmlAndFile,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PREVIEW',
        }));
      }
    }
  }, [uploadJDS3AwsResponse.fileName, apiReqTp, previewPh6.JobCd, previewPh6.JobId]);

  React.useEffect(() => {
    if (updateJobPhase6Response.error && updateJobPhase6Response.message !== '') {
      setLoadingJob(false);
      setAlertMsg(updateJobPhase6Response.message);
      setErrorShowAlert(true);
    }
  }, [updateJobPhase6Response.error, updateJobPhase6Response.message]);

  React.useEffect(() => {
    if (!updateJobPhase6Response.error && updateJobPhase6Response.data.JobCd !== '' && updateJobPhase6Response.data.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH6') {
      setLoadingJob(false);
      setApiReqTp('');
      setOperationTp('list-jobs');
      setLoadingHomeJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: '',
        JobId: '',
        NxtStg: '',
      });
    }
  }, [updateJobPhase6Response.error, updateJobPhase6Response.data.JobCd, updateJobPhase6Response.data.JobId]);

  React.useEffect(() => {
    if (!updateJobPhase6Response.error && updateJobPhase6Response.data.JobCd !== '' && updateJobPhase6Response.data.JobId !== '' && (apiReqTp === 'EDIT-BACK-REQ-TO-PH6' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH6' || apiReqTp === 'PSTG6-req')) {
      setLoadingJob(false);
      setApiReqTp('');
      setOperationTp('list-jobs');
      setAddJobRes({
        ...addJobRes,
        JobCd: '',
        JobId: '',
        NxtStg: '',
      });
      setLoadingHomeJob(false);
    }
  }, [updateJobPhase6Response.error, updateJobPhase6Response.data.JobCd, updateJobPhase6Response.data.JobId, apiReqTp]);

  // Handle Job Keyword

  const handleKeyPressJobKeyword = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && initJobKeyword.trim()) {
      e.preventDefault();
      const newJobKeyword = initJobKeyword.split(',').map((oSpay) => oSpay.trim());
      setPreviewPh6({
        ...previewPh6,
        jobKey: [...previewPh6.jobKey, ...newJobKeyword],
      });
      setInitJobKeyword('');
    }
  };

  const removeJobKeyword = (jobKeyToRemove: string) => {
    setPreviewPh6({
      ...previewPh6,
      jobKey: previewPh6.jobKey.filter((jobKey) => jobKey !== jobKeyToRemove),
    });
  };

  const handleChangeUpdateJobKeyword = (e: any) => {
    setInitJobKeyword(e.target.value);
  };

  const handleChangeIsFemale = (event: any) => {
    if (event.target.checked) {
      setPreviewPh6({ ...previewPh6, isFemale: 'YES' });
    } else {
      setPreviewPh6({ ...previewPh6, isFemale: 'NO' });
    }
  };

  const handleChangeIsAbled = (event: any) => {
    if (event.target.checked) {
      setPreviewPh6({ ...previewPh6, isAbled: 'YES' });
    } else {
      setPreviewPh6({ ...previewPh6, isAbled: 'NO' });
    }
  };
  // Handle Job Keyword

  // Skills and Designation - Start
  const handleGetDesignationChange = (event: any) => {
    if (event.length > 2) {
      setWhichAPI('DESG');
      setLoadingMeta(true);
      dispatch(listMETAReq({
        searchBy: event,
        isSearch: 'YES',
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'DESG',
        token: tokenData,
      }));
    }
  };

  const desigNameoptions = designationListData.map((desg) => ({
    label: `${desg.nme}`,
    value: `${desg.nme}`,
  }));

  const selectedDesgNameOptions = desigNameoptions && desigNameoptions.find(
    (option) => option.value.trim() === previewPh6.desg.trim(),
  );

  const handleGetSkillChange = (event: any) => {
    if (event.length > 2) {
      setWhichAPI('SKILLS');
      setLoadingMeta(true);
      dispatch(listMETAReq({
        searchBy: event,
        isSearch: 'YES',
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'SKILL',
        token: tokenData,
      }));
    }
  };

  const handleGetOSkillChange = (event: any) => {
    if (event.length > 2) {
      setWhichAPI('OSKILLS');
      setLoadingMeta(true);
      dispatch(listMETAReq({
        searchBy: event,
        isSearch: 'YES',
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'SKILL',
        token: tokenData,
      }));
    }
  };

  React.useEffect(() => {
    if (!getMetaInfoResponse.isError && getMetaInfoResponse.message === 'executed') {
      setLoadingMeta(false);
      if (whichAPI === 'SKILLS') {
        setSkillListData((prevData) => {
          const existingNames = new Set(prevData.map((record) => record.nme));
          const uniqueNewData = getMetaInfoResponse.data.filter((record) => !existingNames.has(record.nme));
          return [...prevData, ...uniqueNewData];
        });
      } if (whichAPI === 'OSKILLS') {
        setOSkillListData((prevData) => {
          const existingOSkillNames = new Set(prevData.map((record) => record.nme));
          const uniqueNewOSkillData = getMetaInfoResponse.data.filter((record) => !existingOSkillNames.has(record.nme));
          return [...prevData, ...uniqueNewOSkillData];
        });
      } if (whichAPI === 'DESG') {
        setDesignationListData((prevData) => {
          const existingNames = new Set(prevData.map((record) => record.nme));
          const uniqueNewData = getMetaInfoResponse.data.filter((record) => !existingNames.has(record.nme));
          return [...prevData, ...uniqueNewData];
        });
      }
      dispatch(clearListMETAReq());
    }
  }, [getMetaInfoResponse.isError, getMetaInfoResponse.message]);

  const skillNameoptions = skillListData.map((skill) => ({
    label: `${skill.nme}`,
    value: `${skill.nme}`,
  }));

  const handleSelectDesgData = (e: any) => {
    if (e && e.value) {
      setPreviewPh6({ ...previewPh6, desg: e.value });
    } else {
      setPreviewPh6({ ...previewPh6, desg: '' });
    }
  };

  const handleSelectSkillData = (e: MultiValue<{ label: string; value: string; }> | null) => {
    let updatedSkill: string[] = [];
    if (e && e.length) {
      updatedSkill = e.map(({ label }) => label);
    }
    setPreviewPh6({ ...previewPh6, skills: updatedSkill });
  };

  const skillsList = previewPh6.skills.map((item) => ({ label: item, value: item }));

  const oSkillNameoptions = oSkillListData.map((oSkill) => ({
    label: `${oSkill.nme}`,
    value: `${oSkill.nme}`,
  }));

  const handleSelectOSkillData = (e: MultiValue<{ label: string; value: string; }> | null) => {
    let updatedOSkill: string[] = [];
    if (e && e.length) {
      updatedOSkill = e.map(({ label }) => label);
    }
    setPreviewPh6({ ...previewPh6, oSkills: updatedOSkill });
  };

  const oSkillsList = previewPh6.oSkills.map((item) => ({ label: item, value: item }));
  // Skills and Designation - End

  const handleCheckIsCV = (status: boolean) => {
    setPreviewPh6({ ...previewPh6, isCV: status });
  };

  const handleCheckIsAppTlne = (status: boolean) => {
    setPreviewPh6({ ...previewPh6, isAppTlne: status });
  };

  // Handle Experience Range Start
  const expRange1Nameoptions = ListExpRange1.map((expR1) => ({
    value: expR1.value,
    label: expR1.label,
  }));

  const selectedExpRange1Nameoptions = expRange1Nameoptions && expRange1Nameoptions.find(
    (option) => (option.value === previewPh6.expR1),
  );

  const expRange2Nameoptions = ListExpRange2.map((expR2) => ({
    value: expR2.value,
    label: expR2.label,
  }));

  const selectedExpRange2Nameoptions = expRange2Nameoptions && expRange2Nameoptions.find(
    (option) => (option.value === previewPh6.expR2),
  );

  const handleSelectExpRange1Data = (selectedOption: any) => {
    if (selectedOption) {
      const updatedExpR1 = selectedOption.value;
      // Check if expR2 is already set and is less than or equal to the newly selected expR1
      if (previewPh6.expR2 && previewPh6.expR2 <= updatedExpR1) {
        setErrorShowAlertRange(true);
        setAlertRangeMsg('Experience range is not correct');
      } else {
        // Update expR1 if conditions are met
        setPreviewPh6({
          ...previewPh6,
          expR1: updatedExpR1,
        });
      }
    }
  };

  const handleSelectExpRange2Data = (selectedOption: any) => {
    if (selectedOption) {
      const updatedExpR2 = selectedOption.value;
      // Check if expR1 is already set and expR2 is greater than expR1
      if (previewPh6.expR1 && updatedExpR2 <= previewPh6.expR1) {
        setErrorShowAlertRange(true);
        setAlertRangeMsg('Experience range is not correct');
      } else {
        // Update expR2 if conditions are met
        setPreviewPh6({
          ...previewPh6,
          expR2: updatedExpR2,
        });
      }
    }
  };
  // Handle Experience Range End
  return (
    <div className="my-1">
      {showErrorsAlertRange ? (
        errorAlert(false, alertRangeMsg, showErrorsAlertRange, setErrorShowAlertRange)
      ) : null}
      <div className="pt-2 mb-3 align-center">
        <span className="text-lite-grey-sub">Job Preview</span>
      </div>
      <Form className="job-add-card" onSubmit={handleUpdatePhase6Final}>
        <div className="cx-jobs-dashed-card-main mb-3">
          <Row className="align-center mx-1">
            <div className="pt-2 align-center">
              <span className="text-lite-grey-sub">Basic Details</span>
            </div>
            <Col xs="12" lg="11">
              <div className="pt-4">
                <span className="text-lite align-start ml">Job Title</span>
                <Input
                  placeholder="Enter Job Title"
                  type="text"
                  name="nme"
                  disabled={previewViewMode}
                  value={previewPh6.nme}
                  onChange={handleChangePref}
                  className="jobs-campus-input"
                />
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 mx-2">
                <span className="text-lite align-start ml">Which one best describes Work Preference ?</span>
                <Row className="pt-2 collapse-bg">
                  <Col lg="4" xs="12">
                    <div className="align-start my-1">
                      <Input
                        type="radio"
                        name="wrkPref"
                        disabled={previewViewMode}
                        value="CLNT"
                        checked={previewPh6.wrkPref === 'CLNT'}
                        className="campus-job-radio-box cursor-pointer"
                        onChange={handleChangePref}
                      />
                      <Label className="jobs-check-text ml">From Office</Label>
                    </div>
                  </Col>
                  <Col lg="4" xs="12">
                    <div className="align-start my-1">
                      <Input
                        type="radio"
                        name="wrkPref"
                        disabled={previewViewMode}
                        value="HOME"
                        checked={previewPh6.wrkPref === 'HOME'}
                        className="campus-job-radio-box cursor-pointer"
                        onChange={handleChangePref}
                      />
                      <Label className="jobs-check-text ml">From Home</Label>
                    </div>
                  </Col>
                  <Col lg="4" xs="12">
                    <div className="align-start my-1">
                      <Input
                        type="radio"
                        name="wrkPref"
                        disabled={previewViewMode}
                        value="HYBRD"
                        checked={previewPh6.wrkPref === 'HYBRD'}
                        className="campus-job-radio-box cursor-pointer"
                        onChange={handleChangePref}
                      />
                      <Label className="jobs-check-text ml">Hybrid</Label>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs="12" lg="11">
              <Row>
                <Col xs="12" lg="6">
                  <div className="pt-4 mx-1">
                    <span className="text-lite align-start ml">Client Name</span>
                    <Select
                      value={previewPh6.forClnt === '' ? null : selectedClientNameOptions}
                      options={clientNameoptions}
                      onChange={handleSelectClientData}
                      onInputChange={handleInputClientChange}
                      isDisabled={previewViewMode}
                      styles={{
                        singleValue: (base: any, state: any) => ({
                          ...base,
                          color: state.isDisabled ? '#383838' : base.color, // Ensure selected value's text color changes when disabled
                        }),
                        control: (base: any, state: any) => ({
                          ...base,
                          // '&:disabled': { color: '#575656' },
                          '&:hover': { borderColor: '#8B0000' },
                          border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                          backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                          minHeight: '45px',
                          borderRadius: '7px',
                          padding: 1,
                          textAlign: 'left',
                          fontSize: '15px',
                          fontWeight: 400,
                          // opacity: state.isDisabled ? 0.9 : 1,
                          color: '#575656',
                          boxShadow: 'none',
                          '&:focus': {
                            borderColor: '#E1EDF8',
                            boxShadow: 'none',
                            color: '#575656',
                            background: '#fff',
                          },
                        }),
                        option: (provided: any, state: { isSelected: any; }) => ({
                          ...provided,
                          color: state.isSelected ? '#383838' : '#212121',
                          padding: 15,
                          textAlign: 'left',
                          background: state.isSelected ? '#FAFCFB' : '#fff',
                          '&:hover': {
                            background: '#FAFCFB',
                          },
                        }),
                      }}
                      isLoading={loadingClient}
                      noOptionsMessage={() => 'No Client Found. Add New'}
                      placeholder="Select Client..."
                    />
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="pt-4 mx-1">
                    <span className="text-lite align-start ml">Pincode</span>
                    <Input
                      placeholder="Enter Pincode"
                      type="text"
                      name="pCd"
                      disabled={previewViewMode}
                      value={previewPh6.pCd}
                      onChange={handleCallPincodeChange}
                      className="jobs-campus-input"
                    />
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="pt-4 mx-1">
                    <span className="text-lite align-start ml">City</span>
                    <Input
                      placeholder="Enter City"
                      type="text"
                      name="city"
                      disabled={previewViewMode || loadingPin}
                      value={previewPh6.city}
                      onChange={handleChangePref}
                      className="jobs-campus-input"
                    />
                    {loadingPin && (
                    <div className="dot-loader align-center pt-1">
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                    </div>
                    )}
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="pt-4 mx-1">
                    <span className="text-lite align-start ml">State</span>
                    <Input
                      placeholder="Enter State"
                      type="text"
                      name="st"
                      disabled={previewViewMode || loadingPin}
                      value={previewPh6.st}
                      onChange={handleChangePref}
                      className="jobs-campus-input"
                    />
                    {loadingPin && (
                    <div className="dot-loader align-center pt-1">
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                    </div>
                    )}
                  </div>
                </Col>
                <Col xs="12" lg="12">
                  <div className="pt-4">
                    <span className="text-lite align-start ml">Address</span>
                    <Input
                      placeholder="Enter Company Address..."
                      type="textarea"
                      name="addr"
                      disabled={previewViewMode}
                      value={previewPh6.addr}
                      className="campus-text-area"
                      onChange={handleChangePref}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="cx-jobs-dashed-card-main mb-3">
          <Row className="align-center pt-2 mx-1">
            <div className="pt-2 align-center">
              <span className="text-lite-grey-sub">Job Info</span>
            </div>
            <Col xs="12" lg="11">
              <div className="pt-4 mx-1">
                <span className="text-lite ml-1 align-start">Job Designation</span>
                <Creatable
                  key="designation"
                  value={
                    selectedDesgNameOptions || (previewPh6.desg !== '' ? { label: previewPh6.desg, value: previewPh6.desg } : null)
                  }
                  options={desigNameoptions}
                  isClearable
                  onInputChange={handleGetDesignationChange}
                  onChange={handleSelectDesgData}
                  menuPortalTarget={document.body} // Brings Menu onTop
                  styles={{
                    menu: (base) => ({
                      ...base,
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '10px',
                      padding: '7px',
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  isLoading={loadingMeta}
                  noOptionsMessage={() => 'No Designation Found'}
                  placeholder="Enter or Add New Designation"
                />
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 mx-1">
                <span className="text-lite ml-1 align-start">Desired Skills<span className="mandate-star">*</span></span>
                <Creatable
                  key="skills"
                  options={skillNameoptions}
                  isClearable
                  onInputChange={handleGetSkillChange}
                  onChange={(e) => handleSelectSkillData(e)}
                  value={skillsList}
                  isMulti
                  menuPortalTarget={document.body} // Brings Menu onTop
                  styles={{
                    multiValue: (provided: any) => ({
                      ...provided,
                      background: '#f6eeeb',
                      padding: '1px',
                    }),
                    menu: (base) => ({
                      ...base,
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '10px',
                      padding: '7px',
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  isLoading={loadingMeta}
                  noOptionsMessage={() => 'No Skills Found'}
                  placeholder="Enter or Add New Skills"
                />
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 mx-1">
                <span className="text-lite ml-1 align-start">Good to have Skills</span>
                <Creatable
                  key="oSkills"
                  options={oSkillNameoptions}
                  isClearable
                  onInputChange={handleGetOSkillChange}
                  onChange={(e) => handleSelectOSkillData(e)}
                  value={oSkillsList}
                  isMulti
                  menuPortalTarget={document.body} // Brings Menu onTop
                  styles={{
                    multiValue: (provided: any) => ({
                      ...provided,
                      background: '#f6eeeb',
                      padding: '1px',
                    }),
                    menu: (base) => ({
                      ...base,
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#8B0000' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '10px',
                      padding: '7px',
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  isLoading={loadingMeta}
                  noOptionsMessage={() => 'No Skills Found'}
                  placeholder="Enter or Add New Skills"
                />
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4">
                <span className="text-lite ml-1 align-start">Job Type</span>
                <Select
                  options={jobTpNameoptions}
                  onChange={handleSelectJobType}
                  value={handleAllJobType()}
                  isDisabled={previewViewMode}
                  isMulti
                  styles={{
                    multiValue: (provided: any, state: any) => ({
                      ...provided,
                      background: state.isDisabled ? '#fbfbf9' : '#f6eeeb',
                      padding: '1px',
                      color: state.isDisabled ? '#383838' : provided.color,
                    }),
                    menu: (base) => ({
                      ...base,
                      position: 'relative',
                    }),
                    control: (base: any, state: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#F6B26B' },
                      minHeight: '50px',
                      borderRadius: '4px',
                      padding: 1,
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                      backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Job Type Found'}
                  placeholder="Enter Job Type"
                />
              </div>
            </Col>
            <Col xs="12" lg="11">
              <Row>
                <Col xs="12" lg="7">
                  <div className="pt-4">
                    <span className="text-lite ml-1 align-start">Recruitment Timeline</span>
                    <Select
                      value={previewPh6.timelne === '' ? null : selectedrecruitTLNameoptions}
                      options={recruitTLNameoptions}
                      onChange={handleSelectRecruitTimeline}
                      isDisabled={previewViewMode}
                      styles={{
                        singleValue: (base: any, state: any) => ({
                          ...base,
                          color: state.isDisabled ? '#383838' : base.color,
                        }),
                        control: (base: any, state: any) => ({
                          ...base,
                          // '&:disabled': { color: '#575656' },
                          '&:hover': { borderColor: '#8B0000' },
                          border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                          backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                          minHeight: '45px',
                          borderRadius: '7px',
                          padding: 1,
                          textAlign: 'left',
                          fontSize: '15px',
                          fontWeight: 400,
                          // opacity: state.isDisabled ? 0.9 : 1,
                          color: '#575656',
                          boxShadow: 'none',
                          '&:focus': {
                            borderColor: '#E1EDF8',
                            boxShadow: 'none',
                            color: '#575656',
                            background: '#fff',
                          },
                        }),
                        option: (provided: any, state: { isSelected: any; }) => ({
                          ...provided,
                          color: state.isSelected ? '#383838' : '#212121',
                          padding: 15,
                          textAlign: 'left',
                          background: state.isSelected ? '#FAFCFB' : '#fff',
                          '&:hover': {
                            background: '#FAFCFB',
                          },
                        }),
                      }}
                      noOptionsMessage={() => 'No Recruitment Timeline Found'}
                      placeholder="Select Recruitment Timeline..."
                    />
                  </div>
                </Col>
                <Col xs="12" lg="5">
                  <div className="pt-4">
                    <span className="text-lite ml-1 align-start">Job Schedule</span>
                    <Select
                      value={previewPh6.schdl === '' ? null : selectedScheduleNameOptions}
                      options={scheduleNameoptions}
                      onChange={handleSelectScheduleData}
                      isDisabled={previewViewMode}
                      styles={{
                        singleValue: (base: any, state: any) => ({
                          ...base,
                          color: state.isDisabled ? '#383838' : base.color, // Ensure selected value's text color changes when disabled
                        }),
                        control: (base: any, state: any) => ({
                          ...base,
                          // '&:disabled': { color: '#575656' },
                          '&:hover': { borderColor: '#8B0000' },
                          border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                          backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                          minHeight: '45px',
                          borderRadius: '7px',
                          padding: 1,
                          textAlign: 'left',
                          fontSize: '15px',
                          fontWeight: 400,
                          // opacity: state.isDisabled ? 0.9 : 1,
                          color: '#575656',
                          boxShadow: 'none',
                          '&:focus': {
                            borderColor: '#E1EDF8',
                            boxShadow: 'none',
                            color: '#575656',
                            background: '#fff',
                          },
                        }),
                        option: (provided: any, state: { isSelected: any; }) => ({
                          ...provided,
                          color: state.isSelected ? '#383838' : '#212121',
                          padding: 15,
                          textAlign: 'left',
                          background: state.isSelected ? '#FAFCFB' : '#fff',
                          '&:hover': {
                            background: '#FAFCFB',
                          },
                        }),
                      }}
                      noOptionsMessage={() => 'No Job Schedule Found'}
                      placeholder="Select Job Schedule..."
                    />
                  </div>
                  {previewPh6.schdl === 'OTH'
                    ? (
                      <div className="pt-4 mx-1">
                        <span className="text-lite ml-1 align-start">Create New Schedule</span>
                        <Input
                          placeholder="Enter Schedule"
                          type="text"
                          name="oSchdl"
                          value={previewPh6.oSchdl}
                          onChange={handleChangePref}
                          className="jobs-campus-input"
                        />
                      </div>
                    ) : null}
                </Col>
              </Row>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 mx-3">
                <span className="text-lite align-start">Is there any Planned Start Date for this job ?</span>
                <Row className="pt-2 collapse-bg mb-2">
                  <Col lg="3" xs="3">
                    <div className={previewPh6.isPlndDt ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                      <Input
                        type="radio"
                        name="isPlndDt"
                        disabled={previewViewMode}
                        checked={previewPh6.isPlndDt === true}
                        className="campus-job-radio-box cursor-pointer"
                        onChange={() => handleCheckPlndDt(true)}
                      />
                      <Label className="form-check-text ml">Yes</Label>
                    </div>
                  </Col>
                  <Col lg="3" xs="3">
                    <div className={previewPh6.isPlndDt ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                      <Input
                        type="radio"
                        name="isPlndDt"
                        disabled={previewViewMode}
                        checked={previewPh6.isPlndDt === false}
                        className="campus-job-radio-box cursor-pointer"
                        onChange={() => handleCheckPlndDt(false)}
                      />
                      <Label className="form-check-text ml">No</Label>
                    </div>
                  </Col>
                  {previewPh6.isPlndDt
                    ? (
                      <Col lg="6" xs="6">
                        <div className="align-center my-1">
                          <Input
                            type="date"
                            name="plndDt"
                            disabled={previewViewMode}
                            required={previewPh6.isPlndDt}
                            min={new Date().toISOString().split('T')[0]}
                            value={previewPh6.plndDt}
                            onChange={handleChangePref}
                            className="jobs-campus-input"
                          />
                        </div>
                      </Col>
                    ) : null}
                </Row>
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4">
                <span className="table-text ml-1 align-start pt-2">Number of Open Position for this Job</span>
                <Select
                  value={selectedVacancyNameOptions}
                  options={vacancyNameoptions}
                  onChange={handleSelectVacancyData}
                  isDisabled={previewViewMode}
                  styles={{
                    singleValue: (base: any, state: any) => ({
                      ...base,
                      color: state.isDisabled ? '#383838' : base.color,
                    }),
                    control: (base: any, state: any) => ({
                      ...base,
                      // '&:disabled': { color: '#575656' },
                      '&:hover': { borderColor: '#8B0000' },
                      border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                      backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                      minHeight: '45px',
                      borderRadius: '7px',
                      padding: 1,
                      textAlign: 'left',
                      fontSize: '15px',
                      fontWeight: 400,
                      // opacity: state.isDisabled ? 0.9 : 1,
                      color: '#575656',
                      boxShadow: 'none',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                />
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 align-start">
                <Label check>
                  <Input
                    type="checkbox"
                    name="isFemale"
                    value={previewPh6.isFemale}
                    onClick={handleChangeIsFemale}
                    className="campus-check-box ml pt-2 cursor-pointer"
                  />{' '}
                  <span className="text-lite-grey">Is this Job only for Female Candidates?</span>
                </Label>
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 align-start">
                <Label check>
                  <Input
                    type="checkbox"
                    name="isAbled"
                    value={previewPh6.isAbled}
                    onChange={handleChangeIsAbled}
                    className="campus-check-box ml pt-2 cursor-pointer"
                  />{' '}
                  <span className="text-lite-grey">Is this Job only for Differently Abled Candidates?</span>
                </Label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cx-jobs-dashed-card-main mb-3">
          <Row className="align-center pt-2 mx-1">
            <div className="pt-2 align-center">
              <span className="text-lite-grey-sub">Pay & Benefits</span>
            </div>
            <Col xs="12" lg="11">
              <div className="pt-4">
                <div className={previewPh6.payBy !== '' ? 'cx-jobs-dashed-card' : ''}>
                  <span className="text-lite ml-1 align-start">Filter Pay Structure</span>
                  <Select
                    value={previewPh6.payBy === '' ? null : selectedPayNameOptions}
                    options={payNameoptions}
                    onChange={handleSelectPayData}
                    isDisabled={previewViewMode}
                    styles={{
                      singleValue: (base: any, state: any) => ({
                        ...base,
                        color: state.isDisabled ? '#383838' : base.color,
                      }),
                      control: (base: any, state: any) => ({
                        ...base,
                        // '&:disabled': { color: '#575656' },
                        '&:hover': { borderColor: '#8B0000' },
                        border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                        backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                        minHeight: '45px',
                        borderRadius: '7px',
                        padding: 1,
                        textAlign: 'left',
                        fontSize: '15px',
                        fontWeight: 400,
                        // opacity: state.isDisabled ? 0.9 : 1,
                        color: '#575656',
                        boxShadow: 'none',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                    noOptionsMessage={() => 'No Pay Structure Found'}
                    placeholder="Select Pay Structure..."
                  />
                  {previewPh6.payBy !== ''
                    ? (
                      <Col xs="12" lg="12">
                        <div className="pt-4 mx-1">
                          <Row className="pt-1">
                            <Col xs="12" lg="7">
                              <div className="pt-1">
                                <span className="text-lite ml-1 align-start">Amount</span>
                                <Input
                                  placeholder="Enter Amount"
                                  type="text"
                                  name="amnt"
                                  disabled={previewViewMode}
                                  value={previewPh6.amnt}
                                  onChange={handleChangePref}
                                  className="jobs-campus-input"
                                />
                              </div>
                            </Col>
                            <Col xs="12" lg="5">
                              <div className="pt-4">
                                <Select
                                  value={selectedAmntNameOptions}
                                  options={amountNameoptions}
                                  onChange={handleSelectAmountTypeData}
                                  isDisabled={previewViewMode}
                                  styles={{
                                    singleValue: (base: any, state: any) => ({
                                      ...base,
                                      color: state.isDisabled ? '#383838' : base.color,
                                    }),
                                    control: (base: any, state: any) => ({
                                      ...base,
                                      // '&:disabled': { color: '#575656' },
                                      '&:hover': { borderColor: '#8B0000' },
                                      border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                                      backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                                      minHeight: '45px',
                                      borderRadius: '7px',
                                      padding: 1,
                                      textAlign: 'left',
                                      fontSize: '15px',
                                      fontWeight: 400,
                                      // opacity: state.isDisabled ? 0.9 : 1,
                                      color: '#575656',
                                      boxShadow: 'none',
                                      '&:focus': {
                                        borderColor: '#E1EDF8',
                                        boxShadow: 'none',
                                        color: '#575656',
                                        background: '#fff',
                                      },
                                    }),
                                    option: (provided: any, state: { isSelected: any; }) => ({
                                      ...provided,
                                      color: state.isSelected ? '#383838' : '#212121',
                                      padding: 15,
                                      textAlign: 'left',
                                      background: state.isSelected ? '#FAFCFB' : '#fff',
                                      '&:hover': {
                                        background: '#FAFCFB',
                                      },
                                    }),
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {previewPh6.amnt !== ''
                          ? <span className="text-lite pt-2 align-start ml-2">{numberToWords(parseInt(previewPh6.amnt))} / { AmountTpye.find((amntTp) => amntTp.value === previewPh6.amntTp)?.label || ''}</span>
                          : null}
                      </Col>
                    ) : null}
                </div>
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4">
                <div className={isSuppPay ? 'cx-jobs-dashed-card' : ''}>
                  <span className="text-lite ml align-start">Suplementary Pay</span>
                  <Select
                    options={suppPayNameoptions}
                    onChange={handleAllSuppPay}
                    value={handleSelectSuppPayType()}
                    isDisabled={previewViewMode}
                    isMulti
                    styles={{
                      multiValue: (provided: any, state: any) => ({
                        ...provided,
                        background: state.isDisabled ? '#fbfbf9' : '#f6eeeb',
                        padding: '1px',
                        color: state.isDisabled ? '#383838' : provided.color,
                      }),
                      menu: (base) => ({
                        ...base,
                        position: 'relative',
                      }),
                      control: (base: any, state: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#F6B26B' },
                        minHeight: '50px',
                        borderRadius: '4px',
                        padding: 1,
                        textAlign: 'left',
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#575656',
                        boxShadow: 'none',
                        border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                        backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                    noOptionsMessage={() => 'No Supplemental Payment Found'}
                    placeholder="Enter Supplemental Payment"
                  />
                  {isSuppPay
                    ? (
                      <div className="pt-2 mx-1 margin-state-desk">
                        <span className="text-lite ml-1 align-start">Other Supplemental Payment</span>
                        <Input
                          placeholder="Enter Additional Supplemental Pay"
                          type="text"
                          name="initOSpay"
                          value={initOSpay}
                          disabled={previewViewMode}
                          onChange={handleChangeUpdateOSPay}
                          onKeyDown={handleKeyPressOSPay}
                          className="jobs-campus-input"
                        />
                        <span className="text-lite ml-1 align-start">Press Enter after typing to add supplimental pay</span>

                        <div className="mt-3 align-start badge-container">
                          {previewPh6.oSuppPay.map((oSpay, index) => (
                            <Badge
                              key={`${index.toString()}`}
                              pill
                              disabled={previewViewMode}
                              className={!previewViewMode ? 'chip-box mx-1 my-1 cursor-pointer' : 'chip-box mx-1 my-1'}
                              onClick={!previewViewMode ? () => removeOSPay(oSpay) : undefined}
                              // style={{ cursor: 'pointer' }}
                            >
                              {oSpay} &times;
                            </Badge>
                          ))}
                        </div>
                      </div>
                    ) : null}
                </div>
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="pt-4 mx-1">
                <div className={isOPerk ? 'cx-jobs-dashed-card' : ''}>
                  <span className="text-lite ml align-start">Additional Perk</span>
                  <Select
                    options={perkNameoptions}
                    onChange={handleAllPerk}
                    value={handleSelectPerkType()}
                    isDisabled={previewViewMode}
                    isMulti
                    styles={{
                      multiValue: (provided: any, state: any) => ({
                        ...provided,
                        background: state.isDisabled ? '#fbfbf9' : '#f6eeeb',
                        padding: '1px',
                        color: state.isDisabled ? '#383838' : provided.color,
                      }),
                      menu: (base) => ({
                        ...base,
                        position: 'relative',
                      }),
                      control: (base: any, state: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#F6B26B' },
                        minHeight: '50px',
                        borderRadius: '4px',
                        padding: 1,
                        textAlign: 'left',
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#575656',
                        boxShadow: 'none',
                        border: state.isDisabled ? '1px solid #fff' : '1px solid #fac9c9',
                        backgroundColor: state.isDisabled ? '#fbfbf9' : '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                    noOptionsMessage={() => 'No Additional Perk Found'}
                    placeholder="Enter Additional Perk"
                  />
                  {isOPerk
                    ? (
                      <div className="pt-2 mx-1 margin-state-desk">
                        <span className="text-lite ml-1 align-start">Other Perk</span>
                        <Input
                          placeholder="Enter Additional Perk"
                          type="text"
                          name="initOPerk"
                          value={initOPerk}
                          disabled={previewViewMode}
                          onChange={handleChangeUpdatePerk}
                          onKeyDown={handleKeyPress}
                          className="jobs-campus-input"
                        />
                        <span className="text-lite ml-1 align-start">Press Enter after typing to add the desired perk</span>

                        <div className="mt-3 align-start badge-container">
                          {previewPh6.oPerk.map((perk, index) => (
                            <Badge
                              key={`${index.toString()}`}
                              pill
                              disabled={previewViewMode}
                              className={!previewViewMode ? 'chip-box mx-1 my-1 cursor-pointer' : 'chip-box mx-1 my-1'}
                              onClick={!previewViewMode ? () => removePerk(perk) : undefined}
                              // style={{ cursor: 'pointer' }}
                            >
                              {perk} &times;
                            </Badge>
                          ))}
                        </div>
                      </div>
                    ) : null}
                </div>
              </div>
            </Col>
            <Col xs="12" lg="10">
              <div className="pt-4 align-start">
                <span className="text-lite-grey-sub">What range of experience are you seeking?</span>
              </div>
              <div className="align-start">
                <div className="pt-4 mr">
                  <span className="text-lite align-start">Max Experience</span>
                  <Select
                    value={selectedExpRange2Nameoptions}
                    options={expRange2Nameoptions}
                    onChange={handleSelectExpRange2Data}
                    styles={{
                      control: (base: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#8B0000' },
                        border: '1px solid #DDE0E4',
                        minHeight: '40px',
                        borderRadius: '5px',
                        padding: 1,
                        width: '130px',
                        textAlign: 'left',
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#575656',
                        boxShadow: 'none',
                        backgroundColor: '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                  />
                </div>
                <div className="pt-4 ml-1">
                  <span className="text-lite align-start">Minimum Required</span>
                  <Select
                    value={selectedExpRange1Nameoptions}
                    options={expRange1Nameoptions}
                    onChange={handleSelectExpRange1Data}
                    styles={{
                      control: (base: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#8B0000' },
                        border: '1px solid #DDE0E4',
                        minHeight: '40px',
                        borderRadius: '5px',
                        padding: 1,
                        width: '130px',
                        textAlign: 'left',
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#575656',
                        boxShadow: 'none',
                        backgroundColor: '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cx-jobs-dashed-card-main mb-3">
          <Row className="align-center pt-2 mx-1">
            <div className="pt-2 align-center">
              <span className="text-lite-grey-sub">Describe your Job</span>
            </div>
            <Col xs="12" lg="11">
              <div className="pt-2 mx-1">
                {previewViewMode ? (
                  <div className="pt-3">
                    <div
                      className="ml mx-1"
                // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                  </div>
                )
                  : (
                    <SunEditor
                      height="550px"
                      width="auto"
                      setOptions={{
                        buttonList: [
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                            'fontColor', 'hiliteColor',
                            'outdent', 'indent',
                            'align', 'horizontalRule', 'list', 'lineHeight',
                            'table', 'link', 'font', 'fontSize', 'formatBlock'],
                        ],
                      }}
                      setContents={htmlContent}
                      onChange={handleEditorEvent}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="cx-jobs-dashed-card-main mb-3">
          <Row className="align-center pt-2 mx-1">
            <Col xs="12" lg="11">
              <div className="cx-jobs-dashed-card mb-2">
                <div className="align-center">
                  <span className="text-lite-grey-sub">Application Prefrences</span>
                </div>
                <div className="align-start">
                  <Row>
                    <Col xs="12" lg="11">
                      <div className="pt-4">
                        <span className="form-check-job-text align-start ml-1">Ask potential candidate for CV</span>
                        <Row className="pt-2 collapse-bg ml-1">
                          <Col lg="6" xs="6">
                            <div className="align-start">
                              <Input
                                type="radio"
                                checked={previewPh6.isCV === true}
                                className="campus-radio-box cursor-pointer"
                                onChange={() => handleCheckIsCV(true)}
                              />
                              <Label check className="form-check-text ml">Yes, require a CV</Label>
                            </div>
                          </Col>
                          <Col lg="6" xs="6">
                            <div className="align-start">
                              <Input
                                type="radio"
                                checked={previewPh6.isCV === false}
                                className="campus-radio-box cursor-pointer"
                                onChange={() => handleCheckIsCV(false)}
                              />
                              <Label check className="form-check-text ml">NO, don&apos;t ask for CV</Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs="12" lg="11">
                      <div className="pt-4">
                        <span className="form-check-job-text align-start ml-1">Is there Application deadline ?</span>
                        <Row className="pt-2 collapse-bg ml-1">
                          <Col lg="3" xs="3">
                            <div className={previewPh6.isAppTlne ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                              <Input
                                type="radio"
                                checked={previewPh6.isAppTlne === true}
                                className="campus-radio-box cursor-pointer"
                                onChange={() => handleCheckIsAppTlne(true)}
                              />
                              <Label check className="form-check-text ml">Yes</Label>
                            </div>
                          </Col>
                          <Col lg="3" xs="3">
                            <div className={previewPh6.isAppTlne ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                              <Input
                                type="radio"
                                checked={previewPh6.isAppTlne === false}
                                className="campus-radio-box cursor-pointer"
                                onChange={() => handleCheckIsAppTlne(true)}
                              />
                              <Label check className="form-check-text ml">No</Label>
                            </div>
                          </Col>
                          {previewPh6.isAppTlne
                            ? (
                              <Col lg="6" xs="6">
                                <div className="align-center my-1">
                                  <Input
                                    type="date"
                                    name="appTlne"
                                    required={previewPh6.isAppTlne}
                                    min={new Date().toISOString().split('T')[0]}
                                    value={previewPh6.appTlne}
                                    onChange={handleChangePref}
                                    className="campus-input"
                                  />
                                </div>
                              </Col>
                            ) : null}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xs="12" lg="11">
              <div className="cx-jobs-dashed-card mb-2">
                <div className="align-start">
                  <Row className="align-center pt-2 mx-1">
                    <div className="pt-2 align-center">
                      <span className="text-lite-grey-sub">Communication Prefrences</span>
                    </div>
                    <Col xs="12" lg="11">
                      <Row className="align-start pt-4">
                        <Col lg="6">
                          <div className="align-start pt-2">
                            <span className="form-check-job-text">Let applicant contact you directly for this job</span>
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="align-center">
                            <Input
                              placeholder="Enter Mobile Number"
                              type="text"
                              name="jDCntPh"
                              disabled={previewViewMode}
                              value={previewPh6.jDCntPh}
                              onChange={handleChangePref}
                              className="jobs-campus-input"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="11">
                      <Row className="align-start pt-4">
                        <Col lg="6">
                          <div className="align-start pt-2">
                            <span className="form-check-job-text align-start">Let potential Candidate contact you via Email</span>
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="align-center">
                            <Input
                              placeholder="Enter Email Id"
                              type="text"
                              name="potCndEml"
                              disabled={previewViewMode}
                              value={previewPh6.potCndEml}
                              onChange={handleChangePref}
                              className="jobs-campus-input"
                            />
                          </div>
                          {/* <span className="mr-1 text-lite align-end">Mobile Number</span> */}
                          <div className="pt-3" />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="11">
                      <Row className="align-start pt-4">
                        <Col lg="6">
                          <div className="align-start pt-5">
                            <span className="form-check-job-text align-start">Communication Prefrence</span>
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="pt-3">
                            <span className="ml-1 text-lite align-start">Send daily update to</span>
                            <Input
                              placeholder="Enter Email Id"
                              type="text"
                              name="dailUpdEml"
                              disabled={previewViewMode}
                              value={previewPh6.dailUpdEml}
                              onChange={handleChangePref}
                              className="jobs-campus-input"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cx-jobs-dashed-card-main mb-3">
          <Row className="align-center pt-2 mx-1">
            <Col xs="10">
              <div className="pt-2 mx-1 margin-state-desk">
                <Input
                  placeholder="Enter Keywords"
                  type="text"
                  name="initJobKeyword"
                  value={initJobKeyword}
                  disabled={previewViewMode}
                  onChange={handleChangeUpdateJobKeyword}
                  onKeyDown={handleKeyPressJobKeyword}
                  className="jobs-campus-input"
                />
                <span className="text-lite ml align-start">Press Enter after typing to add job key</span>

                <div className="mt-3 align-start badge-container">
                  {previewPh6.jobKey.map((jobKey, index) => (
                    <Badge
                      key={`${index.toString()}`}
                      pill
                      disabled={previewViewMode}
                      className={!previewViewMode ? 'chip-box mx-1 my-1 cursor-pointer' : 'chip-box mx-1 my-1'}
                      onClick={!previewViewMode ? () => removeJobKeyword(jobKey) : undefined}
                      // style={{ cursor: 'pointer' }}
                    >
                      {jobKey} &times;
                    </Badge>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="my-1">
          <Row>
            <Col xs="12" lg="12">
              <div className="align-end pt-2 mr-1">
                {previewViewMode ? (
                  <Button className="button-icon" onClick={handleViewMode}>
                    Edit
                  </Button>
                )
                  : (
                    <Button className="button-icon" onClick={handleEditMode}>
                      Cancel
                    </Button>
                  )}

                <Button className="campus-button-all ml" disabled={initJobKeyword !== ''}>
                  SAVE
                  <FontAwesomeIcon icon={faFloppyDisk} className="ml" />
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <div className="pt-4" />
    </div>
  );
};

export default ReviewJobPhase6;
