import {
  faUsersGear,
  faBan,
  faCheckToSlot,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Row, Col,
  CardHeader,
  Card,
  CardBody,
  CardImg,
} from 'reactstrap';
import { faCircleCheck, faClock } from '@fortawesome/free-regular-svg-icons';
import { JobsDataResult } from '../../../../../../../../services/staffing/job-board/list-jobs-private/list-jobs.private.types';
import {
  APIHeader, ListJobType, UserType,
} from '../../../../../../../../utils/constants';
import Modal from '../../../../../../../../utils/Modal/Modal';
import { clearJobByDetailsReq, jobByDetailsReq, jobStatusReq } from '../../../../../../../../store/campusXcel/actions';
import { UpdateJobStatusData } from '../../../../../../../../services/staffing/job-board/update-job-status/job-status.types';
import Offcanvas from '../../../../../../../../utils/OffCanvas/OffcanvasComponent';
import { JobsResult } from '../../../../../../../../services/staffing/job-board/get-job-details/job-details.types';
import { ViewEditJobs } from './view.edit.jobs';
// import JobResumeComponent from '../../resumes/resume.component';

interface TableProps {
  reqClientId: string;
  data: JobsDataResult[];
  tokenData: string;
  setIsUpdateSent: (isUpdateSent: string) => void;
  setLoadingJobStatus: (loadingJobStatus: boolean) => void;
  setAlertMsg: (alertMsg: string) => void;
  setErrorShowAlert: (errorShowAlert: boolean) => void;
  setSuccessShowAlert: (successShowAlert: boolean) => void;
}

const AllApprovedPrivateJobsTable: React.FC<TableProps> = ({
  data,
  tokenData,
  setIsUpdateSent,
  setLoadingJobStatus,
  setAlertMsg,
  setErrorShowAlert,
  setSuccessShowAlert,
  reqClientId,
}) => {
  // const isMobileJob = window.innerWidth <= 468;
  const [jobViewDetailsOpen, setJobViewDetailsOpen] = React.useState<Record<string, boolean>>({});
  // const [jobResumeUploadOpen, setJobResumeUploadOpen] = React.useState<Record<string, boolean>>({});
  // const [jobResumeView, setJobResumeView] = React.useState('');
  const [jobInfoEditView, setJobInfoEditView] = React.useState('');
  const [jobDetName, setJobDetName] = React.useState('');
  const [modalConfirmBlock, setModalConfirmBlock] = React.useState(false);
  const [modalConfirmApprove, setModalConfirmApprove] = React.useState(false);
  const dispatch = useDispatch();
  const updateJobStatusResponse = useSelector((state: RootState) => state.campusXcel.updateJobStatus);
  const getJobViewEditResponse = useSelector((state: RootState) => state.campusXcel.getJobByDetails);
  const [updateJobStatus, setUpdateJobStatus] = React.useState<UpdateJobStatusData>({
    JobCd: '',
    JobId: '',
    pStg: '',
  });
  const [viewEditData, setViewEditData] = React.useState<JobsResult>({
    pStg: '',
    JobCd: '',
    JobId: '',
    forClnt: '',
    nme: '',
    cNme: '',
    pCd: '',
    wrkPref: '',
    city: '',
    st: '',
    addr: '',
    logoUrl: '',
    jobTp: [],
    schdl: '',
    oSchdl: '',
    isPlndDt: false,
    plndDt: '',
    vacncy: 0,
    timelne: '',
    payBy: '',
    amnt: '',
    amntTp: '',
    suppPay: [],
    oSuppPay: [],
    perk: [],
    oPerk: [],
    jdFile: '',
    jDCntPh: '',
    dailUpdEml: '',
    potCndEml: '',
    isFemale: '',
    isAbled: '',
    skills: [],
    oSkills: [],
    desg: '',
    updtBy: '',
    updtOn: '',
  });
  // const [resumeLoadData, setResumeLoadData] = React.useState<ResumeLoadData>({
  //   JobCd: '',
  //   JobId: '',
  //   nme: '',
  // });

  const handleOpenJobView = (openIndexView: string, jobCode:string, jobIdent: string) => {
    setJobInfoEditView(openIndexView);
    setLoadingJobStatus(true);
    dispatch(jobByDetailsReq({
      requestType: APIHeader.REQ_GET_JOB_DETAILS_BY_ID,
      uTp: UserType.RCRT,
      token: tokenData,
      jobCd: jobCode,
      jobId: jobIdent,
    }));
  };

  React.useEffect(() => {
    if (getJobViewEditResponse.isError && getJobViewEditResponse.message !== '') {
      setLoadingJobStatus(false);
      setErrorShowAlert(true);
      setAlertMsg(updateJobStatusResponse.message);
    }
  }, [getJobViewEditResponse.isError, getJobViewEditResponse.message]);

  React.useEffect(() => {
    if (!getJobViewEditResponse.isError && getJobViewEditResponse.message === 'executed') {
      setViewEditData(getJobViewEditResponse.data);
      setLoadingJobStatus(false);
      setJobViewDetailsOpen((prevState) => ({
        ...prevState,
        [jobInfoEditView]: !prevState[jobInfoEditView],
      }));
      dispatch(clearJobByDetailsReq());
    }
  }, [getJobViewEditResponse.isError, getJobViewEditResponse.message]);

  const handleCloseJobViewEdit = () => {
    setJobViewDetailsOpen((prevState) => ({
      ...prevState,
      [jobInfoEditView]: !prevState[jobInfoEditView],
    }));
    setJobInfoEditView('');
  };

  const handleLoadResumeByJobs = (jobData: JobsDataResult) => {
    const params = new URLSearchParams({
      // jobCd: jobData.JobCd,
      // jobId: jobData.JobId,
      nme: jobData.nme,
      forClnt: jobData.forClnt,
    }).toString();

    window.open(`job/${jobData.JobCd}/${jobData.JobId}?${params}`, '_blank');
    window.scrollTo(0, 0);
    // setResumeLoadData({
    //   ...resumeLoadData,
    //   JobCd: jobData.JobCd,
    //   JobId: jobData.JobId,
    //   nme: jobData.nme,
    // });
    // setJobResumeView(openIndexView);
    // setJobResumeUploadOpen((prevState) => ({
    //   ...prevState,
    //   [openIndexView]: !prevState[openIndexView],
    // }));
  };

  // const handleCloseResumeByJobs = () => {
  //   // window.open(`jobs/${jobCode}/${jobIdent}`, '_blank');
  //   // window.scrollTo(0, 0);
  //   setJobResumeUploadOpen((prevState) => ({
  //     ...prevState,
  //     [jobResumeView]: !prevState[jobResumeView],
  //   }));
  //   setJobResumeView('');
  // };

  const handleConfirmBan = (jobBanName: string, jobCode:string, jobIdent: string, jobStg: string) => {
    setUpdateJobStatus({
      ...updateJobStatus, JobCd: jobCode, JobId: jobIdent, pStg: jobStg,
    });
    setJobDetName(jobBanName);
    setModalConfirmBlock(!modalConfirmBlock);
  };

  const handleConfirmApprove = (jobApprName: string, jobCode:string, jobIdent: string, jobStg: string) => {
    setUpdateJobStatus({
      ...updateJobStatus, JobCd: jobCode, JobId: jobIdent, pStg: jobStg,
    });
    setJobDetName(jobApprName);
    setModalConfirmApprove(!modalConfirmApprove);
  };

  // const handleEditData = (record: any) => {
  //   setEditRecord(record);
  // };

  const handleSubmitBlock = (status: string) => {
    if (status === 'BLOCK') {
      setLoadingJobStatus(true);
      setModalConfirmBlock(false);
      dispatch(jobStatusReq({
        inputBody: updateJobStatus,
        requestType: APIHeader.REQ_UPDATE_JOB_STATUS,
        uTp: UserType.RCRT,
        token: tokenData,
      }));
    } else {
      setModalConfirmBlock(false);
    }
  };

  const handleSubmitApprove = (status: string) => {
    if (status === 'APPROVE') {
      setLoadingJobStatus(true);
      setModalConfirmApprove(false);
      dispatch(jobStatusReq({
        inputBody: updateJobStatus,
        requestType: APIHeader.REQ_UPDATE_JOB_STATUS,
        uTp: UserType.RCRT,
        token: tokenData,
      }));
    } else {
      setModalConfirmApprove(false);
    }
  };

  React.useEffect(() => {
    if (updateJobStatusResponse.error && updateJobStatusResponse.message !== '') {
      setLoadingJobStatus(false);
      setErrorShowAlert(true);
      setAlertMsg(updateJobStatusResponse.message);
    }
  }, [updateJobStatusResponse.error, updateJobStatusResponse.message]);

  React.useEffect(() => {
    if (!updateJobStatusResponse.error && updateJobStatusResponse.message !== '') {
      setLoadingJobStatus(false);
      setSuccessShowAlert(true);
      setAlertMsg(updateJobStatusResponse.message);
      setIsUpdateSent('YES');
    }
  }, [updateJobStatusResponse.error, updateJobStatusResponse.message]);

  return (
    <div className="">
      {data.length > 0
        ? (
          <Row className="align-start margin-mob-desk-pirv-jobs">
            {data.map((jobData, index) => (
              ['PSTGAPPR', 'LIVE', 'JDBLCK'].includes(jobData.jobSts) ? (
                <Col lg="6" xs="12" key={`${index.toString()}`}>
                  <Card className="card-joblist-pvt-appr my-1">
                    <CardHeader className="form-card-header">
                      <Row className="my-1">
                        <Col xs="9" lg="10">
                          <div className="align-start">
                            <Button className="button-icon padding-zero" onClick={() => handleOpenJobView(`jobView-${index}`, jobData.JobCd, jobData.JobId)}>
                              <span className="pub-jobs-header-text">{jobData.nme}</span>
                            </Button>
                          </div>
                          <div className="align-start pt-1">
                            <span className="pub-jobs-org-text">{jobData.cNme}
                            </span>
                            {/* <span className="pub-jobs-org-text mx-2">|</span>
                            <span className="pub-jobs-org-text">Applicants({jobData.aplCnt})
                            </span> */}
                            <span className="pub-jobs-org-text mx-2">|</span>
                            {jobData.jobSts === 'PSTGAPPR'
                              ? <span className="job-icon-pending padding-zero">Pending Approval<FontAwesomeIcon icon={faClock} className="ml" /></span>
                              : jobData.jobSts === 'LIVE'
                                ? <span className="job-icon-live padding-zero">LIVE<FontAwesomeIcon icon={faCircleCheck} className="ml" /></span>
                                : jobData.jobSts === 'JDBLCK'
                                  ? <span className="job-icon-block padding-zero">Blocked<FontAwesomeIcon icon={faBan} className="ml" /></span>
                                  : null}
                          </div>
                        </Col>
                        <Col xs="3" lg="2">
                          <div className="align-end">
                            <CardImg className="pub-job-logo" src={jobData.logoUrl !== '' ? jobData.logoUrl : '/images/icons/jobs-pending.png'} alt="jobs" />
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="padding-zero">
                      <Row className="pt-1">
                        <Col xs="12">
                          <div className="align-start ml mb-2">
                            <span className="dashboard-sub-post-text">{jobData.desg}</span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <Row className="d-flex flex-wrap">
                            <Col xs="auto">
                              <div className="my-1 mx-1">
                                <Card className="chip-box-jobs-pvt">
                                  {jobData.vacncy} Vacancies
                                </Card>
                              </div>
                            </Col>
                            {jobData.jobTp.map((jobType, jobTpIndex) => (
                              <Col xs="auto" className="my-1 mx-1" key={`${jobTpIndex.toString()}`}>
                                <Card className="chip-box-jobs-pvt">
                                  <span className="puser-user-name-text">
                                    {ListJobType.find((state) => state.value === jobType)?.label || ''}
                                  </span>
                                </Card>
                              </Col>
                            ))}
                            {jobData.skills && jobData.skills.length > 0 && jobData.skills[0] !== '' && jobData.skills.filter((skillsData) => skillsData !== 'OTRS').map((skillsData, skillsIndex) => (
                              <Col xs="auto" className="my-1 mx-1" key={`${skillsIndex.toString()}`}>
                                <Card className="chip-box-jobs-pvt">
                                  <span className="puser-user-name-text">{skillsData}</span>
                                </Card>
                              </Col>
                            ))}
                            {/* {jobData.oPerk && jobData.oPerk.length > 0 && jobData.oPerk[0] !== '' && jobData.oPerk.map((oPerkData, oPerkIndex) => (
                              <Col xs="auto" className="my-1 mx-1" key={`${oPerkIndex.toString()}`}>
                                <Card className="chip-box-jobs-pvt">
                                  <span className="puser-user-name-text">{oPerkData}</span>
                                </Card>
                              </Col>
                            ))}
                            {jobData.oSuppPay && jobData.oSuppPay.length > 0 && jobData.oSuppPay[0] !== '' && jobData.oSuppPay.map((oSuppPayData, oSuppPayIndex) => (
                              <Col xs="auto" className="my-1 mx-1" key={`${oSuppPayIndex.toString()}`}>
                                <Card className="chip-box-jobs-pvt">
                                  <span className="puser-user-name-text">{oSuppPayData}</span>
                                </Card>
                              </Col>
                            ))}
                            {jobData.perk && jobData.perk.length > 0 && jobData.perk[0] !== '' && jobData.perk.filter((perkData) => perkData !== 'OTRS').map((perkData, perkIndex) => (
                              <Col xs="auto" className="my-1 mx-1" key={`${perkIndex.toString()}`}>
                                <Card className="chip-box-jobs-pvt">
                                  <span className="puser-user-name-text">{AddonPerkType.find((state) => state.value === perkData)?.label || ''}</span>
                                </Card>
                              </Col>
                            ))}
                            {jobData.suppPay && jobData.suppPay.length > 0 && jobData.suppPay[0] !== '' && jobData.suppPay.filter((suppPayData) => suppPayData !== 'OTRS').map((suppPayData, suppPayIndex) => (
                              <Col xs="auto" className="my-1 mx-1" key={`${suppPayIndex.toString()}`}>
                                <Card className="chip-box-jobs-pvt">
                                  <span className="puser-user-name-text">{SuppPayType.find((state) => state.value === suppPayData)?.label || ''}</span>
                                </Card>
                              </Col>
                            ))} */}
                          </Row>
                        </Col>
                      </Row>
                      <Row className="pt-4 mb-1">
                        <Col lg="6" xs="5">
                          <div className="align-start pt-2 ml">
                            <span className="text-lite">
                              <FontAwesomeIcon className="text-lite-grey" icon={faUsersGear} />
                              Applicants (<span className="job-applicant-text">{jobData.aplCnt}</span>)
                            </span>
                          </div>
                        </Col>
                        <Col lg="6" xs={jobData.jobSts === 'PSTGAPPR' ? '12' : 7}>
                          <div className="align-end">
                            {jobData.jobSts === 'LIVE' || jobData.jobSts === 'PSTGAPPR'
                              ? (
                                <>
                                  {/* `jobResumeLoad-${index}`,  */}
                                  <Button onClick={() => handleLoadResumeByJobs(jobData)} className="campus-button-lite">
                                    <FontAwesomeIcon icon={faCheckToSlot} className="mr" />Actions
                                  </Button>
                                </>
                              ) : null}
                            {jobData.jobSts === 'LIVE' || jobData.jobSts === 'PSTGAPPR'
                              ? (
                                <Button onClick={() => handleConfirmBan(jobData.nme, jobData.JobCd, jobData.JobId, 'JDBLCK')} className="button-icon-danger">
                                  <FontAwesomeIcon icon={faBan} className="table-icon-danger" />
                                </Button>
                              ) : null}
                            {/* <Button onClick={() => handleOpenJobView(`jobView-${index}`)} className="button-icon-edit">
                              <FontAwesomeIcon icon={faPenToSquare} className="table-icon-prim" />
                            </Button> */}
                            {jobData.jobSts === 'PSTGAPPR' || jobData.jobSts === 'JDBLCK'
                              ? (
                                <Button onClick={() => handleConfirmApprove(jobData.nme, jobData.JobCd, jobData.JobId, 'LIVE')} className="job-button-approve mr">
                                  APPROVE
                                </Button>
                              ) : null}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Modal
                    show={modalConfirmApprove || false}
                    onClose={() => (false)}
                    disCancel
                    targetId={`Approve-${index.toString()}`}
                    title=""
                    size="SM"
                  >
                    <div className="mx-5 pt-3">
                      <span className="text-lite">Do you really want to Approve?</span>
                    </div>
                    <div className="mx-2 text-center-aligned">
                      <span className="table-text">{jobDetName}</span>
                    </div>
                    <div className="align-center my-3">
                      <Button className="button-icon mx-1" onClick={() => handleSubmitApprove('APPROVE')}>
                        APPROVE
                      </Button>
                      <Button className="campus-button-all mx-1" onClick={() => handleSubmitApprove('CANCEL')}>
                        CANCEL
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    show={modalConfirmBlock || false}
                    onClose={() => (false)}
                    disCancel
                    targetId={`Block-${index.toString()}`}
                    title=""
                    size="SM"
                  >
                    <div className="mx-5 pt-3">
                      <span className="text-lite">Do you really want to Block?</span>
                    </div>
                    <div className="mx-2 text-center-aligned">
                      <span className="table-text">{jobDetName}</span>
                    </div>
                    <div className="align-center my-3">
                      <Button className="button-icon mx-1" onClick={() => handleSubmitBlock('BLOCK')}>
                        BLOCK
                      </Button>
                      <Button className="campus-button-all mx-1" onClick={() => handleSubmitBlock('CANCEL')}>
                        CANCEL
                      </Button>
                    </div>
                  </Modal>
                </Col>
              ) : null
            ))}
          </Row>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Record Found</span>
          </div>
        )}
      <Offcanvas
        isOpen={jobViewDetailsOpen[jobInfoEditView]}
        toggle={handleCloseJobViewEdit}
      >
        <div>
          <ViewEditJobs
            viewEditData={viewEditData}
            setViewEditData={setViewEditData}
            reqClientId={reqClientId}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            setLoadingJobStatus={setLoadingJobStatus}
            setIsUpdateSent={setIsUpdateSent}
          />
        </div>
      </Offcanvas>
      {/* <Offcanvas
        isOpen={jobResumeUploadOpen[jobResumeView]}
        toggle={handleCloseResumeByJobs}
      >
        <JobResumeComponent
          resumeJobData={resumeLoadData}
        />
      </Offcanvas> */}
    </div>
  );
};

export default AllApprovedPrivateJobsTable;
