import {
  faBriefcase,
  faCircle,
  faIndianRupee,
  faInfoCircle,
  faLocationDot,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  Col, Row,
  UncontrolledTooltip,
} from 'reactstrap';
import './jobs.scss';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { AmountSLashTpye, ListJobSchedule, ListJobType } from '../../../../utils/constants';
import { XcelianJobsDataResult } from '../../../../services/staffing/xcelian/xcelian-jobs/xcln.jobs.types';

interface TableProps {
  isMobileJob: boolean;
  clickedIndex: string;
  data: XcelianJobsDataResult[];
  handleGetJobDetails: (jobCode: string, jobIdent: string) => void;
  handleUpdateInterviewStatus: (jobCode: string, jobIdent: string, appSts: string, clientId: string, isCV: boolean, isUserResume: boolean) => void;
  handleUpdateJobsViewCount: (JobCode: string, JobId: string) => void;
}

const AllXcelianJobs: React.FC<TableProps> = ({
  clickedIndex,
  data,
  isMobileJob,
  handleGetJobDetails,
  handleUpdateInterviewStatus,
  handleUpdateJobsViewCount,
}) => {
  const [errorAlert, setErrorAlert] = React.useState('');
  const [mediaAlert, setMediaAlert] = React.useState('');
  const copyToClipboard = (shareUrl: string, jobType: string, jobId: string) => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setMediaAlert(`DESK-${jobId}`);
    });
    handleUpdateJobsViewCount(jobType, jobId);
  };
  const shareOnMobile = async (shareUrl: string, jobType: string, jobId: string) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'CampusXcel Jobs',
          text: 'Check out this job on CampusXcel!',
          url: shareUrl,
        });
        setMediaAlert(`MOB-${jobId}`); // Sharing was successful
      } catch (error) {
        setMediaAlert(`MOB-${jobId}`);
        setErrorAlert('Sharing canceled by user');
      }
    } else {
      setMediaAlert(`MOB-${jobId}`);
      setErrorAlert('Sharing canceled by user');
    }
    handleUpdateJobsViewCount(jobType, jobId);
  };

  return (
    <div>
      {data.length > 0
        ? (
          <div>
            {/* <div className="my-3 align-center">
              <span className="chip-box">All Jobs</span>
            </div> */}
            <Row className="align-start margin-jobs-xcln-card">
              {data.map((jobData, index) => (
                <Col lg="12 my-1" xs="12 my-1" key={`job-info${index.toString()}`}>
                  <Card className="card-joblist-lite my-1">
                    <CardBody className="padding-zero cursor-pointer" onClick={() => handleGetJobDetails(jobData.JobCd, jobData.JobId)}>
                      <Row className="my-1 xcln-job-header-bg">
                        <Col xs="9" lg="10">
                          <div className="align-start">
                            <span className={`${clickedIndex === jobData.JobId ? 'xcln-job-card-selected' : 'xcln-jobs-header-text'}`}>
                              {jobData.nme}
                            </span>
                          </div>
                          <div className="align-start">
                            <span className="xcln-jobs-org-text">{jobData.cNme}
                            </span>
                            <span className="xcln-jobs-org-text mx-2">|</span>
                            <span className="xcln-jobs-org-text">Applicants({jobData.aplCnt})
                            </span>
                            {jobData.isFemale === 'YES' ? (
                              <>
                                <span className="xcln-jobs-org-text mx-2">|</span>
                                <CardImg src="/images/icons/female.png" className="xcln-job-icon-size1" alt="Icon 3" />
                              </>
                            ) : null}
                            {jobData.isAbled === 'YES' ? (
                              <>
                                <span className="xcln-jobs-org-text mx-2">|</span>
                                <CardImg src="/images/icons/diffabled.png" className="xcln-job-icon-size1" alt="Icon 3" />
                              </>
                            ) : null}
                          </div>
                        </Col>
                        <Col xs="3" lg="2">
                          <div className="align-end">
                            <CardImg className="xcln-job-logo" src={jobData.logoUrl !== '' ? jobData.logoUrl : '/images/icons/jobs-pending.png'} alt="jobs" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-1 pt-2">
                        <Col xs="12" lg="12">
                          <div className="align-start">
                            <span className="xcln-jobs-org-text">
                              <FontAwesomeIcon icon={faBriefcase} className="mr" />
                              {jobData.expR1} to {jobData.expR2} Yrs
                            </span>
                            <span className="xcln-jobs-org-text mx-2">|</span>
                            <span className="xcln-jobs-org-text">
                              <FontAwesomeIcon icon={faIndianRupee} className="mr" />
                              {jobData.amnt !== '' ? <>{jobData.amnt} <span className="text-lite-grey">{AmountSLashTpye.find((state) => state.value === jobData.amntTp)?.label || ''}</span></> : 'Not Disclosed'}
                            </span>
                            <span className="xcln-jobs-org-text mx-2">|</span>
                            <span className="xcln-jobs-org-text">
                              <FontAwesomeIcon icon={faLocationDot} className="mr" />
                              {jobData.city}
                            </span>
                            {jobData.isCV
                              ? (
                                <>
                                  <span className="xcln-jobs-org-text mx-2">|</span>
                                  <span className="correct-pass-key">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr" /> Resume
                                  </span>
                                </>
                              ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row className="xcln-job-schedule-container pt-3">
                        <Col xs="auto" className="my-1 mx-1">
                          <Card className="xcln-chip-box-jobs-pvt">
                            <span className="puser-user-name-text">
                              <FontAwesomeIcon icon={faCircle} className="xcln-job-dot" />
                              {ListJobSchedule.find((state) => state.value === jobData.schdl)?.label || ''}
                            </span>
                          </Card>
                        </Col>
                        {jobData.oSchdl && (
                        <Col xs="auto" className="my-1 mx-1">
                          <Card className="xcln-chip-box-jobs-pvt">
                            <span className="puser-user-name-text">
                              <FontAwesomeIcon icon={faCircle} className="xcln-job-dot" />
                              {jobData.oSchdl}
                            </span>
                          </Card>
                        </Col>
                        )}
                        {jobData.jobTp.length > 0 && jobData.jobTp.map((jobType, jobTpIndex) => (
                          <React.Fragment key={`job-tp${jobTpIndex.toString}`}>
                            <Col xs="auto" className="my-1 mx-1">
                              <Card className="xcln-chip-box-jobs-pvt">
                                <span className="puser-user-name-text">
                                  <FontAwesomeIcon icon={faCircle} className="xcln-job-dot" />
                                  {ListJobType.find((state) => state.value === jobType)?.label || ''}
                                </span>
                              </Card>
                            </Col>
                          </React.Fragment>
                        ))}
                        {jobData.skills.length > 0 && jobData.skills.map((skill, skillIndex) => (
                          <React.Fragment key={`skilldata-${skillIndex.toString()}`}>
                            <Col xs="auto" className="my-1 mx-1">
                              <Card className="xcln-chip-box-jobs-pvt">
                                <span className="puser-user-name-text">
                                  <FontAwesomeIcon icon={faCircle} className="xcln-job-dot" />
                                  {skill}
                                </span>
                              </Card>
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </CardBody>
                    <CardFooter className="xcln-card-footer">
                      <Row>
                        <Col xs="8" lg="7">
                          <div className="pt-2 mx-1">
                            <span className="text-lite">vacancies
                              (<span className="puser-user-name-text">{jobData.vacncy}</span>)
                            </span>
                            <span className="text-lite mx-2">
                              |
                            </span>
                            <span className="text-lite">
                              views ({jobData.mView ? jobData.mView : 0})
                            </span>
                            <span className="text-lite mx-2">
                              |
                            </span>
                            {isMobileJob ? (
                              <Button
                                id={`mobile-share-${jobData.JobId}`}
                                className="button-icon padding-zero"
                                onClick={() => shareOnMobile(
                                  `${window.location.origin}/job-details/${jobData.JobCd}/${jobData.JobId}`,
                                  jobData.JobCd,
                                  jobData.JobId,
                                )}
                              >
                                <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
                              </Button>
                            ) : (
                              <Button
                                id={`desktop-share-${jobData.JobId}`}
                                className="button-icon padding-zero"
                                onClick={() => copyToClipboard(
                                  `${window.location.origin}/job-details/${jobData.JobCd}/${jobData.JobId}`,
                                  jobData.JobCd,
                                  jobData.JobId,
                                )}
                              >
                                <FontAwesomeIcon className="share-icon" icon={faShareNodes} />
                              </Button>
                            )}
                            {/* Tooltip for sharing actions */}
                            <UncontrolledTooltip
                              delay={0}
                              target={isMobileJob ? `mobile-share-${jobData.JobId}` : `desktop-share-${jobData.JobId}`}
                            >
                              {mediaAlert === `DESK-${jobData.JobId}` ? 'Copied to Clipboard'
                                : mediaAlert === `MOB-${jobData.JobId}` && errorAlert === '' ? 'Share'
                                  : mediaAlert === `MOB-${jobData.JobId}` && errorAlert !== '' ? errorAlert
                                    : 'Share'}
                            </UncontrolledTooltip>
                          </div>
                        </Col>
                        <Col xs="4" lg="5">
                          <div className="pt-2 align-end">
                            <Button disabled={jobData.aplySts === 'APPLD'} className="button-icon padding-zero" onClick={() => handleUpdateInterviewStatus(jobData.JobCd, jobData.JobId, 'APPLD', jobData.forClnt, jobData.isCV, jobData.uResume)}>
                              <span className="jobs-apply-text">{jobData.aplySts === 'APPLD' ? 'Job Applied' : 'Apply'}
                              </span>
                            </Button>
                            {jobData.aplySts === 'APPLD'
                              ? (
                                <>
                                  <Button className="button-icon padding-zero" id={jobData.JobId}><FontAwesomeIcon className="text-lite mx-1" icon={faInfoCircle} /></Button>
                                  <UncontrolledTooltip
                                    target={jobData.JobId}
                                  >
                                    {jobData.appldBy === 'SELF' ? 'Self Applied' : `This job is applied by - ${jobData.appldBy}`}
                                  </UncontrolledTooltip>
                                </>
                              )
                              : null}
                            {jobData.aplySts === '' || jobData.aplySts === 'SVD' ? <span className="text-lite-grey mx-2">|</span> : null}
                            <Button disabled={jobData.aplySts === 'SVD' || jobData.aplySts === 'APPLD'} className="button-icon  padding-zero mr" onClick={() => handleUpdateInterviewStatus(jobData.JobCd, jobData.JobId, 'SVD', jobData.forClnt, jobData.isCV, jobData.uResume)}>
                              <span className="jobs-apply-text">{jobData.aplySts === 'SVD' ? 'Job Saved' : jobData.aplySts === 'APPLD' ? '' : 'Save'}
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Jobs Found</span>
          </div>
        )}
    </div>
  );
};
export default AllXcelianJobs;
