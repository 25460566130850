import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardImg } from 'reactstrap';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'typesafe-actions';
import { AddJobComponents } from './add-jobs/add.job.component';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../../utils/service/localstorage-service';
import ListJobsComponent from './add-jobs/list-jobs/list.jobs.component';
import {
  JobIdResponseData, Phase2UpdateJobInput, Phase3UpdateJobInput, Phase4UpdateJobInput,
  Phase5UpdateJobInput,
  Phase6UpdateJobInput,
} from '../../../../../../services/staffing/job-board/update-job-data/update.job.data.types';
import { JobAddDataInput } from '../../../../../../services/staffing/job-board/add-new-job/add-job.types';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { errorAlert } from '../../../../../../utils/alert';
import LoaderData from '../../../../../../utils/loader';
import {
  clearAddNewJobReq, clearEditJobsReq, clearJobByDetailsReq, clearjobByIdReq, clearUploadS3FilesReq, jobByDetailsReq, jobByIdReq,
} from '../../../../../../store/campusXcel/actions';

export const RecruitJobs: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [loadingHomeJob, setLoadingHomeJob] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [apiReqTp, setApiReqTp] = React.useState('');
  const [operationTp, setOperationTp] = React.useState('list-jobs');
  const [reqClientId, setReqClientId] = React.useState('');
  const getJobByIdResponse = useSelector((state: RootState) => state.campusXcel.getJobById);
  const getJobPreviewResponse = useSelector((state: RootState) => state.campusXcel.getJobByDetails);
  const [addNewJob, setAddNewJob] = React.useState<JobAddDataInput>({
    pStg: 'PSTG1',
    clntId: '',
    staffCd: '',
    nme: '',
    pCd: '',
    wrkPref: 'CLNT',
    city: '',
    st: '',
    addr: '',
  });
  const [updatePh2Job, setUpdatePh2Job] = React.useState<Phase2UpdateJobInput>({
    pStg: 'PSTG2',
    JobCd: '',
    JobId: '',
    jobTp: [],
    schdl: '',
    oSchdl: '',
    isPlndDt: false,
    plndDt: '',
    vacncy: 1,
    timelne: '',
    isAbled: 'NO',
    isFemale: 'NO',
    skills: [],
    oSkills: [],
    desg: '',
  });
  const [updatePh3Pay, setUpdatePh3Pay] = React.useState<Phase3UpdateJobInput>({
    pStg: 'PSTG3',
    JobCd: '',
    JobId: '',
    payBy: 'EXAMNT', // ENUM
    amnt: '',
    amntTp: 'PRY',
    suppPay: [],
    oSuppPay: [],
    perk: [],
    oPerk: [],
    expR1: 0,
    expR2: 1,
  });
  const [updatePh4JD, setUpdatePh4JD] = React.useState<Phase4UpdateJobInput>({
    pStg: 'PSTG4',
    JobCd: '',
    JobId: '',
    jdFile: '',
  });
  const [updatePh5Pref, setUpdatePh5Pref] = React.useState<Phase5UpdateJobInput>({
    pStg: 'PSTG5',
    JobCd: '',
    JobId: '',
    jDCntPh: '',
    dailUpdEml: '',
    potCndEml: '',
    jobKey: [],
    isCV: false,
    isAppTlne: false,
    appTlne: '',
  });
  const [previewPh6, setPreviewPh6] = React.useState<Phase6UpdateJobInput>({
    pStg: '',
    JobCd: '',
    JobId: '',
    forClnt: '',
    nme: '',
    pCd: '',
    wrkPref: 'CLNT',
    city: '',
    st: '',
    addr: '',
    jobTp: [],
    schdl: '',
    oSchdl: '',
    isPlndDt: false,
    plndDt: '',
    vacncy: 0,
    timelne: '',
    payBy: 'EXAMNT',
    amnt: '',
    amntTp: 'PRY',
    suppPay: [],
    oSuppPay: [],
    perk: [],
    oPerk: [],
    expR1: 0,
    expR2: 1,
    jdFile: '',
    jDCntPh: '',
    dailUpdEml: '',
    potCndEml: '',
    jobKey: [],
    isAbled: 'NO',
    isFemale: 'NO',
    skills: [],
    oSkills: [],
    desg: '',
    isCV: false,
    isAppTlne: false,
    appTlne: '',
  });
  const [addJobRes, setAddJobRes] = React.useState<JobIdResponseData>({
    JobCd: '',
    JobId: '',
    NxtStg: '',
  });
  const [jobPhStatus, setJobPhStatus] = React.useState<1 | 2 | 3 | 4 | 5 | 6>(1);

  const handleGetPrevData = (resPhase: string, jobCode: string, jobIdent: string) => {
    dispatch(clearjobByIdReq());
    dispatch(jobByIdReq({
      requestType: APIHeader.REQ_GET_JOB_BY_ID,
      uTp: UserType.RCRT,
      token: tokenData,
      jobCd: jobCode,
      jobId: jobIdent,
      resStg: resPhase,
    }));
  };

  const handleJobPreviewData = (jobCode: string, jobIdent: string) => {
    dispatch(clearJobByDetailsReq());
    dispatch(jobByDetailsReq({
      requestType: APIHeader.REQ_GET_JOB_DETAILS_BY_ID,
      uTp: UserType.RCRT,
      token: tokenData,
      jobCd: jobCode,
      jobId: jobIdent,
    }));
  };

  // NEW Logic Data - Start
  React.useEffect(() => {
    if (addJobRes.JobCd !== '' && addJobRes.JobId !== '') {
      if (apiReqTp === 'NEW-ADD-REQ-TO-PH2' || apiReqTp === 'PSTG2-req' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH2') {
        setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        dispatch(clearAddNewJobReq());
        dispatch(clearEditJobsReq());
        setJobPhStatus(2);
        setOperationTp('add-jobs');
        setAddNewJob({
          ...addNewJob,
          pStg: 'PSTG1',
          clntId: '',
          staffCd: '',
          nme: '',
          pCd: '',
          wrkPref: 'CLNT',
          city: '',
          st: '',
          addr: '',
        });
      } else if (apiReqTp === 'NEW-ADD-REQ-TO-PH3' || apiReqTp === 'PSTG3-req' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH3') {
        setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        dispatch(clearEditJobsReq());
        setJobPhStatus(3);
        setOperationTp('add-jobs');
        setUpdatePh2Job({
          ...updatePh2Job,
          pStg: 'PSTG2',
          JobCd: '',
          JobId: '',
          jobTp: [],
          schdl: '',
          oSchdl: '',
          isPlndDt: false,
          plndDt: '',
          vacncy: 1,
          timelne: '',
          isAbled: 'NO',
          isFemale: 'NO',
          skills: [],
          oSkills: [],
          desg: '',
        });
      } else if (apiReqTp === 'NEW-ADD-REQ-TO-PH4' || apiReqTp === 'PSTG4-req' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH4') {
        dispatch(clearEditJobsReq());
        dispatch(clearUploadS3FilesReq());
        setJobPhStatus(4);
        setOperationTp('add-jobs');
        setUpdatePh4JD({ ...updatePh4JD, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        setUpdatePh3Pay({
          ...updatePh3Pay,
          pStg: 'PSTG3',
          JobCd: '',
          JobId: '',
          payBy: 'EXAMNT', // ENUM
          amnt: '',
          amntTp: 'PRY',
          suppPay: [],
          oSuppPay: [],
          perk: [],
          oPerk: [],
          expR1: 0,
          expR2: 1,
        });
      } else if (apiReqTp === 'NEW-ADD-REQ-TO-PH5' || apiReqTp === 'PSTG5-req' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH5') {
        dispatch(clearUploadS3FilesReq());
        dispatch(clearEditJobsReq());
        setJobPhStatus(5);
        setOperationTp('add-jobs');
        setUpdatePh5Pref({ ...updatePh5Pref, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        setUpdatePh4JD({
          ...updatePh4JD,
          pStg: 'PSTG4',
          JobCd: '',
          JobId: '',
          jdFile: '',
        });
      } else if (apiReqTp === 'NEW-ADD-REQ-TO-PH6' || apiReqTp === 'PSTG6-req' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH6') {
        setUpdatePh5Pref({
          ...updatePh5Pref,
          pStg: 'PSTG5',
          JobCd: '',
          JobId: '',
          jDCntPh: '',
          dailUpdEml: '',
          potCndEml: '',
          jobKey: [],
          isCV: false,
          isAppTlne: false,
          appTlne: '',
        });
        dispatch(clearEditJobsReq());
        setPreviewPh6({ ...previewPh6, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        handleJobPreviewData(addJobRes.JobCd, addJobRes.JobId);
      }
    }
  }, [apiReqTp, addJobRes.JobCd, addJobRes.JobId]);

  React.useEffect(() => {
    if (addJobRes.JobCd !== '' && addJobRes.JobId !== '') {
      if (apiReqTp === 'EDIT-BACK-REQ-TO-PH1') {
        dispatch(clearAddNewJobReq());
        handleGetPrevData('PHASE1', addJobRes.JobCd, addJobRes.JobId);
      } else if (apiReqTp === 'EDIT-BACK-REQ-TO-PH2') {
        dispatch(clearEditJobsReq());
        setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        handleGetPrevData('PHASE2', addJobRes.JobCd, addJobRes.JobId);
        setJobPhStatus(2);
        setOperationTp('add-jobs');
      } else if (apiReqTp === 'EDIT-BACK-REQ-TO-PH3') {
        dispatch(clearEditJobsReq());
        setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        handleGetPrevData('PHASE3', addJobRes.JobCd, addJobRes.JobId);
        setJobPhStatus(3);
        setOperationTp('add-jobs');
      } else if (apiReqTp === 'EDIT-BACK-REQ-TO-PH4') {
        dispatch(clearEditJobsReq());
        setUpdatePh4JD({ ...updatePh4JD, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        handleGetPrevData('PHASE4', addJobRes.JobCd, addJobRes.JobId);
        setJobPhStatus(4);
        setOperationTp('add-jobs');
      } else if (apiReqTp === 'EDIT-BACK-REQ-TO-PH5') {
        dispatch(clearEditJobsReq());
        setUpdatePh5Pref({ ...updatePh5Pref, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
        handleGetPrevData('PHASE5', addJobRes.JobCd, addJobRes.JobId);
        setJobPhStatus(5);
        setOperationTp('add-jobs');
      } else if (apiReqTp === 'EDIT-BACK-REQ-TO-PH6') {
        handleGetPrevData('PHASE6', addJobRes.JobCd, addJobRes.JobId);
        setJobPhStatus(6);
        dispatch(clearEditJobsReq());
        setOperationTp('add-jobs');
      }
    }
  }, [apiReqTp, addJobRes.JobCd, addJobRes.JobId]);
  // NEW Logic Data - End

  // Below is from List Response
  // New Form Addition
  // React.useEffect(() => {
  //   if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH2') {
  //     setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     dispatch(clearAddNewJobReq());
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(2);
  //     setOperationTp('add-jobs');
  //     setAddNewJob({
  //       ...addNewJob,
  //       pStg: 'PSTG1',
  //       clntId: '',
  //       staffCd: '',
  //       nme: '',
  //       pCd: '',
  //       wrkPref: '',
  //       city: '',
  //       st: '',
  //       addr: '',
  //     });
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH3') {
  //     setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(3);
  //     setOperationTp('add-jobs');
  //     setUpdatePh2Job({
  //       ...updatePh2Job,
  //       pStg: 'PSTG2',
  //       JobCd: '',
  //       JobId: '',
  //       jobTp: [],
  //       schdl: '',
  //       oSchdl: '',
  //       isPlndDt: false,
  //       plndDt: '',
  //       vacncy: 1,
  //       timelne: '',
  //     });
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH4') {
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(4);
  //     setOperationTp('add-jobs');
  //     setUpdatePh4JD({ ...updatePh4JD, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     setUpdatePh3Pay({
  //       ...updatePh3Pay,
  //       pStg: 'PSTG3',
  //       JobCd: '',
  //       JobId: '',
  //       payBy: '',
  //       amnt: '',
  //       amntTp: '',
  //       suppPay: [],
  //       oSuppPay: [],
  //       perk: [],
  //       oPerk: [],
  //     });
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH5') {
  //     dispatch(clearUploadS3FilesReq());
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(5);
  //     setOperationTp('add-jobs');
  //     setUpdatePh5Pref({ ...updatePh5Pref, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     setUpdatePh4JD({
  //       ...updatePh4JD,
  //       pStg: 'PSTG4',
  //       JobCd: '',
  //       JobId: '',
  //       jdFile: '',
  //     });
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH6') {
  //     setUpdatePh5Pref({
  //       ...updatePh5Pref,
  //       pStg: 'PSTG5',
  //       JobCd: '',
  //       JobId: '',
  //       jDCntPh: '',
  //       dailUpdEml: '',
  //       potCndEml: '',
  //     });
  //     dispatch(clearEditJobsReq());
  //     // setJobPhStatus(6);
  //     // setOperationTp('add-jobs');
  //     setPreviewPh6({ ...previewPh6, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleJobPreviewData(addJobRes.JobCd, addJobRes.JobId);
  //   }
  // }, [addJobRes.JobCd, addJobRes.JobId, apiReqTp]);
  // // Edit Form With Next Stage as previous is assumed Completed
  // React.useEffect(() => {
  //   if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'PSTG2-req') {
  //     setJobPhStatus(2);
  //     setOperationTp('add-jobs');
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'PSTG3-req') {
  //     setJobPhStatus(3);
  //     setOperationTp('add-jobs');
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     setUpdatePh2Job(updatePh2Job);
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'PSTG4-req') {
  //     setJobPhStatus(4);
  //     setOperationTp('add-jobs');
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh4JD({ ...updatePh4JD, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     setUpdatePh3Pay(updatePh3Pay);
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'PSTG5-req') {
  //     setJobPhStatus(5);
  //     setOperationTp('add-jobs');
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh5Pref({ ...updatePh5Pref, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     setUpdatePh4JD(updatePh4JD);
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'PSTG6-req') {
  //     setJobPhStatus(6);
  //     dispatch(clearEditJobsReq());
  //     setOperationTp('add-jobs');
  //     setUpdatePh5Pref(updatePh5Pref);
  //     handleJobPreviewData(addJobRes.JobCd, addJobRes.JobId);
  //   }
  // }, [apiReqTp, addJobRes.JobCd, addJobRes.JobId]);

  // // Edit Form But with Previous or Next Button
  // React.useEffect(() => {
  //   if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH1') {
  //     dispatch(clearAddNewJobReq());
  //     handleGetPrevData('PHASE1', addJobRes.JobCd, addJobRes.JobId);
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH2') {
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleGetPrevData('PHASE2', addJobRes.JobCd, addJobRes.JobId);
  //     setJobPhStatus(2);
  //     setOperationTp('add-jobs');
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH3') {
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleGetPrevData('PHASE3', addJobRes.JobCd, addJobRes.JobId);
  //     setJobPhStatus(3);
  //     setOperationTp('add-jobs');
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH4') {
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh4JD({ ...updatePh4JD, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleGetPrevData('PHASE4', addJobRes.JobCd, addJobRes.JobId);
  //     setJobPhStatus(4);
  //     setOperationTp('add-jobs');
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH5') {
  //     dispatch(clearEditJobsReq());
  //     setUpdatePh5Pref({ ...updatePh5Pref, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleGetPrevData('PHASE5', addJobRes.JobCd, addJobRes.JobId);
  //     setJobPhStatus(5);
  //     setOperationTp('add-jobs');
  //   } else if (addJobRes.JobCd !== '' && addJobRes.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH6') {
  //     // setUpdatePh6Pay({ ...updatePh6Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleGetPrevData('PHASE6', addJobRes.JobCd, addJobRes.JobId);
  //     setJobPhStatus(6);
  //     dispatch(clearEditJobsReq());
  //     setOperationTp('add-jobs');
  //   } else if (apiReqTp === 'EDIT-FORWARD-REQ-TO-PH2') {
  //     setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(2);
  //     setOperationTp('add-jobs');
  //   } else if (apiReqTp === 'EDIT-FORWARD-REQ-TO-PH3') {
  //     setUpdatePh3Pay({ ...updatePh3Pay, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(3);
  //     setOperationTp('add-jobs');
  //   } else if (apiReqTp === 'EDIT-FORWARD-REQ-TO-PH4') {
  //     dispatch(clearEditJobsReq());
  //     setJobPhStatus(4);
  //     setOperationTp('add-jobs');
  //     setUpdatePh4JD({ ...updatePh4JD, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //   } else if (apiReqTp === 'EDIT-FORWARD-REQ-TO-PH5') {
  //     dispatch(clearEditJobsReq());
  //     dispatch(clearUploadS3FilesReq());
  //     setJobPhStatus(5);
  //     setOperationTp('add-jobs');
  //     setUpdatePh5Pref({ ...updatePh5Pref, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //   } else if (apiReqTp === 'EDIT-FORWARD-REQ-TO-PH6') {
  //     setJobPhStatus(6);
  //     setOperationTp('add-jobs');
  //     setPreviewPh6({ ...previewPh6, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //     handleJobPreviewData(addJobRes.JobCd, addJobRes.JobId);
  //   }
  // }, [addJobRes.JobCd, addJobRes.JobId, apiReqTp]);

  React.useEffect(() => {
    if (getJobByIdResponse.isError && getJobByIdResponse.message !== '') {
      setLoadingHomeJob(false);
      setAlertMsg(getJobByIdResponse.message);
      setErrorShowAlert(true);
    }
  }, [getJobByIdResponse.isError, getJobByIdResponse.message]);

  React.useEffect(() => {
    if (!getJobByIdResponse.isError && getJobByIdResponse.message === 'executed' && apiReqTp === 'EDIT-BACK-REQ-TO-PH1') {
      setLoadingHomeJob(false);
      setAddNewJob({
        ...addNewJob,
        pStg: 'PSTG1',
        clntId: getJobByIdResponse.data.forClnt ? getJobByIdResponse.data.forClnt : '',
        staffCd: getJobByIdResponse.data.JobCd ? getJobByIdResponse.data.JobCd : '',
        nme: getJobByIdResponse.data.nme ? getJobByIdResponse.data.nme : '',
        pCd: getJobByIdResponse.data.pCd ? getJobByIdResponse.data.pCd : '',
        wrkPref: getJobByIdResponse.data.wrkPref ? getJobByIdResponse.data.wrkPref : 'CLNT',
        city: getJobByIdResponse.data.city ? getJobByIdResponse.data.city : '',
        st: getJobByIdResponse.data.st ? getJobByIdResponse.data.st : '',
        addr: getJobByIdResponse.data.addr ? getJobByIdResponse.data.addr : '',
      });
      setJobPhStatus(1);
      setOperationTp('add-jobs');
    }
  }, [getJobByIdResponse.isError, getJobByIdResponse.message, apiReqTp]);

  React.useEffect(() => {
    if (!getJobByIdResponse.isError && getJobByIdResponse.message === 'executed' && apiReqTp === 'EDIT-BACK-REQ-TO-PH2') {
      setLoadingHomeJob(false);
      // setApiReqTp('PSTG3-req');
      setUpdatePh2Job({
        ...updatePh2Job,
        pStg: 'PSTG2',
        JobCd: getJobByIdResponse.data.JobCd ? getJobByIdResponse.data.JobCd : '',
        JobId: getJobByIdResponse.data.JobId ? getJobByIdResponse.data.JobId : '',
        jobTp: getJobByIdResponse.data.jobTp ? getJobByIdResponse.data.jobTp : [],
        schdl: getJobByIdResponse.data.schdl ? getJobByIdResponse.data.schdl : '',
        oSchdl: getJobByIdResponse.data.oSchdl ? getJobByIdResponse.data.oSchdl : '',
        isPlndDt: getJobByIdResponse.data.isPlndDt ? getJobByIdResponse.data.isPlndDt : false,
        plndDt: getJobByIdResponse.data.plndDt ? getJobByIdResponse.data.plndDt : '',
        vacncy: getJobByIdResponse.data.vacncy ? getJobByIdResponse.data.vacncy : 0,
        timelne: getJobByIdResponse.data.timelne ? getJobByIdResponse.data.timelne : '',
        isFemale: getJobByIdResponse.data.isFemale ? getJobByIdResponse.data.isFemale : '',
        isAbled: getJobByIdResponse.data.isAbled ? getJobByIdResponse.data.isAbled : '',
        skills: getJobByIdResponse.data.skills ? getJobByIdResponse.data.skills : [],
        oSkills: getJobByIdResponse.data.oSkills ? getJobByIdResponse.data.oSkills : [],
        desg: getJobByIdResponse.data.desg ? getJobByIdResponse.data.desg : '',
      });
    }
  }, [getJobByIdResponse.isError, getJobByIdResponse.message, apiReqTp]);

  React.useEffect(() => {
    if (!getJobByIdResponse.isError && getJobByIdResponse.message === 'executed' && apiReqTp === 'EDIT-BACK-REQ-TO-PH3') {
      setLoadingHomeJob(false);
      // setApiReqTp('PSTG3-req');
      setUpdatePh3Pay({
        ...updatePh3Pay,
        pStg: 'PSTG3',
        JobCd: getJobByIdResponse.data.JobCd ? getJobByIdResponse.data.JobCd : '',
        JobId: getJobByIdResponse.data.JobId ? getJobByIdResponse.data.JobId : '',
        payBy: getJobByIdResponse.data.payBy ? getJobByIdResponse.data.payBy : 'EXAMNT',
        amnt: getJobByIdResponse.data.amnt ? getJobByIdResponse.data.amnt : '',
        amntTp: getJobByIdResponse.data.amntTp ? getJobByIdResponse.data.amntTp : 'PRY',
        suppPay: getJobByIdResponse.data.suppPay ? getJobByIdResponse.data.suppPay : [],
        oSuppPay: getJobByIdResponse.data.oSuppPay ? getJobByIdResponse.data.oSuppPay : [],
        perk: getJobByIdResponse.data.perk ? getJobByIdResponse.data.perk : [],
        oPerk: getJobByIdResponse.data.oPerk ? getJobByIdResponse.data.oPerk : [],
        expR1: getJobByIdResponse.data.expR1 ? getJobByIdResponse.data.expR1 : 0,
        expR2: getJobByIdResponse.data.expR2 ? getJobByIdResponse.data.expR2 : 0,
      });
    }
  }, [getJobByIdResponse.isError, getJobByIdResponse.message, apiReqTp]);

  React.useEffect(() => {
    if (!getJobByIdResponse.isError && getJobByIdResponse.message === 'executed' && apiReqTp === 'EDIT-BACK-REQ-TO-PH4') {
      setLoadingHomeJob(false);
      // setApiReqTp('PSTG3-req');
      setUpdatePh4JD({
        ...updatePh4JD,
        pStg: 'PSTG4',
        JobCd: getJobByIdResponse.data.JobCd ? getJobByIdResponse.data.JobCd : '',
        JobId: getJobByIdResponse.data.JobId ? getJobByIdResponse.data.JobId : '',
        jdFile: getJobByIdResponse.data.jdFile ? getJobByIdResponse.data.jdFile : '',
      });
    }
  }, [getJobByIdResponse.isError, getJobByIdResponse.message, apiReqTp]);

  React.useEffect(() => {
    if (!getJobByIdResponse.isError && getJobByIdResponse.message === 'executed' && apiReqTp === 'EDIT-BACK-REQ-TO-PH5') {
      setLoadingHomeJob(false);
      setUpdatePh5Pref({
        ...updatePh5Pref,
        pStg: 'PSTG5',
        JobCd: getJobByIdResponse.data.JobCd ? getJobByIdResponse.data.JobCd : '',
        JobId: getJobByIdResponse.data.JobId ? getJobByIdResponse.data.JobId : '',
        jDCntPh: getJobByIdResponse.data.jDCntPh ? getJobByIdResponse.data.jDCntPh : '',
        dailUpdEml: getJobByIdResponse.data.dailUpdEml ? getJobByIdResponse.data.dailUpdEml : '',
        potCndEml: getJobByIdResponse.data.potCndEml ? getJobByIdResponse.data.potCndEml : '',
        jobKey: getJobByIdResponse.data.jobKey ? getJobByIdResponse.data.jobKey : [],
        isCV: getJobByIdResponse.data.isCV ? getJobByIdResponse.data.isCV : false,
        isAppTlne: getJobByIdResponse.data.isAppTlne ? getJobByIdResponse.data.isAppTlne : false,
        appTlne: getJobByIdResponse.data.appTlne ? getJobByIdResponse.data.appTlne : '',
      });
    }
  }, [getJobByIdResponse.isError, getJobByIdResponse.message, apiReqTp]);

  // Preview Data Setup - Start
  React.useEffect(() => {
    if (!getJobPreviewResponse.isError && getJobPreviewResponse.message === 'executed'
        && (apiReqTp === 'PSTG6-req' || apiReqTp === 'NEW-ADD-REQ-TO-PH6' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH6')) {
      setLoadingHomeJob(false);
      // Ensure the state is fully updated before proceeding

      const updatedPreviewPh6 = {
        ...previewPh6,
        pStg: 'PSTGAPPR',
        JobCd: getJobPreviewResponse.data.JobCd ? getJobPreviewResponse.data.JobCd : '',
        JobId: getJobPreviewResponse.data.JobId ? getJobPreviewResponse.data.JobId : '',
        nme: getJobPreviewResponse.data.nme ? getJobPreviewResponse.data.nme : '',
        forClnt: getJobPreviewResponse.data.forClnt ? getJobPreviewResponse.data.forClnt : '',
        wrkPref: getJobPreviewResponse.data.wrkPref ? getJobPreviewResponse.data.wrkPref : 'CLNT',
        pCd: getJobPreviewResponse.data.pCd ? getJobPreviewResponse.data.pCd : '',
        city: getJobPreviewResponse.data.city ? getJobPreviewResponse.data.city : '',
        st: getJobPreviewResponse.data.st ? getJobPreviewResponse.data.st : '',
        addr: getJobPreviewResponse.data.addr ? getJobPreviewResponse.data.addr : '',
        jobTp: getJobPreviewResponse.data.jobTp ? getJobPreviewResponse.data.jobTp : [],
        schdl: getJobPreviewResponse.data.schdl ? getJobPreviewResponse.data.schdl : '',
        oSchdl: getJobPreviewResponse.data.oSchdl ? getJobPreviewResponse.data.oSchdl : '',
        isPlndDt: getJobPreviewResponse.data.isPlndDt ? getJobPreviewResponse.data.isPlndDt : false,
        timelne: getJobPreviewResponse.data.timelne ? getJobPreviewResponse.data.timelne : '',
        plndDt: getJobPreviewResponse.data.plndDt ? getJobPreviewResponse.data.plndDt : '',
        vacncy: getJobPreviewResponse.data.vacncy ? getJobPreviewResponse.data.vacncy : 0,
        payBy: getJobPreviewResponse.data.payBy ? getJobPreviewResponse.data.payBy : 'EXAMNT',
        amnt: getJobPreviewResponse.data.amnt ? getJobPreviewResponse.data.amnt : '',
        amntTp: getJobPreviewResponse.data.amntTp ? getJobPreviewResponse.data.amntTp : 'PRY',
        suppPay: getJobPreviewResponse.data.suppPay ? getJobPreviewResponse.data.suppPay : [],
        oSuppPay: getJobPreviewResponse.data.oSuppPay ? getJobPreviewResponse.data.oSuppPay : [],
        perk: getJobPreviewResponse.data.perk ? getJobPreviewResponse.data.perk : [],
        oPerk: getJobPreviewResponse.data.oPerk ? getJobPreviewResponse.data.oPerk : [],
        expR1: getJobPreviewResponse.data.expR1 ? getJobPreviewResponse.data.expR1 : 0,
        expR2: getJobPreviewResponse.data.expR2 ? getJobPreviewResponse.data.expR2 : 0,
        jdFile: getJobPreviewResponse.data.jdFile ? getJobPreviewResponse.data.jdFile : '',
        jdFileUrl: getJobPreviewResponse.data.jdFileUrl ? getJobPreviewResponse.data.jdFileUrl : '',
        jDCntPh: getJobPreviewResponse.data.jDCntPh ? getJobPreviewResponse.data.jDCntPh : '',
        dailUpdEml: getJobPreviewResponse.data.dailUpdEml ? getJobPreviewResponse.data.dailUpdEml : '',
        potCndEml: getJobPreviewResponse.data.potCndEml ? getJobPreviewResponse.data.potCndEml : '',
        jobKey: getJobPreviewResponse.data.jobKey ? getJobPreviewResponse.data.jobKey : [],
        isCV: getJobPreviewResponse.data.isCV ? getJobPreviewResponse.data.isCV : false,
        isAppTlne: getJobPreviewResponse.data.isAppTlne ? getJobPreviewResponse.data.isAppTlne : false,
        appTlne: getJobPreviewResponse.data.appTlne ? getJobPreviewResponse.data.appTlne : '',
        isFemale: getJobPreviewResponse.data.isFemale ? getJobPreviewResponse.data.isFemale : '',
        isAbled: getJobPreviewResponse.data.isAbled ? getJobPreviewResponse.data.isAbled : '',
        skills: getJobPreviewResponse.data.skills ? getJobPreviewResponse.data.skills : [],
        oSkills: getJobPreviewResponse.data.oSkills ? getJobPreviewResponse.data.oSkills : [],
        desg: getJobPreviewResponse.data.desg ? getJobPreviewResponse.data.desg : '',
      };
      // Update the preview state
      setJobPhStatus(6);
      setOperationTp('add-jobs');
      setPreviewPh6(updatedPreviewPh6);
    }
  }, [getJobPreviewResponse.isError, getJobPreviewResponse.message, apiReqTp]);
  // Preview Data Setup - End

  React.useEffect(() => {
    setReqClientId(userProfile && JSON.parse(userProfile).profileData.rcrtCd);
  }, [userProfile && JSON.parse(userProfile).profileData.rcrtCd]);

  const handleAddNewJob = () => {
    dispatch(clearAddNewJobReq());
    setAddNewJob({
      ...addNewJob,
      pStg: 'PSTG1',
      clntId: '',
      staffCd: '',
      nme: '',
      pCd: '',
      wrkPref: 'CLNT',
      city: '',
      st: '',
      addr: '',
    });
    setUpdatePh2Job({
      ...updatePh2Job,
      pStg: 'PSTG2',
      JobCd: '',
      JobId: '',
      jobTp: [],
      schdl: '',
      oSchdl: '',
      isPlndDt: false,
      plndDt: '',
    });
    setAddJobRes({
      ...addJobRes,
      JobCd: '',
      JobId: '',
      NxtStg: '',
    });
    setJobPhStatus(1); // Change Her eAdd Form stage
    setOperationTp('add-jobs');
    setApiReqTp('new-add-req');
  };
  const handleShowListJob = () => {
    // setAddJobRes({
    //   ...addJobRes,
    //   JobCd: '',
    //   JobId: '',
    //   NxtStg: '',
    // });
    setApiReqTp('');
    setOperationTp('list-jobs');
  };
  return (
    <div>
      { loadingHomeJob ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="allign-start ml my-2">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/jobs.png" alt="batches" />
        <span className="text-lite-grey ml">JOBS</span>
      </div>
      <div className="pt-1">
        <div className="align-end mr-1">
          {operationTp === 'list-jobs' ? (
            <Button className="button-transparent align-center" onClick={handleAddNewJob}>
              <FontAwesomeIcon icon={faPlus} className="mr" />
              Add New
            </Button>
          ) : (
            <Button className="button-transparent align-center" onClick={handleShowListJob}>
              <FontAwesomeIcon icon={faArrowLeft} className="mr" />
              Go Back
            </Button>
          )}
        </div>
      </div>
      <hr className="mx-2 hr-dasshed-lite" />
      <div>
        {operationTp === 'list-jobs'
          ? (
            <ListJobsComponent
              operationTp={operationTp}
              tokenData={tokenData}
              reqClientId={reqClientId}
              addJobRes={addJobRes}
              setAddJobRes={setAddJobRes}
              setApiReqTp={setApiReqTp}
            />
          )
          : (
            <AddJobComponents
              reqClientId={reqClientId}
              tokenData={tokenData}
              addJobRes={addJobRes}
              setAddJobRes={setAddJobRes}
              jobPhStatus={jobPhStatus}
              // setJobPhStatus={setJobPhStatus}
              addNewJob={addNewJob}
              setAddNewJob={setAddNewJob}
              updatePh2Job={updatePh2Job}
              setUpdatePh2Job={setUpdatePh2Job}
              apiReqTp={apiReqTp}
              setApiReqTp={setApiReqTp}
              updatePh3Pay={updatePh3Pay}
              setUpdatePh3Pay={setUpdatePh3Pay}
              updatePh4JD={updatePh4JD}
              setUpdatePh4JD={setUpdatePh4JD}
              updatePh5Pref={updatePh5Pref}
              setUpdatePh5Pref={setUpdatePh5Pref}
              previewPh6={previewPh6}
              setPreviewPh6={setPreviewPh6}
              setLoadingHomeJob={setLoadingHomeJob}
              setOperationTp={setOperationTp}
            />
          )}
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default RecruitJobs;
