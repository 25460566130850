import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import '../../../../recruiter.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddJobPhaseTypeProps } from '../../../../recruiter.type';
import {
  addNewJobReq, clearMetaPinCodeReq, getMetaPinCodeReq, getStaffClientListReq,
} from '../../../../../../../../store/campusXcel/actions';
import { APIHeader, ApiLIMIT, UserType } from '../../../../../../../../utils/constants';
import { ListStaffClientResultData } from '../../../../../../../../services/staffing/clients/list-clients/list-client.types';

export const AddJobPhase1: React.FC<AddJobPhaseTypeProps> = ({
  reqClientId,
  jobPhase,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  setAddJobRes,
  addJobRes,
  addNewJob,
  setAddNewJob,
  apiReqTp,
  setApiReqTp,
}) => {
  const dispatch = useDispatch();
  const [loadingPin, setLoadingPin] = React.useState(false);
  const [loadingClient, setLoadingClient] = React.useState(false);
  const [clientListData, setClientListData] = React.useState<ListStaffClientResultData[]>([]);
  const getPinResponse = useSelector((state: RootState) => state.campusXcel.getMetaPinCode);
  const addJobPhase1Response = useSelector((state: RootState) => state.campusXcel.addNewJob);
  const getClientListResponse = useSelector((state: RootState) => state.campusXcel.getStaffClientList);

  const handleCallPincodeChange = (event: any) => {
    if (event.target.value.length === 6) {
      setLoadingPin(true);
      dispatch(getMetaPinCodeReq({
        uTp: UserType.CMPS,
        token: tokenData,
        requestType: APIHeader.REQ_META_CITY_PIN,
        limit: ApiLIMIT.LIMIT,
        pinCode: event.target.value,
        cntryCd: 'IN',
      }));
    }
    setAddNewJob({ ...addNewJob, pCd: event.target.value });
  };
  React.useEffect(() => {
    if (!getPinResponse.isError && getPinResponse.data.pin !== '' && getPinResponse.message === 'executed') {
      setLoadingPin(false);
      setAddNewJob({
        ...addNewJob, pCd: getPinResponse.data.pin, city: getPinResponse.data.cty, st: getPinResponse.data.st,
      });
      dispatch(clearMetaPinCodeReq());
    }
  }, [getPinResponse.isError, getPinResponse.data.pin, getPinResponse.message]);

  const handleChangeAddJob = (event: any) => {
    setAddNewJob({ ...addNewJob, [event.target.name]: event.target.value });
  };

  const handleClientLoc = (workPref: string) => {
    setAddNewJob({ ...addNewJob, wrkPref: workPref });
  };

  React.useEffect(() => {
    if (jobPhase === 'phase-1' && reqClientId !== '') {
      setLoadingClient(true);
      setAddNewJob({ ...addNewJob, staffCd: reqClientId });
      dispatch(getStaffClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.RCRT,
        limit: 50,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: reqClientId,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [jobPhase, reqClientId, apiReqTp]);

  const handleInputClientChange = (e:string) => {
    if (e) {
      dispatch(getStaffClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.RCRT,
        limit: 50,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: e,
        isSearch: 'YES',
        token: tokenData,
        clntTp: reqClientId,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  };
  const handleSelectClientData = (selectedOption: any) => {
    if (selectedOption) {
      setAddNewJob({
        ...addNewJob,
        clntId: selectedOption.value,
      });
    }
  };

  React.useEffect(() => {
    if (!getClientListResponse.isError && getClientListResponse.message === 'executed') {
      setClientListData(getClientListResponse.data.clntData);
      setLoadingClient(false);
    }
  }, [getClientListResponse.isError, getClientListResponse.message]);

  const clientNameoptions = clientListData.map((client) => ({
    label: `${client.nme}`,
    value: `${client.ClntId}`,
  }));

  const selectedClientNameOptions = clientNameoptions && clientNameoptions.find(
    (option) => (option.value === `${addNewJob.clntId}`),
  );

  const handleSubmitNewJob = (e: any) => {
    e.preventDefault();
    if (apiReqTp === 'new-add-req') {
      setLoadingJob(true);
      dispatch(addNewJobReq({
        inputBody: addNewJob,
        requestType: APIHeader.REQ_ADD_JOB_PHASE1,
        uTp: UserType.RCRT,
        token: tokenData,
      }));
    }
    if (apiReqTp === 'EDIT-BACK-REQ-TO-PH1') {
      // setLoadingJob(true);
      // eslint-disable-next-line no-console
      console.log('Go Ahead and Dispatch API to Update Phase 1');
    }
  };
  React.useEffect(() => {
    if (addJobPhase1Response.error && addJobPhase1Response.message !== '') {
      setLoadingJob(false);
      setAlertMsg(addJobPhase1Response.message);
      setErrorShowAlert(true);
    }
  }, [addJobPhase1Response.error, addJobPhase1Response.message]);

  React.useEffect(() => {
    if (!addJobPhase1Response.error && addJobPhase1Response.data.JobCd !== '' && addJobPhase1Response.data.JobId !== '' && apiReqTp === 'new-add-req') {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: addJobPhase1Response.data.JobCd,
        JobId: addJobPhase1Response.data.JobId,
        NxtStg: addJobPhase1Response.data.NxtStg,
      });
      setApiReqTp('NEW-ADD-REQ-TO-PH2');
    }
  }, [addJobPhase1Response.error, addJobPhase1Response.data.JobCd, addJobPhase1Response.data.JobId, apiReqTp]);

  React.useEffect(() => {
    if (!addJobPhase1Response.error && addJobPhase1Response.data.JobCd !== '' && addJobPhase1Response.data.JobId !== '' && apiReqTp === 'EDIT-BACK-REQ-TO-PH1') {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: addJobPhase1Response.data.JobCd,
        JobId: addJobPhase1Response.data.JobId,
        NxtStg: addJobPhase1Response.data.NxtStg,
      });
      setApiReqTp('EDIT-FORWARD-REQ-TO-PH2');
    }
  }, [addJobPhase1Response.error, addJobPhase1Response.data.JobCd, addJobPhase1Response.data.JobId, apiReqTp]);

  // React.useEffect(() => {
  //   if (!addJobPhase1Response.error && addJobPhase1Response.data.JobCd !== '' && addJobPhase1Response.data.JobId !== '' && apiReqTp === 'EDIT-REQ-TO-PH2') {
  //     setLoadingJob(false);
  //     setAddJobRes({
  //       ...addJobRes,
  //       JobCd: addJobPhase1Response.data.JobCd,
  //       JobId: addJobPhase1Response.data.JobId,
  //       NxtStg: addJobPhase1Response.data.NxtStg,
  //     });
  //     setApiReqTp('EDIT-REQ-TO-PH2');
  //   }
  // }, [addJobPhase1Response.error, addJobPhase1Response.data.JobCd, addJobPhase1Response.data.JobId, apiReqTp]);

  return (
    <div className="my-1">
      <Form className="job-add-card" onSubmit={handleSubmitNewJob}>
        <div className="my-2 mb-3 align-center">
          <span className="text-lite-grey-sub">Provide Basic Details</span>
        </div>
        <Row className="align-center">
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Client Name<span className="mandate-star">*</span></span>
              <Select
                value={addNewJob.clntId === '' ? null : selectedClientNameOptions}
                options={clientNameoptions}
                onChange={handleSelectClientData}
                onInputChange={handleInputClientChange}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                isLoading={loadingClient}
                noOptionsMessage={() => 'No Client Found. Add New'}
                placeholder="Select Client..."
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4">
              <span className="text-lite ml-1 align-start">Job Title<span className="mandate-star">*</span></span>
              <Input
                placeholder="Enter Job Title"
                type="text"
                name="nme"
                required
                value={addNewJob.nme}
                onChange={handleChangeAddJob}
                className="campus-input"
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 ml-1 mx-3">
              <span className="text-lite align-start">Which one best describes Work Preference ?</span>
              <Row className="pt-2 collapse-bg mb-2">
                <Col lg="4" xs="12">
                  <div className="align-start my-1">
                    <Input
                      type="radio"
                      name="wrkPref"
                      checked={addNewJob.wrkPref === 'CLNT'}
                      className="campus-radio-box cursor-pointer"
                      onChange={() => handleClientLoc('CLNT')}
                    />
                    <Label check className="form-check-text ml">From Office</Label>
                  </div>
                </Col>
                <Col lg="4" xs="12">
                  <div className="align-start my-1">
                    <Input
                      type="radio"
                      name="wrkPref"
                      checked={addNewJob.wrkPref === 'HOME'}
                      className="campus-radio-box cursor-pointer"
                      onChange={() => handleClientLoc('HOME')}
                    />
                    <Label check className="form-check-text ml">From Home</Label>
                  </div>
                </Col>
                <Col lg="4" xs="12">
                  <div className="align-start my-1">
                    <Input
                      type="radio"
                      name="wrkPref"
                      checked={addNewJob.wrkPref === 'HYBRD'}
                      className="campus-radio-box cursor-pointer"
                      onChange={() => handleClientLoc('HYBRD')}
                    />
                    <Label check className="form-check-text ml">Hybrid</Label>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="cx-jobs-dashed-card">
              <Row className="mb-1">
                <Col xs="12" lg="5">
                  <div className="pt-4 mx-1">
                    <span className="text-lite ml-1 align-start">Pincode<span className="mandate-star">*</span></span>
                    <Input
                      placeholder="Enter Pincode"
                      type="text"
                      name="pCd"
                      required
                      value={addNewJob.pCd}
                      onChange={handleCallPincodeChange}
                      className="campus-input"
                    />
                  </div>
                </Col>
                <Col xs="12" lg="7">
                  <div className="pt-4 mx-1">
                    <span className="text-lite ml-1 align-start">City<span className="mandate-star">*</span></span>
                    <Input
                      placeholder="Enter City"
                      type="text"
                      name="city"
                      required
                      disabled={loadingPin}
                      value={addNewJob.city}
                      onChange={handleChangeAddJob}
                      className="campus-input"
                    />
                    {loadingPin && (
                    <div className="dot-loader align-center pt-1">
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                    </div>
                    )}
                  </div>
                </Col>
                <Col xs="12" lg="5">
                  <div className="pt-4 mx-1 margin-state-desk">
                    <span className="text-lite ml-1 align-start">State<span className="mandate-star">*</span></span>
                    <Input
                      placeholder="Enter State"
                      type="text"
                      name="st"
                      required
                      disabled={loadingPin}
                      value={addNewJob.st}
                      onChange={handleChangeAddJob}
                      className="campus-input"
                    />
                    {loadingPin && (
                    <div className="dot-loader align-center pt-1">
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                    </div>
                    )}
                  </div>
                </Col>
                <Col xs="12" lg="7">
                  <div className="pt-4 mr-1">
                    <span className="text-lite ml-1 align-start">Address</span>
                    <Input
                      placeholder="Enter Company Address..."
                      type="textarea"
                      name="addr"
                      value={addNewJob.addr}
                      className="campus-text-area mx-2"
                      onChange={handleChangeAddJob}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12" lg="10">
            {/* <hr className="hr-dasshed-lite" /> */}
            <div className="align-end mr pt-2">
              <Button className="button-transparent" disabled={loadingPin}>
                Continue
                <FontAwesomeIcon icon={faArrowRight} className="ml" />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="pt-4" />
    </div>
  );
};

export default AddJobPhase1;
