// components/CustomTable.tsx
import React from 'react';
import {
  Button, Col, Popover, PopoverBody, Row,
} from 'reactstrap';
import './cxTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faChevronLeft, faChevronRight, faCircleInfo, faEnvelopeCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { ListCourseType } from '../constants';

interface TableColumn {
  header: string;
  accessor: string;
}

interface TableProps {
  tableId: string;
  columns: TableColumn[];
  data: any[];
  oSts: string;
  currentPage: number;
  recordsPerPage: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  setEditRecord: (editRecord: any) => void;
  setDeleteRecord: (deleteRecord: any) => void;
  setOtherObj: (otherObj: any) => void; // Any additional data flow
}

const CXTable: React.FC<TableProps> = ({
  tableId,
  columns,
  data,
  oSts,
  currentPage,
  recordsPerPage,
  totalRecords,
  onPageChange,
  setEditRecord,
  setDeleteRecord,
  setOtherObj,
}) => {
  const [isCompanyInfo, setIsCompanyInfo] = React.useState<Record<string, boolean>>({});
  const toggleCompanyInfo = (orgId: string) => {
    setIsCompanyInfo((prevState) => ({
      ...prevState,
      [orgId]: !prevState[orgId],
    }));
  };
  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);
  const handleDeleteData = (record: any) => {
    setDeleteRecord(record); // Pass the record to be deleted
  };

  const handleEditData = (record: any) => {
    setEditRecord(record); // Pass the record to be edited
  };

  const handleOthersData = (record: any, reqFor: string) => {
    if (reqFor === 'SEND_MAIL_ONLY') {
      setOtherObj({
        UserTp: record.UserTp, UserCd: record.UserCd, bNme: record.bNme, lNme: record.lNme, fNme: record.fNme,
      });
    }
  };

  return (
    <div className="table-container">
      {data.length > 0
        ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={`${index.toString()}`}>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={`${index.toString()}`}>
                    {columns.map((column, colIndex) => (
                      <td key={`${colIndex.toString()}`}>
                        {column.accessor
                          ? row[column.accessor]
                          : (
                            <>
                              {column.header === 'Action' && tableId === 'cmpsBatchId' && (
                              <div className="align-center">
                                <Button onClick={() => handleDeleteData(row)} className="button-icon-danger padding-zero">
                                  <FontAwesomeIcon icon={faTrashCan} className="table-icon-danger" />
                                </Button>
                                <Button onClick={() => handleEditData(row)} className="button-icon-edit">
                                  <FontAwesomeIcon icon={faPenToSquare} className="table-icon-prim" />
                                </Button>
                              </div>
                              )}
                              {column.header === 'Action' && tableId === 'cmps-user' && (
                              <div className="align-center">
                                <Button disabled onClick={() => handleDeleteData(row)} className="button-icon-danger padding-zero">
                                  <FontAwesomeIcon icon={faTrashCan} className="table-icon-danger" />
                                </Button>
                                <Button onClick={() => handleEditData(row)} className="button-icon-edit">
                                  <FontAwesomeIcon icon={faPenToSquare} className="table-icon-prim" />
                                </Button>
                              </div>
                              )}
                              {column.header === 'Action' && tableId === 'cmps-cand' && (
                              <div className="align-center">
                                {oSts === 'APPR'
                                  ? (
                                    <>
                                      <Button onClick={() => handleDeleteData(row)} className="button-icon-danger">
                                        <FontAwesomeIcon icon={faBan} className="mx-1" />
                                      </Button>
                                      <Button onClick={() => handleOthersData(row, 'SEND_MAIL_ONLY')} className="button-icon-edit">
                                        <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="mx-1" />
                                      </Button>
                                    </>
                                  ) : null }
                                {oSts === 'NACC'
                                  ? (
                                    <>
                                      <Button onClick={() => handleEditData(row)} className="button-icon-success padding-zero">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mx-1" />
                                      </Button>
                                      <Button onClick={() => handleOthersData(row, 'SEND_MAIL_ONLY')} className="button-icon-edit">
                                        <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="mx-1" />
                                      </Button>
                                    </>
                                  ) : null }
                                  {oSts === 'PADM'
                                    ? (
                                      <>
                                        <Button onClick={() => handleDeleteData(row)} className="button-icon-danger">
                                          <FontAwesomeIcon icon={faBan} className="mx-1" />
                                        </Button>
                                        <Button onClick={() => handleEditData(row)} className="button-icon-success padding-zero">
                                          <FontAwesomeIcon icon={faCheckCircle} className="" />
                                        </Button>
                                      </>
                                    ) : null }
                              </div>
                              )}
                              {column.header === 'Client' && (
                              <div className="align-center">
                                {`${row.cInfo.nme}`}
                                <Button className="button-icon padding-zero" onClick={() => toggleCompanyInfo(row.cCd)} id={row.cCd}>
                                  <FontAwesomeIcon icon={faCircleInfo} className="mx-1" />
                                </Button>
                                <Popover placement="top" isOpen={isCompanyInfo[row.cCd]} target={row.cCd} toggle={() => toggleCompanyInfo(row.cCd)}>
                                  <PopoverBody>
                                    <div className="my-2 align-center">
                                      <span className="cx-info-header">{row.cInfo.nme}</span>
                                    </div>
                                    {row.cInfo.univ !== ''
                                      ? (
                                        <div className="my-2">
                                          <span className="text-lite">University: </span>
                                          <span className="cx-info-text">{row.cInfo.univ}</span>
                                        </div>
                                      ) : null }
                                    <div className="my-2">
                                      <span className="text-lite">Email: </span>
                                      <span className="cx-info-text">{row.cInfo.cntEId}</span>
                                    </div>
                                    <div className="my-2">
                                      <span className="text-lite">Phone1: </span>
                                      <span className="cx-info-text">{row.cInfo.dlCd1} {row.cInfo.cntPh1}</span>
                                    </div>
                                    {row.cInfo.cntPh2 !== ''
                                      ? (
                                        <div className="my-2">
                                          <span className="text-lite">Phone2: </span>
                                          <span className="cx-info-text">{row.cInfo.dlCd2} {row.cInfo.cntPh2}</span>
                                        </div>
                                      ) : null }
                                    <div className="my-2">
                                      <span className="text-lite">Current Status: </span>
                                      <span className="cx-info-text">{row.cInfo.clntSts}</span>
                                    </div>
                                    {row.cInfo.web !== ''
                                      ? (
                                        <div className="my-2">
                                          <span className="text-lite">Website: </span>
                                          <span className="cx-info-text">{row.cInfo.web}</span>
                                        </div>
                                      ) : null }
                                  </PopoverBody>
                                </Popover>
                              </div>
                              )}
                              {column.header === 'Name' && tableId === 'cmps-cand' && (
                              <div className="align-center">
                                {`${row.fNme} ${row.lNme}`}
                              </div>
                              )}
                              {column.header === 'Name' && tableId === 'recruit-user' && (
                              <div className="align-center">
                                {`${row.fNme} ${row.lNme}`}
                              </div>
                              )}
                              {column.header === 'Phone' && tableId === 'recruit-user' && (
                              <div className="align-center">
                                {`${row.dlCd}-${row.ph}`}
                              </div>
                              )}
                              {/* {column.header === 'Course' && tableId === 'cmps-cand' && (
                              <div className="align-center">
                                { ListCourseType.find((course) => course.value === row.crs)?.label || ''}
                              </div>
                              )} */}
                              {column.header === 'Phone1' && (
                              <div className="align-center">
                                {`${row.dlCd1}-${row.cntPh1}`}
                              </div>
                              )}
                              {column.header === 'Phone2' && row.cntPh2 !== '' && (
                              <div className="align-center">
                                {`${row.dlCd2} ${row.cntPh2}`}
                              </div>
                              )}
                              {column.header === 'C-Phone' && (
                              <div className="align-center">
                                {`${row.dlCd} ${row.ph}`}
                              </div>
                              )}
                              {column.header === 'C-User' && (
                              <div className="align-center">
                                {`${row.fNme} ${row.lNme}`}
                              </div>
                              )}
                              {column.header === 'Course' && (
                              <div className="align-center">
                                { ListCourseType.find((course) => course.value === row.crs)?.label || 'HHHH'}
                              </div>
                              )}
                            </>
                          )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col xs="5" lg="5">
                <div className="align-start my-2">
                  <span className="text-lite">Total Records: {totalRecords}</span>
                </div>
              </Col>
              <Col xs="7" lg="7">
                <div className="align-end my-2">
                  <Button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                    className="table-button"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                  <div className="mx-2 table-margin-bot">
                    <span className="table-text">
                      {currentPage + 1} / {totalPages}
                    </span>
                  </div>
                  <Button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage >= totalPages - 1}
                    className="table-button"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Record Found</span>
          </div>
        )}
    </div>
  );
};

export default CXTable;
