import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Font, Image,
} from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt, faBirthdayCake,
} from '@fortawesome/free-solid-svg-icons'; // Add other icons as needed
import { PreviewResumeProps } from '../resume.template.type';
import { formatUserDateNoHr } from '../../../../../../utils/constants';
// Import FontAwesomeIcon component

Font.register({
  family: 'Microsoft Sans Serif',
  src: '/templates-resume/Microsoft Sans Serif.ttf', // Note the leading slash
});
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#ffffff',
    fontFamily: 'Microsoft Sans Serif',
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 15,
    fontWeight: 600,
    color: '#515451',
    fontFamily: 'Microsoft Sans Serif',
  },
  subHeader: {
    fontSize: 14,
    fontWeight: 800,
    color: '#193158',
    fontFamily: 'Microsoft Sans Serif',
  },
  skillCategory: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 10,
  },
  emailText: {
    fontSize: 13,
    fontWeight: 400,
    color: '#868484',
    paddingTop: '10px',
    fontFamily: 'Microsoft Sans Serif',
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#575656',
    paddingTop: '10px',
    fontFamily: 'Microsoft Sans Serif',
  },
  textDate: {
    fontSize: 12,
    fontWeight: 600,
    color: '#7c7070',
    paddingTop: 5,
    fontFamily: 'Microsoft Sans Serif',
  },
  icon: {
    marginRight: 5,
    fontSize: 12,
  },
  marginbottom: {
    marginBottom: 20,
  },
  marginbottomMed: {
    marginBottom: 5,
  },
  marginLeftAlign: {
    marginLeft: 10,
  },
  imageicon: {
    width: 11,
    height: 11,
  },
});

const ResumePDF: React.FC<PreviewResumeProps> = ({ previewResume, myProfile }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>
          {myProfile?.fNme ? myProfile.fNme : ''} {myProfile?.lNme ? myProfile.lNme : ''}
        </Text>
        <Text style={styles.marginbottomMed} />
        {myProfile?.cEId && (
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
            <Text style={styles.emailText}><Image
              style={styles.imageicon}
              src="/templates-resume/common-images/mail.png"
            /> {' '}{myProfile.cEId}
            </Text>
          </View>
        )}
        {myProfile?.dlCd && myProfile?.cPh && (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.emailText}>
              <Image
                style={styles.imageicon}
                src="/templates-resume/common-images/phone.png"
              /> {' '}{myProfile.dlCd} {myProfile.cPh}
            </Text>
          </View>
        )}
      </View>

      {/* Career Objective */}
      {previewResume?.obj && (
        <View style={styles.section}>
          <Text style={styles.marginbottom} />
          <Text style={styles.subHeader}>Career Objective</Text>
          <Text style={styles.text}>{previewResume.obj}</Text>
        </View>
      )}

      {/* Skills */}
      {previewResume?.resSkills && previewResume.resSkills.length > 0 && (
      <View style={styles.section}>
        <Text style={styles.marginbottomMed} />
        <Text style={styles.subHeader}><Image
          style={styles.imageicon}
          src="/templates-resume/common-images/blck_settings.png"
        /> {' '}Skill
        </Text>
        {previewResume.resSkills.map((skillGroup, index) => {
          const skillsList = skillGroup.skillData.join(', ');
          return (
            <Text style={styles.emailText} key={`group-${index.toString()}`}>
              <Text style={styles.marginbottomMed}>{skillGroup?.sklName}</Text> - <Text style={styles.text}>{skillsList}</Text>
            </Text>
          );
        })}
      </View>
      )}

      {/* Professional Experience */}
      {previewResume?.orgData && previewResume.orgData.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.marginbottom} />
          <Text style={styles.subHeader}>Professional Experience</Text>
          {previewResume.orgData.map((org, orgIndex) => (
            <View style={styles.section} key={`org-${orgIndex.toString()}`}>
              <Text style={styles.textDate}>
                {' '}<Image
                  style={styles.imageicon}
                  src="/templates-resume/common-images/suitcase.png"
                /> {' '}
                {formatUserDateNoHr(org.sDte)} -{' '}
                {org.isCrnt ? 'Currently Working' : org.eDte ? formatUserDateNoHr(org.eDte) : 'NA'}
              </Text>
              <Text style={styles.text}>
                {' '}{org?.dsg || ''}, {org?.org || ''} ({org?.orgLoc || ''})
              </Text>

              {org.prjs && org.prjs.map((project, projIndex) => (
                <View style={styles.section} key={`project-${projIndex.toString()}`}>
                  <Text style={styles.text}>
                    {' '}Project: {project?.prjNme || ''}{' '}
                    {project?.isCrnt ? '- Currently working' : ''}
                  </Text>
                  <Text style={styles.text}>{' '}{project?.desc || ''}</Text>
                  <Text style={styles.marginbottomMed} />
                  <Text style={styles.textDate} key="proj-skills">
                    {' '}<Text style={styles.text}>Skills Achieved:</Text> {project.skls.filter(Boolean).join(', ')}
                  </Text>
                  <Text style={styles.marginbottomMed} />
                  <Text style={styles.text}>{' '}Roles & Responsibilities:</Text>
                  {project.myRls && project.myRls.map((role, roleIndex) => (
                    <Text style={styles.text} key={`proj-role-${roleIndex.toString()}`}>
                      {' '}- {role || ''}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          ))}
        </View>
      )}

      {previewResume?.langData && previewResume.langData.length > 0 && (
      <View style={styles.section}>
        <Text style={styles.marginbottom} />
        <Text style={styles.subHeader}>Language Proficiency</Text>
        {previewResume.langData.map((lang, langIndex) => (
          <View style={styles.section} key={`lang-${langIndex.toString()}`}>
            <Text style={styles.text}>
              {' '}<Image
                style={styles.imageicon}
                src="/templates-resume/common-images/world.png"
              /> {' '}
              {`${lang?.langNme || ''} - ${lang?.level || ''}`}
            </Text>
          </View>
        ))}
      </View>
      )}

      {previewResume?.certData && previewResume.certData.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.marginbottom} />
          <Text style={styles.subHeader}>Certifications & Achievements</Text>
          {previewResume.certData.map((cert, certIndex) => (
            <View style={styles.section} key={`cert-${certIndex.toString()}`}>
              <Text style={styles.text}>{' '}<Image
                style={styles.imageicon}
                src="/templates-resume/common-images/verified.png"
              /> {' '}{cert?.certNme || ''}
              </Text>
              {cert?.sDte && cert?.eDte && (
                <Text style={styles.text}>
                  {' '}{formatUserDateNoHr(cert.sDte)} - {formatUserDateNoHr(cert.eDte)}
                </Text>
              )}
              <Text style={styles.text}>{cert?.desc || ''}</Text>
            </View>
          ))}
        </View>
      )}

      {/* Education */}
      {previewResume?.eduData && previewResume.eduData.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.marginbottom} />
          <Text style={styles.subHeader}>Education</Text>
          {previewResume.eduData.map((edu, eduIndex) => (
            <View style={styles.section} key={`edu-${eduIndex.toString()}`}>
              <Text style={styles.text}>{' '}{' '}<Image
                style={styles.imageicon}
                src="/templates-resume/common-images/grads.png"
              /> {' '}{edu?.cmpsNme || ''}
              </Text>
              <Text style={styles.emailText}>{' '}{edu?.spec || ''}</Text>
              <Text style={styles.textDate}>
                {' '}{formatUserDateNoHr(edu?.sDte)} - {formatUserDateNoHr(edu?.eDte)}
              </Text>
            </View>
          ))}
        </View>
      )}

      {/* Personal Information */}
      {previewResume?.dob && previewResume?.addr && (
        <View style={styles.section}>
          <Text style={styles.marginbottom} />
          <Text style={styles.subHeader}>Personal Information</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faBirthdayCake} style={styles.icon} />
            <Text style={styles.textDate}>DOB: {formatUserDateNoHr(previewResume.dob)}</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={styles.icon} />
            <Text style={styles.textDate}>
              Address: {previewResume.addr}, {previewResume?.city || ''},{' '}
              {previewResume?.st || ''} - {previewResume?.pCd || ''}
            </Text>
          </View>
        </View>
      )}
    </Page>
  </Document>
);

export default ResumePDF;
