import React from 'react';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import './jobs.scss';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import JobsAccordion from '../../../utils/Accordion/Jobs-Accordion';
import { CXJobsNavbar } from '../navbar/cx-jobs.navbar';
import { JobData } from '../../../services/staffing/job-board/list-jobs/list-jobs.types';
import {
  clearEditJobViewReq,
  clearJobByDetailsReq, clearListPublicJobReq, editJobViewReq, getLoginToken, jobByDetailsReq, listPublicJobReq,
} from '../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../utils/constants';
import AllPublicJobs from './job-list';
import { JobsResult } from '../../../services/staffing/job-board/get-job-details/job-details.types';
import JobPublicDetails from './job-details';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { JobViewInput } from '../../../services/staffing/job-board/update-job-views/update.job.views.types';

export const IntroJobsCampusXcel: React.FC = () => {
  const dispatch = useDispatch();
  const isMobileJob = window.innerWidth <= 468;
  const [loadingJob, setLoadingJob] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [tokenValue, setTokenValue] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const getJobDetailsResponse = useSelector((state: RootState) => state.campusXcel.getJobByDetails);
  const updateViewResponse = useSelector((state: RootState) => state.campusXcel.updateJobView);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const publicListJobsResponse = useSelector((state: RootState) => state.campusXcel.listPublicJobs);
  const [jobDetailsOpen, setJobDetailsOpen] = React.useState<Record<string, boolean>>({});
  const [jobToggleIndex, setJobToggleIndex] = React.useState('');
  const [clickedIndex, setClickedIndex] = React.useState('');
  const [, setAllListedJobs] = React.useState<JobData>({
    tCnt: 0,
    fCnt: 0,
    jobsData: [],
    lastEvalKey: {
      JobCd: '',
      JobId: '',
    },
  });
  const [, setUpdateJobsView] = React.useState<JobViewInput>({
    JobCd: '',
    JobId: '',
  });
  const [jobDetails, setJobDetails] = React.useState<JobsResult>({
    jobSts: '',
    pStg: '',
    JobCd: '',
    JobId: '',
    forClnt: '',
    nme: '',
    cNme: '',
    pCd: '',
    wrkPref: '',
    city: '',
    st: '',
    logoUrl: '',
    jobTp: [],
    schdl: '',
    oSchdl: '',
    isPlndDt: false,
    plndDt: '',
    vacncy: 0,
    timelne: '',
    payBy: '',
    amnt: '',
    amntTp: '',
    suppPay: [],
    oSuppPay: [],
    perk: [],
    oPerk: [],
    expR1: 0,
    expR2: 0,
    jdFile: '',
    jdFileUrl: '',
    jDCntPh: '',
    dailUpdEml: '',
    potCndEml: '',
    jobKey: [],
    isCV: false,
    updtOn: '',
    isFemale: '',
    isAbled: '',
    desg: '',
    skills: [],
    oSkills: [],
  });

  const toggleDescJobDetails = (target: string) => {
    setJobDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleUpdateJobsViewCount = (JobCode: string, JobId: string) => {
    setUpdateJobsView((prevState) => {
      const updatedViewData = { ...prevState, JobCd: JobCode, JobId };
      dispatch(editJobViewReq({
        inputBody: updatedViewData,
        requestType: APIHeader.REQ_UPDATE_JOBS_VIEWS,
        uTp: UserType.CXAPI,
        token: tokenValue,
      }));
      return updatedViewData;
    });
  };

  const handleGetJobDetails = (jobCode: string, jobIdent: string) => {
    setLoadingJob(true);
    setClickedIndex(jobIdent);
    dispatch(clearJobByDetailsReq());
    dispatch(jobByDetailsReq({
      requestType: APIHeader.REQ_GET_JOB_DETAILS_BY_ID,
      uTp: UserType.CXAPI,
      token: tokenValue,
      jobCd: jobCode,
      jobId: jobIdent,
    }));
    setJobToggleIndex(jobIdent);
    setJobDetailsOpen((prevState) => ({
      ...prevState,
      [jobIdent]: !prevState[jobIdent],
    }));
    handleUpdateJobsViewCount(jobCode, jobIdent);
  };

  React.useEffect(() => {
    if (updateViewResponse.error && updateViewResponse.message !== '') {
      dispatch(clearEditJobViewReq());
    }
  }, [updateViewResponse.error, updateViewResponse.message]);

  React.useEffect(() => {
    if (!updateViewResponse.error && updateViewResponse.message === 'executed') {
      dispatch(clearEditJobViewReq());
    }
  }, [updateViewResponse.error, updateViewResponse.message]);

  React.useEffect(() => {
    if (getJobDetailsResponse.isError && getJobDetailsResponse.message !== '') {
      setLoadingJob(false);
    }
  }, [getJobDetailsResponse.isError, getJobDetailsResponse.message]);

  React.useEffect(() => {
    if (!getJobDetailsResponse.isError && getJobDetailsResponse.message === 'executed') {
      setLoadingJob(false);
      setJobDetails(getJobDetailsResponse.data);
    }
  }, [getJobDetailsResponse.isError, getJobDetailsResponse.message]);

  React.useEffect(() => {
    setLoadingJob(true);
    dispatch(getLoginToken({
      userName: '',
      passKey: '',
      isPublic: true,
      uTp: UserType.CXAPI,
      requestType: APIHeader.USER_LOGIN,
    }));
  }, []);

  React.useEffect(() => {
    if (loginTokenData.message !== '') {
      if (loginTokenData.isError) {
        setLoadingJob(false);
      } else if (loginTokenData.token !== '') {
        setTokenValue(loginTokenData.token);
        setLoadingJob(true);
        dispatch(listPublicJobReq({
          requestType: APIHeader.REQ_ALL_JOBS_PUBLIC,
          uTp: UserType.CXAPI,
          limit: recordsPerPage,
          evalKey: '',
          evalTp: '',
          isFilter: 'NO',
          isSearch: 'NO',
          token: loginTokenData.token,
        }));
      }
    }
  }, [loginTokenData]);

  const fetchMoreData = () => {
    setLoadingJob(true);
    const searchFlag = isSearchQuery !== '' ? 'YES' : 'NO';
    const searchParam = isSearchQuery !== '' ? searchText : '';
    dispatch(listPublicJobReq({
      requestType: APIHeader.REQ_ALL_JOBS_PUBLIC,
      uTp: UserType.CXAPI,
      limit: recordsPerPage,
      evalKey: evalKeyText,
      evalTp: evalTpText,
      isFilter: 'NO',
      isSearch: searchFlag,
      searchBy: searchParam,
      token: tokenValue,
    }));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchJobs = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingJob(true);
    dispatch(listPublicJobReq({
      requestType: APIHeader.REQ_ALL_JOBS_PUBLIC,
      uTp: UserType.CXAPI,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenValue,
    }));
    setIsSearchQuery(searchText);
  };

  React.useEffect(() => {
    if (publicListJobsResponse.isError && publicListJobsResponse.message !== '') {
      setLoadingJob(false);
      setAlertMsg(publicListJobsResponse.message);
      setErrorShowAlert(true);
      dispatch(clearListPublicJobReq());
    }
  }, [publicListJobsResponse.isError, publicListJobsResponse.message]);

  React.useEffect(() => {
    if (!publicListJobsResponse.isError && publicListJobsResponse.message === 'executed') {
      setLoadingJob(false);
      if (searchText !== '') {
        setPagedData({ [currentPage]: publicListJobsResponse.data.jobsData });
      } else if (
        publicListJobsResponse.data
        && Object.keys(publicListJobsResponse.data).length > 0
        && Array.isArray(publicListJobsResponse.data.jobsData)
      ) {
        const newPageData = publicListJobsResponse.data.jobsData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllListedJobs({
          tCnt: publicListJobsResponse.data.tCnt,
          fCnt: publicListJobsResponse.data.fCnt,
          jobsData: publicListJobsResponse.data.jobsData,
          lastEvalKey: publicListJobsResponse.data.lastEvalKey,
        });
        if (publicListJobsResponse.data.lastEvalKey !== null) {
          setEvalKeyText(publicListJobsResponse.data.lastEvalKey.JobId);
          setEvalTpText(publicListJobsResponse.data.lastEvalKey.JobCd);
        }
        setTotalRecords(publicListJobsResponse.data.tCnt);
      }
    }
    dispatch(clearListPublicJobReq());
  }, [publicListJobsResponse.isError, publicListJobsResponse.message]);

  const selectStyles = {
    singleValue: (base: any, state: any) => ({
      ...base,
      color: state.isDisabled ? '#383838' : base.color,
    }),
    menu: (base: any) => ({
      ...base,
      position: 'absolute',
    }),
    control: (base: any) => {
      // Determine if the viewport is mobile
      const isMobile = window.innerWidth <= 468; // Adjust based on your breakpoint
      return {
        ...base,
        '&:hover': { borderColor: '#fabdbd' },
        border: isMobile ? '1px solid #E1EDF8' : 'none',
        borderBottom: '1px solid #E1EDF8',
        backgroundColor: '#fff',
        minHeight: isMobile ? '55px' : '40px',
        marginRight: '10px',
        flex: 1,
        width: isMobile ? '300px' : '',
        borderRadius: isMobile ? '5px' : '1px',
        color: '#575656',
        fontSize: isMobile ? '14px' : '15px', // Change font size for mobile
        boxShadow: 'none',
        '&:focus': {
          borderColor: '#E1EDF8',
          boxShadow: 'none',
          color: '#575656',
          background: '#f9f9f9',
        },
      };
    },
    option: (provided: any, state: { isSelected: any; }) => ({
      ...provided,
      color: state.isSelected ? '#383838' : '#212121',
      padding: 15,
      textAlign: 'left',
      background: state.isSelected ? '#FAFCFB' : '#fff',
      '&:hover': {
        background: '#FAFCFB',
      },
    }),
  };

  return (
    <div className="bg-jobs-public">
      <CXJobsNavbar />
      <Row className="margin-search-jobs">
        <Col xs="12" lg="12">
          <JobsAccordion
            title="Filter your search"
            defaultOpen={!isMobileJob}
            targetId="myFilter"
          >
            <div className="search-container align-center">
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="Filter by Organization"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Location"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Skills"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Job Type"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="By Work Prefrence"
                  isDisabled
                />
              </div>
              <div className="margin-select-jobs">
                <Select
                  styles={selectStyles}
                  noOptionsMessage={() => 'No Client Found. Add New'}
                  placeholder="Miscellaneous"
                  isDisabled
                />
              </div>
              <Button className="button-search-jobs"><FontAwesomeIcon id="jSearch" icon={faMagnifyingGlass} className="mx-2" /></Button>
            </div>
          </JobsAccordion>
        </Col>
        <Col xs="12" lg="12">
          <Row className="align-start pt-5">
            {showErrorsAlert
              ? (
                <div className="loader-container-none align-center">
                  <span className="mx-2 wrong-pass-key">{alertMsg}</span>
                  <br />
                  <span className="mx-2 wrong-pass-key">Kindly refresh your page</span>
                </div>
              )
              : loadingJob ? (
                <div className="loader-campusxcel align-center">
                  <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
                  <span className="mx-2 text-lite">Jobs loading... <br />Please wait....</span>
                </div>
              )
                : (
                  <Col lg="6" xs="12">
                    <div className="order-right-pub-jobs">
                      <Row className="align-start">
                        <Col xs="12" lg="12">
                          <Row className="padding-zero">
                            <Col lg="9" xs="12">
                              <div className="margin-list-search-job-mob1 mx-2">
                                <div className="input-wrapper margin-left-search-jobs-pub">
                                  <Input
                                    type="text"
                                    placeholder="Search by Job Name / Location / Job Title"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                  />
                                  <Button type="button" onClick={handleSearchJobs}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                            {!isMobileJob
                              ? (
                                <Col xs="12" lg="3">
                                  <div className="align-end mr-2 pt-2">
                                    <Button
                                      onClick={() => handlePageChange(currentPage - 1)}
                                      disabled={currentPage === 0}
                                      className="pub-job-button-nxt "
                                    >
                                      <FontAwesomeIcon icon={faChevronLeft} />
                                    </Button>
                                    <div className="mx-2 table-margin-bot">
                                      <span className="table-text">
                                        {currentPage + 1} / {totalPages}
                                      </span>
                                    </div>
                                    <Button
                                      onClick={() => handlePageChange(currentPage + 1)}
                                      disabled={currentPage >= totalPages - 1}
                                      className="pub-job-button-nxt"
                                    >
                                      <FontAwesomeIcon icon={faChevronRight} />
                                    </Button>
                                  </div>
                                </Col>
                              ) : null}
                          </Row>
                        </Col>
                        <Col xs="12" lg="12" className="pub-jobs-dmin-height">
                          <div className="pt-3">
                            {pagedData[currentPage]
                              ? (
                                <AllPublicJobs
                                  isMobileJob={isMobileJob}
                                  clickedIndex={clickedIndex}
                                  data={pagedData[currentPage]}
                                  handleGetJobDetails={handleGetJobDetails}
                                  handleUpdateJobsViewCount={handleUpdateJobsViewCount}
                                />
                              ) : null}
                          </div>
                          {isMobileJob
                            ? (
                              <div className="align-end mr pt-2">
                                <Button
                                  onClick={() => handlePageChange(currentPage - 1)}
                                  disabled={currentPage === 0}
                                  className="pub-job-button-nxt "
                                >
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </Button>
                                <div className="mx-2 table-margin-bot">
                                  <span className="table-text">
                                    {currentPage + 1} / {totalPages}
                                  </span>
                                </div>
                                <Button
                                  onClick={() => handlePageChange(currentPage + 1)}
                                  disabled={currentPage >= totalPages - 1}
                                  className="pub-job-button-nxt"
                                >
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                              </div>
                            ) : null}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}
            <Col lg="6" xs="12" className="padding-zero">
              <div className="pt-5">
                <div>
                  {isMobileJob
                    ? (
                      <Offcanvas
                        isOpen={jobDetailsOpen[jobToggleIndex]}
                        toggle={() => toggleDescJobDetails(jobToggleIndex)}
                      >
                        <span className="text-lite">Job Details</span>
                        <JobPublicDetails jobDetails={jobDetails} />
                      </Offcanvas>
                    )
                    : <div className="pub-jobs-dmin-height pt-2 mr-1"><JobPublicDetails jobDetails={jobDetails} /></div>}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default IntroJobsCampusXcel;
