import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate1 from './download-template';
import './template1.scss';
import { PreviewResumeProps } from '../resume.template.type';

export const DownloadTemplate1: React.FC<PreviewResumeProps> = ({ previewResume, myProfile }) => (
  <PDFDownloadLink document={<PDFTemplate1 previewResume={previewResume} myProfile={myProfile} />} fileName="resume.pdf" className="template-link">
    <div className="template-btn">
      <FontAwesomeIcon icon={faFileDownload} />
      <span className="ml">Download</span>
    </div>
  </PDFDownloadLink>
);

export default DownloadTemplate1;
