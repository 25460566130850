import React from 'react';
import {
  Button,
  CardImg, Col, NavLink, Row,
} from 'reactstrap';
import '../xcelian.scss';
import moment from 'moment';
// import { MultiValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XcelianResumeInput from './resume-input/resume.input';
// import { PreviewTemplate1 } from './template/template1/template1'; // Add more templates as necessary
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../utils/service/localstorage-service';
import Accordion from '../../../../utils/Accordion/Accordion';
import Offcanvas from '../../../../utils/OffCanvas/OffcanvasComponent';
import {
  CertInput,
  EducationInput,
  LangInput,
  OrgProjInput, ResumeCoverLetterInput, ResumeObjectiveInput, ResumePersonalInfo, ResumeSkillsInput,
  ResumeVideoInput,
} from '../../../../services/staffing/xcelian/update-resume/update.resume.types';
import {
  clearDelPostPublishReq,
  clearEditResumeInfoReq, clearUploadS3FilesReq, clearXcelianPreviewResume, clearXcelianShareResume, delPostPublish, editResumeInfoReq, getXcelianPreviewResume,
  getXcelianShareResume,
  uploadS3FilesReq,
} from '../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../utils/constants';
import { errorAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { XcelianResumePreviewData } from '../../../../services/staffing/xcelian/get-xcelian-resume/xcelian.preview.types';
import { getConfig } from '../../../../utils/config/config';
import PreviewTemplate1 from './template/template1/template1';
import EResumePreviewTemplate from './e.prev.resume';

const { AWS_CXUSER_BUCKET } = getConfig();

export const XcelianResumeComponent: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const isMobileJob = window.innerWidth <= 468;
  const userProfile = getUserProfileLocalStorage();
  const [loadingResume, setLoadingResume] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [isClickClose, setIsClickClose] = React.useState(false);
  const [isEResPrev, setIsEResPrev] = React.useState(false);
  const [loaderVideoResume, setLoaderVideoResume] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('template1');
  const [storeDelFile, setStoreDelFile] = React.useState('');
  const [inputPasscodePerv, setInputPasscodePerv] = React.useState('');
  const [eResumePass, setEResumePass] = React.useState(false);
  const [isPasscodePerv, setIsPasscodePerv] = React.useState(false);
  const [passReviewCd, setPassReviewCd] = React.useState('');
  const [clickCXComponent, setClickCXComponent] = React.useState(false);
  const xcelianResumePassPreviewMainResponse = useSelector((state: RootState) => state.campusXcel.xcelianResumeShare);
  const getResumeXcelianPreviewResponse = useSelector((state: RootState) => state.campusXcel.previewXcelianResume);
  const updateResumeResponse = useSelector((state: RootState) => state.campusXcel.updateResumeInfo);
  const delResumeCertFileResponse = useSelector((state: RootState) => state.campusXcel.deletePostDataS3);
  // const uploadCertS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const [myProfile, setMyProfile] = React.useState({
    UserCd: '',
    UserTp: '',
    cEId: '',
    fNme: '',
    lNme: '',
    dlCd: '+91',
    cPh: '',
  });
  const [previewResume, setPreviewResume] = React.useState<XcelianResumePreviewData>({
    dob: '',
    gnd: '',
    oGnd: '',
    pCd: '',
    city: '',
    st: '',
    addr: '',
    resCnt: 0,
    resSkills: [],
    obj: '',
    covL: '',
    vidUrl: '',
    orgData: [],
    eduData: [],
    certData: [],
    langData: [],
  });

  const [resumePersonalInfo, setResumePersonalInfo] = React.useState<ResumePersonalInfo>({
    XclnCd: '',
    XclnId: '',
    dob: '',
    gnd: '',
    oGnd: '',
    pCd: '',
    city: '',
    st: '',
    addr: '',
  });
  const [resumeSkills, setResumeSkills] = React.useState<ResumeSkillsInput>({
    XclnCd: '',
    XclnId: '',
    skills: [],
  });
  const [resumeObjective, setResumeObjective] = React.useState<ResumeObjectiveInput>({
    XclnCd: '',
    XclnId: '',
    obj: '',
  });
  const [resumeOrgProj, setResumeOrgProj] = React.useState<OrgProjInput>({
    XclnCd: '',
    XclnId: '',
    orgData: [],
  });
  const [resumeEducation, setResumeEducation] = React.useState<EducationInput>({
    XclnCd: '',
    XclnId: '',
    eduData: [],
  });
  const [resumeCert, setResumeCert] = React.useState<CertInput>({
    XclnCd: '',
    XclnId: '',
    certData: [],
  });
  const [resumeLang, setResumeLang] = React.useState<LangInput>({
    XclnCd: '',
    XclnId: '',
    langData: [],
  });
  const [resumeCoverLetter, setResumeCoverLetter] = React.useState<ResumeCoverLetterInput>({
    XclnCd: '',
    XclnId: '',
    covL: '',
  });

  const [resumeVideo, setResumeVideo] = React.useState<ResumeVideoInput>({
    XclnCd: '',
    XclnId: '',
    vidNme: '',
  });

  const handleResumeInfoData = (event: any) => {
    if (event.target.name === 'dob') {
      const dateData = moment(event.target.value).format('YYYY-MM-DD');
      setResumePersonalInfo({ ...resumePersonalInfo, dob: dateData });
    } else {
      setResumePersonalInfo({ ...resumePersonalInfo, [event.target.name]: event.target.value });
    }
  };

  const handleGenderRegister = (genderData: string) => {
    if (genderData === 'M') {
      setResumePersonalInfo({ ...resumePersonalInfo, gnd: 'M', oGnd: '' });
    } else if (genderData === 'F') {
      setResumePersonalInfo({ ...resumePersonalInfo, gnd: 'F', oGnd: '' });
    } else if (genderData === 'O') {
      setResumePersonalInfo({ ...resumePersonalInfo, gnd: 'O' });
    } else {
      setResumePersonalInfo({ ...resumePersonalInfo, gnd: 'UNK', oGnd: '' });
    }
  };

  const toggleResumeTemplate = () => {
    setIsClickClose(!isClickClose);
  };

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId !== '') {
      const { profileData } = JSON.parse(userProfile);
      setMyProfile({
        UserTp: profileData.UserTp,
        UserCd: profileData.UserCd,
        fNme: profileData.fNme,
        lNme: profileData.lNme,
        cEId: profileData.eId,
        dlCd: profileData.dlCd,
        cPh: profileData.ph,
      });
    }
  }, [userProfile]);

  // const handleUpdateResumeData = (event: any) => {
  //   setPreviewResume({ ...previewResume, [event.target.name]: event.target.value });
  // };

  // Define available templates
  const templates = [
    { id: 'template1', component: PreviewTemplate1, imgSrc: '/images/resumes/template-icons-1/template-1.png' },
    // Add more templates here
  ];

  React.useEffect(() => {
    if (myProfile.UserTp !== '' && myProfile.UserCd) {
      setLoadingResume(true);
      dispatch(getXcelianPreviewResume({
        uTp: UserType.XCLN,
        token: tokenData,
        requestType: APIHeader.REQ_GET_RESUME_DETAILS_BY_ID,
        xclnCd: myProfile.UserTp,
        xclnId: myProfile.UserCd,
      }));
    }
  }, [myProfile.UserTp, myProfile.UserCd]);

  React.useEffect(() => {
    if (getResumeXcelianPreviewResponse.isError && getResumeXcelianPreviewResponse.message !== '') {
      setLoadingResume(false);
      setAlertMsg(getResumeXcelianPreviewResponse.message);
      setErrorShowAlert(true);
      dispatch(clearXcelianPreviewResume());
    }
  }, [getResumeXcelianPreviewResponse.isError, getResumeXcelianPreviewResponse.message]);

  React.useEffect(() => {
    if (!getResumeXcelianPreviewResponse.isError && getResumeXcelianPreviewResponse.message === 'executed') {
      setLoadingResume(false);
      setPreviewResume(getResumeXcelianPreviewResponse.resumeData);
      setResumePersonalInfo({
        ...resumePersonalInfo,
        dob: getResumeXcelianPreviewResponse.resumeData.dob ? getResumeXcelianPreviewResponse.resumeData.dob : '',
        gnd: getResumeXcelianPreviewResponse.resumeData.gnd ? getResumeXcelianPreviewResponse.resumeData.gnd : '',
        oGnd: getResumeXcelianPreviewResponse.resumeData.oGnd ? getResumeXcelianPreviewResponse.resumeData.oGnd : '',
        pCd: getResumeXcelianPreviewResponse.resumeData.pCd ? getResumeXcelianPreviewResponse.resumeData.pCd : '',
        city: getResumeXcelianPreviewResponse.resumeData.city ? getResumeXcelianPreviewResponse.resumeData.city : '',
        st: getResumeXcelianPreviewResponse.resumeData.st ? getResumeXcelianPreviewResponse.resumeData.st : '',
        addr: getResumeXcelianPreviewResponse.resumeData.addr ? getResumeXcelianPreviewResponse.resumeData.addr : '',
      });
      setResumeSkills({
        ...resumeSkills,
        skills: getResumeXcelianPreviewResponse.resumeData.resSkills ? getResumeXcelianPreviewResponse.resumeData.resSkills : [],
      });
      setResumeObjective({
        ...resumeObjective,
        obj: getResumeXcelianPreviewResponse.resumeData.obj ? getResumeXcelianPreviewResponse.resumeData.obj : '',
      });
      setResumeOrgProj({
        ...resumeOrgProj,
        orgData: getResumeXcelianPreviewResponse.resumeData.orgData ? getResumeXcelianPreviewResponse.resumeData.orgData : [],
      });
      setResumeEducation({
        ...resumeEducation,
        eduData: getResumeXcelianPreviewResponse.resumeData.eduData ? getResumeXcelianPreviewResponse.resumeData.eduData : [],
      });
      setResumeCert({
        ...resumeCert,
        certData: getResumeXcelianPreviewResponse.resumeData.certData ? getResumeXcelianPreviewResponse.resumeData.certData : [],
      });
      setResumeLang({
        ...resumeLang,
        langData: getResumeXcelianPreviewResponse.resumeData.langData ? getResumeXcelianPreviewResponse.resumeData.langData : [],
      });
      setResumeCoverLetter({
        ...resumeCoverLetter,
        covL: getResumeXcelianPreviewResponse.resumeData.covL ? getResumeXcelianPreviewResponse.resumeData.covL : '',
      });
      setResumeVideo({
        ...resumeVideo,
        vidUrl: getResumeXcelianPreviewResponse.resumeData.vidUrl ? getResumeXcelianPreviewResponse.resumeData.vidUrl : '',
      });
      dispatch(clearXcelianPreviewResume());
    }
  }, [getResumeXcelianPreviewResponse.isError, getResumeXcelianPreviewResponse.message]);

  // Personal Info Start
  const handleUpdatePersonalInfo = () => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      setLoadingResume(true);
      setResumePersonalInfo((prevState) => {
        const updatedPersonalData = {
          ...prevState,
          XclnCd: myProfile.UserTp,
          XclnId: myProfile.UserCd,
        };
        dispatch(editResumeInfoReq({
          inputBody: updatedPersonalData,
          requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
          uTp: UserType.XCLN,
          token: tokenData,
          resumeTp: 'PERINFO',
        }));
        return updatedPersonalData;
      });
    }
  };

  // Personal Info End

  // Skill Data Start

  // const handleSelectSkillData = (selectedSkills: any) => {
  //   const newSkills: ResumeSkillsDetails[] = resumeSkills.skills.map((skillDetail) => ({
  //     ...skillDetail,
  //     skills: selectedSkills.filter((skill: { value: string | string[]; }) => skill.value.includes(skillDetail.sklName)).map((skill: { value: any; }) => skill.value),
  //   }));

  //   handleSelectSkillData({ ...resumeSkills, skills: newSkills });
  // };
  // const handleSelectSkillData = (e: MultiValue<{ label: string; value: string; }> | null) => {
  //   let updatedSkill: string[] = [];
  //   if (e && e.length) {
  //     updatedSkill = e.map(({ label }) => label);
  //   }
  //   setResumeSkills({ ...resumeSkills, skills: updatedSkill });
  // };

  const handleUpdateSkills = () => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      setLoadingResume(true);
      setResumeSkills((prevState) => {
        const updatedSkills = {
          ...prevState,
          XclnCd: myProfile.UserTp,
          XclnId: myProfile.UserCd,
        };
        dispatch(editResumeInfoReq({
          inputBody: updatedSkills,
          requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
          uTp: UserType.XCLN,
          token: tokenData,
          resumeTp: 'SKILLS',
        }));
        return updatedSkills;
      });
    }
  };

  // Skill Data End

  // Objective Data Start

  const handleResumeObjectiveData = (event: any) => {
    setResumeObjective({ ...resumeObjective, [event.target.name]: event.target.value });
  };

  const handleUpdateObjective = () => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '' && resumeObjective.obj !== '') {
      setLoadingResume(true);
      setResumeObjective((prevState) => {
        const updatedObjective = {
          ...prevState,
          XclnCd: myProfile.UserTp,
          XclnId: myProfile.UserCd,
        };
        dispatch(editResumeInfoReq({
          inputBody: updatedObjective,
          requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
          uTp: UserType.XCLN,
          token: tokenData,
          resumeTp: 'OBJ',
        }));
        return updatedObjective;
      });
    }
  };

  // Objective Data End

  // OrgProject Data Start
  const validateExperience = () => {
    const orgValidation = resumeOrgProj.orgData.map((org) => {
      const orgHasEmptyFields = !org.org || !org.orgLoc || !org.sDte || !org.dsg || (!org.isCrnt && !org.eDte);
      if (orgHasEmptyFields) {
        setAlertMsg(`Some fields are empty in organization: ${org.org || 'Unknown Organization'}`);
        setErrorShowAlert(true);
        return true;
      }
      const projectValidation = org.prjs.some((proj) => {
        if (!proj.prjNme || !proj.desc) {
          setAlertMsg(`Some fields are empty in project: ${proj.prjNme || 'Unknown Project'} of organization ${org.org}`);
          setErrorShowAlert(true);
          return true;
        }
        return false;
      });
      if (projectValidation) return true;
      return false;
    });
    return !orgValidation.some((hasError) => hasError === true);
  };

  const handleUpdateOrgProject = () => {
    if (validateExperience()) {
      if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
        setLoadingResume(true);
        setResumeOrgProj((prevState) => {
          const updatedOrgProj = {
            ...prevState,
            XclnCd: myProfile.UserTp,
            XclnId: myProfile.UserCd,
          };
          dispatch(editResumeInfoReq({
            inputBody: updatedOrgProj,
            requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
            uTp: UserType.XCLN,
            token: tokenData,
            resumeTp: 'ORGPROJ',
          }));
          return updatedOrgProj;
        });
      }
    }
  };

  // OrgProject Data End

  // Education Data Start
  const validateEducation = () => {
    const eduValidation = resumeEducation.eduData.map((edu) => {
      const orgHasEmptyFields = !edu.cmpsNme || !edu.cmpsLoc || !edu.sDte || !edu.spec || !edu.eDte;
      if (orgHasEmptyFields) {
        setAlertMsg(`Some fields are empty in Education Details: ${edu.cmpsNme || 'Unknown Campus'}`);
        setErrorShowAlert(true);
        return true;
      }
      return false;
    });
    return !eduValidation.some((hasError) => hasError === true);
  };

  const handleUpdateEduction = () => {
    if (validateEducation()) {
      if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
        setLoadingResume(true);
        setResumeEducation((prevState) => {
          const updatedEducation = {
            ...prevState,
            XclnCd: myProfile.UserTp,
            XclnId: myProfile.UserCd,
          };
          dispatch(editResumeInfoReq({
            inputBody: updatedEducation,
            requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
            uTp: UserType.XCLN,
            token: tokenData,
            resumeTp: 'EDU',
          }));
          return updatedEducation;
        });
      }
    }
  };

  // Education Data End

  // Certifications Data Start
  const validateCert = () => {
    const eduValidation = resumeCert.certData.map((cert) => {
      const orgHasEmptyFields = !cert.certNme || !cert.desc || !cert.sDte || !cert.eDte;
      if (orgHasEmptyFields) {
        setAlertMsg(`Some fields are empty in Certification Details: ${cert.certNme || 'Unknown Certification'}`);
        setErrorShowAlert(true);
        return true;
      }
      return false;
    });
    return !eduValidation.some((hasError) => hasError === true);
  };

  const handleUpdateCertifications = async () => {
    if (validateCert()) {
      if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
        setLoadingResume(true);

        const uploadPromises = resumeCert.certData.map(async (cert) => {
          if (!cert.file || !cert.fileNme) {
            return Promise.resolve(); // Skip this upload, resolve immediately
          }

          const formData = new FormData();
          formData.append(cert.fileNme, cert.file); // Append the file with its name

          // Dispatch the upload action
          const response = dispatch(uploadS3FilesReq({
            bucketNme: AWS_CXUSER_BUCKET,
            folderName: `xcelian-cert/${myProfile.UserTp}/${myProfile.UserCd}`,
            fileNmeInit: cert.fileNme,
            formData,
            requestType: '',
            isEdit: false,
          }));

          return response;
        });

        try {
          await Promise.all(uploadPromises);
          const updatedCertData = resumeCert.certData.map((cert) => {
            delete cert.file;
            delete cert.certUrl;
            return { ...cert };
          });

          const updatedCert = {
            ...resumeCert,
            XclnCd: myProfile.UserTp,
            XclnId: myProfile.UserCd,
            certData: updatedCertData, // Updated certData without 'file'
          };

          // Call your edit resume info API
          dispatch(editResumeInfoReq({
            inputBody: updatedCert,
            requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
            uTp: UserType.XCLN,
            token: tokenData,
            resumeTp: 'CERT',
          }));
        } catch (error) {
          setAlertMsg('Error uploading files. Please try again.');
          setErrorShowAlert(true);
        }
      }
    }
  };
  const removeCertFromState = (fileNme: string) => {
    setResumeCert((prevState) => ({
      ...prevState,
      certData: prevState.certData.map((cert) => {
        if (cert.fileNme === fileNme) {
          return {
            ...cert,
            certUrl: undefined,
            fileNme: '',
            file: null,
          };
        }
        return cert;
      }),
    }));
  };
  const handleDeleteCertImage = async (fileNme: string) => {
    dispatch(delPostPublish({
      bucketNme: AWS_CXUSER_BUCKET,
      fileNme: `xcelian-cert/${myProfile.UserTp}/${myProfile.UserCd}/${fileNme}`,
      requestType: '',
    }));

    setStoreDelFile(fileNme);
  };

  React.useEffect(() => {
    if (delResumeCertFileResponse.data === 'success') {
      removeCertFromState(storeDelFile);
      dispatch(clearDelPostPublishReq());
    }
  }, [delResumeCertFileResponse.data]);

  // Certifications Data End

  // Language Data Start
  const validateLang = () => {
    const langValidation = resumeLang.langData.map((cert) => {
      const orgHasEmptyFields = !cert.langNme || !cert.level;
      if (orgHasEmptyFields) {
        setAlertMsg(`Some fields are empty in Language Details: ${cert.langNme || 'Unknown Language'}`);
        setErrorShowAlert(true);
        return true;
      }
      return false;
    });
    return !langValidation.some((hasError) => hasError === true);
  };

  const handleUpdateLanguage = async () => {
    if (validateLang()) {
      if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
        setLoadingResume(true);
        setResumeLang((prevState) => {
          const updatedLang = {
            ...prevState,
            XclnCd: myProfile.UserTp,
            XclnId: myProfile.UserCd,
          };
          dispatch(editResumeInfoReq({
            inputBody: updatedLang,
            requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
            uTp: UserType.XCLN,
            token: tokenData,
            resumeTp: 'LANG',
          }));
          setClickCXComponent(false);
          return updatedLang;
        });
      }
    }
  };

  // Language Data End

  // Cover Letter Data Start

  const handleResumeCoverLetterData = (event: any) => {
    setResumeCoverLetter({ ...resumeCoverLetter, [event.target.name]: event.target.value });
  };

  const handleUpdateCoverLetter = () => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '' && resumeCoverLetter.covL !== '') {
      setLoadingResume(true);
      setResumeCoverLetter((prevState) => {
        const updatedCoverLetter = {
          ...prevState,
          XclnCd: myProfile.UserTp,
          XclnId: myProfile.UserCd,
        };
        dispatch(editResumeInfoReq({
          inputBody: updatedCoverLetter,
          requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
          uTp: UserType.XCLN,
          token: tokenData,
          resumeTp: 'COVL',
        }));
        return updatedCoverLetter;
      });
    }
  };

  // Cover Letter Data End

  // Video Filename Start
  const handleUpdateResumeVideo = (fileName: string) => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      setLoaderVideoResume(false);
      setLoadingResume(true);
      setResumeVideo((prevState) => {
        const updatedVideo = {
          ...prevState,
          XclnCd: myProfile.UserTp,
          XclnId: myProfile.UserCd,
          vidNme: fileName,
        };

        // Delete vidUrl before dispatching
        delete updatedVideo.vidUrl;

        dispatch(editResumeInfoReq({
          inputBody: updatedVideo,
          requestType: APIHeader.REQ_UPDATE_RESUME_INFO,
          uTp: UserType.XCLN,
          token: tokenData,
          resumeTp: 'VIDEO',
        }));

        dispatch(clearUploadS3FilesReq());
        return updatedVideo;
      });
    }
  };

  // Video Filename  End

  React.useEffect(() => {
    if (updateResumeResponse.error && updateResumeResponse.message !== '') {
      setLoadingResume(false);
      setAlertMsg(updateResumeResponse.message);
      setErrorShowAlert(true);
      dispatch(clearEditResumeInfoReq());
    }
  }, [updateResumeResponse.error, updateResumeResponse.message]);

  React.useEffect(() => {
    if (!updateResumeResponse.error && updateResumeResponse.message === 'executed') {
      dispatch(clearXcelianPreviewResume());
      setLoadingResume(false);
      dispatch(getXcelianPreviewResume({
        uTp: UserType.XCLN,
        token: tokenData,
        requestType: APIHeader.REQ_GET_RESUME_DETAILS_BY_ID,
        xclnCd: myProfile.UserTp,
        xclnId: myProfile.UserCd,
      }));
      dispatch(clearEditResumeInfoReq());
    }
  }, [updateResumeResponse.error, updateResumeResponse.message]);

  // handleGetXcelianPreview Get Preview Start
  // const handleEResumePass = () => {
  //   setEResumePass(!eResumePass);
  // };

  const handleChangeXcelianPreview = (e: any) => {
    setInputPasscodePerv(e.target.value);
  };

  const handleCancelXcelianPreview = () => {
    setIsEResPrev(false);
    setInputPasscodePerv('');
    setPassReviewCd('');
    setIsPasscodePerv(false);
  };

  const handleGetXcelianPreview = () => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      setLoadingResume(true);
      setPassReviewCd('MAIN_PREV');
      dispatch(getXcelianShareResume({
        requestType: APIHeader.REQ_INTERVIEW_XCELIAN_SHARE_URL,
        uTp: UserType.XCLN,
        token: tokenData,
        xclnTp: myProfile.UserTp,
        xclnId: myProfile.UserCd,
        resCd: inputPasscodePerv,
        opsMode: 'PRVIF',
      }));
    }
  };

  React.useEffect(() => {
    if (xcelianResumePassPreviewMainResponse.isError && xcelianResumePassPreviewMainResponse.message !== '' && passReviewCd === 'MAIN_PREV') {
      setLoadingResume(false);
      dispatch(clearXcelianShareResume());
      setErrorShowAlert(true);
      setPassReviewCd('');
      setAlertMsg(xcelianResumePassPreviewMainResponse.message);
    }
  }, [xcelianResumePassPreviewMainResponse.isError, xcelianResumePassPreviewMainResponse.message, passReviewCd]);

  React.useEffect(() => {
    if (!xcelianResumePassPreviewMainResponse.isError && xcelianResumePassPreviewMainResponse.message === 'executed' && myProfile.UserTp !== '' && myProfile.UserCd !== '' && passReviewCd === 'MAIN_PREV') {
      setLoadingResume(true);
      setInputPasscodePerv('');
      setLoadingResume(false);
      setPassReviewCd('');
      setIsEResPrev(true);
      dispatch(clearXcelianShareResume());
    }
  }, [xcelianResumePassPreviewMainResponse.isError, xcelianResumePassPreviewMainResponse.message, myProfile.UserTp, myProfile.UserCd, passReviewCd]);

  // handleGetXcelianPreview Get Preview End

  return (
    <div>
      { loadingResume ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="allign-start ml my-2">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/resume.png" alt="batches" />
        <span className="text-lite-grey ml">My Resume</span>
      </div>
      {!isMobileJob ? (
        <Row className="pt-3">
          <Col lg="4">
            <div className="xcelian-right-border">
              <XcelianResumeInput
                handleResumeInfoData={handleResumeInfoData}
                resumePersonalInfo={resumePersonalInfo}
                handleGenderRegister={handleGenderRegister}
                setResumePersonalInfo={setResumePersonalInfo}
                handleUpdatePersonalInfo={handleUpdatePersonalInfo}
                tokenData={tokenData}
                myProfile={myProfile}
                setResumeSkills={setResumeSkills}
                resumeSkills={resumeSkills}
                handleUpdateSkills={handleUpdateSkills}
                handleResumeObjectiveData={handleResumeObjectiveData}
                resumeObjective={resumeObjective}
                handleUpdateObjective={handleUpdateObjective}
                setResumeOrgProj={setResumeOrgProj}
                resumeOrgProj={resumeOrgProj}
                handleUpdateOrgProject={handleUpdateOrgProject}
                setResumeEducation={setResumeEducation}
                resumeEducation={resumeEducation}
                handleUpdateEduction={handleUpdateEduction}
                setResumeCert={setResumeCert}
                resumeCert={resumeCert}
                handleUpdateCertifications={handleUpdateCertifications}
                handleDeleteCertImage={handleDeleteCertImage}
                setResumeLang={setResumeLang}
                resumeLang={resumeLang}
                handleUpdateLanguage={handleUpdateLanguage}
                handleResumeCoverLetterData={handleResumeCoverLetterData}
                resumeCoverLetter={resumeCoverLetter}
                handleUpdateCoverLetter={handleUpdateCoverLetter}
                clickCXComponent={clickCXComponent}
                setClickCXComponent={setClickCXComponent}
                setResumeVideo={setResumeVideo}
                resumeVideo={resumeVideo}
                handleUpdateResumeVideo={handleUpdateResumeVideo}
                loaderVideoResume={loaderVideoResume}
                setLoaderVideoResume={setLoaderVideoResume}
                handleGetXcelianPreview={handleGetXcelianPreview}
                isPasscodePerv={isPasscodePerv}
                setIsPasscodePerv={setIsPasscodePerv}
                handleChangeXcelianPreview={handleChangeXcelianPreview}
                eResumePass={eResumePass}
                setEResumePass={setEResumePass}
                previewResumeCnt={previewResume.resCnt || 0}
              />
            </div>
          </Col>
          <Col lg="8">
            {!isEResPrev
              ? (
                <Row>
                  <Col lg="9">
                    {templates.map((template) => (activeTab === template.id ? (
                    // Render the component using JSX
                      <template.component
                        key={template.id}
                        previewResume={previewResume}
                        myProfile={myProfile}
                      />
                    ) : null))}
                  </Col>
                  <Col lg="3">
                    <div className="xcelian-left-border">
                      <div className="align-center">
                        <span className="text-lite">All Templates</span>
                      </div>
                      <div className="my-3 align-center template-design">
                        {templates.map((template) => (
                          <NavLink
                            key={template.id}
                            className="cursor-pointer"
                            title={template.id}
                            active={activeTab === template.id}
                            onClick={() => setActiveTab(template.id)}
                          >
                            <CardImg src={template.imgSrc} alt="" className="preview-resume-image" />
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              )
              : (
                <Row>
                  <Col lg="12">
                    <div className="align-start">
                      <Button className="button-icon mx-1" onClick={handleCancelXcelianPreview}>
                        <FontAwesomeIcon className="share-icon" icon={faLeftLong} /> BACK
                      </Button>
                    </div>
                  </Col>
                  <Col lg="12">
                    <EResumePreviewTemplate
                      previewResume={previewResume}
                      myProfile={myProfile}
                    />
                  </Col>
                </Row>
              )}

          </Col>
        </Row>
      ) : (
        <Row className="pt-4">
          <Col xs="12">
            <div className="align-start">
              <Button className="button-icon" onClick={toggleResumeTemplate}>
                <CardImg className="recruit-main-header-img-card-none" src="/images/icons/resume-pdf.png" alt="batches" />
                <span className="text-lite-grey ml">Open Templates</span>
              </Button>
              <Offcanvas
                isOpen={isClickClose}
                toggle={toggleResumeTemplate}
              >
                <div className="ml-1">All Templates</div>
                <div className="my-3 align-center template-design">
                  {templates.map((template) => (
                    <NavLink
                      key={template.id}
                      className="cursor-pointer"
                      title={template.id}
                      active={activeTab === template.id}
                      onClick={() => setActiveTab(template.id)}
                    >
                      <CardImg src={template.imgSrc} alt="" className="preview-resume-image" />
                    </NavLink>
                  ))}
                </div>
              </Offcanvas>
            </div>
          </Col>
          <Col xs="12">
            <Accordion
              title="Input Resume"
              defaultOpen={false}
              targetId="myResume"
            >
              <div className="xcelian-right-border">
                <XcelianResumeInput
                  handleResumeInfoData={handleResumeInfoData}
                  resumePersonalInfo={resumePersonalInfo}
                  handleGenderRegister={handleGenderRegister}
                  setResumePersonalInfo={setResumePersonalInfo}
                  handleUpdatePersonalInfo={handleUpdatePersonalInfo}
                  tokenData={tokenData}
                  myProfile={myProfile}
                  setResumeSkills={setResumeSkills}
                  resumeSkills={resumeSkills}
                  handleUpdateSkills={handleUpdateSkills}
                  handleResumeObjectiveData={handleResumeObjectiveData}
                  resumeObjective={resumeObjective}
                  handleUpdateObjective={handleUpdateObjective}
                  setResumeOrgProj={setResumeOrgProj}
                  resumeOrgProj={resumeOrgProj}
                  handleUpdateOrgProject={handleUpdateOrgProject}
                  setResumeEducation={setResumeEducation}
                  resumeEducation={resumeEducation}
                  handleUpdateEduction={handleUpdateEduction}
                  setResumeCert={setResumeCert}
                  resumeCert={resumeCert}
                  handleUpdateCertifications={handleUpdateCertifications}
                  handleDeleteCertImage={handleDeleteCertImage}
                  setResumeLang={setResumeLang}
                  resumeLang={resumeLang}
                  handleUpdateLanguage={handleUpdateLanguage}
                  handleResumeCoverLetterData={handleResumeCoverLetterData}
                  resumeCoverLetter={resumeCoverLetter}
                  handleUpdateCoverLetter={handleUpdateCoverLetter}
                  clickCXComponent={clickCXComponent}
                  setClickCXComponent={setClickCXComponent}
                  setResumeVideo={setResumeVideo}
                  resumeVideo={resumeVideo}
                  handleUpdateResumeVideo={handleUpdateResumeVideo}
                  loaderVideoResume={loaderVideoResume}
                  setLoaderVideoResume={setLoaderVideoResume}
                  handleGetXcelianPreview={handleGetXcelianPreview}
                  isPasscodePerv={isPasscodePerv}
                  setIsPasscodePerv={setIsPasscodePerv}
                  handleChangeXcelianPreview={handleChangeXcelianPreview}
                  eResumePass={eResumePass}
                  setEResumePass={setEResumePass}
                  previewResumeCnt={previewResume.resCnt || 0}
                />
              </div>
            </Accordion>
          </Col>
          <Col xs="12 pt-3">
            {templates.map((template) => activeTab === template.id && (
            <template.component
              key={template.id}
              previewResume={previewResume}
              myProfile={myProfile}
            />
            ))}
          </Col>
          <Offcanvas
            isOpen={isEResPrev}
            toggle={() => setIsEResPrev(!isEResPrev)}
          >
            <div className="ml- 1">
              <EResumePreviewTemplate
                previewResume={previewResume}
                myProfile={myProfile}
              />
            </div>
          </Offcanvas>
        </Row>
      )}
    </div>
  );
};

export default XcelianResumeComponent;
