import React from 'react';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import './template1.scss';
import {
  faBriefcase,
  faCertificate,
  faCircleDot,
  faEnvelope, faGears, faGlobe, faGraduationCap, faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import DownloadTemplate1 from './download';
import { PreviewResumeProps } from '../resume.template.type';
import { formatUserDateNoHr } from '../../../../../../utils/constants';

export const PreviewTemplate1: React.FC<PreviewResumeProps> = ({ previewResume, myProfile }) => (
  <div>
    <Row>
      <Col xs="12">
        <div className="float-right-template-1">
          <DownloadTemplate1 previewResume={previewResume} myProfile={myProfile} />
        </div>
      </Col>
    </Row>
    <Card className="template-card-design">
      <Row className="mx-1 pt-3">
        <Col xs="12 ml">
          <span className="template-header">{myProfile.fNme}{' '}{myProfile.lNme}</span>
        </Col>
        <Col xs="12 ml pt-1">
          <FontAwesomeIcon icon={faEnvelope} className="menu-icon-cc" />
          <span className="template-desc-text ml">{myProfile.cEId}</span>
        </Col>
        <Col xs="12 ml">
          <FontAwesomeIcon icon={faPhone} className="menu-icon-cc" />
          <span className="template-desc-text ml">{myProfile.dlCd} {myProfile.cPh}</span>
        </Col>
      </Row>
      {previewResume && (previewResume.obj !== '' && previewResume.obj !== undefined)
        ? (
          <Row className="mx-1">
            <Col xs="12">
              <div className="pt-5">
                <span className="template-header-content">Career Objective</span>
              </div>
            </Col>
            <Col className="mx-1 pt-2">
              <div className="align-text-justify">
                <span className="template-resume-project-header">{previewResume.obj}</span>
              </div>
            </Col>
          </Row>
        ) : null}
      {previewResume && previewResume.resSkills && previewResume.resSkills.length > 0
        ? (
          <Row className="mx-1">
            <Col xs="12">
              <div className="pt-4">
                <FontAwesomeIcon icon={faGears} className="mr template-about-text" />
                <span className="template-header-content">Skills</span>
              </div>
            </Col>
            <Col className="mx-1 pt-2">
              <Row>
                {previewResume && previewResume.resSkills && previewResume.resSkills.map((skillGroup, skillGroupIndex) => (
                  <div key={`group-${skillGroupIndex.toString()}`} className="my-1">
                    <span className="template-header">
                      <FontAwesomeIcon icon={faDotCircle} className="mr xcelian-resume-project-role-icon" />{skillGroup.sklName}:
                      <span className="ml template-resume-project-header">{skillGroup.skillData.join(', ')}</span>
                    </span>
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        ) : null}
      {previewResume && previewResume.orgData && previewResume.orgData.length > 0
        ? (
          <Row className="mx-1">
            <Col xs="12">
              <div className="pt-4">
                <span className="template-header-content">Professional Experience</span>
              </div>
            </Col>
            <Col className="mx-1 pt-2">
              {previewResume && previewResume.orgData && previewResume.orgData
                .sort((orgA, orgB) => (orgB.isCrnt ? 1 : 0) - (orgA.isCrnt ? 1 : 0)) // Sort to bring currently working entries to the top
                .map((org, orgIndex) => (
                  <div className="mb-4" key={`${orgIndex.toString()}`}>
                    <div className="align-start">
                      <span className="template-about-text">
                        <FontAwesomeIcon icon={faBriefcase} className="mr template-exp-icon" />
                        {org.sDte !== '' ? formatUserDateNoHr(org.sDte) : 'NA'} - {org.isCrnt ? 'Currently Working' : org.eDte !== '' ? formatUserDateNoHr(org.eDte) : 'NA'}
                      </span>
                    </div>
                    <div className="align-start">
                      <span className="template-header">
                        {org.dsg}, {org.org} ({org.orgLoc})
                      </span>
                    </div>
                    <div className="my-2">
                      <div className="my-1">
                        <span className="template-project-exp">Collaborative Projects</span>
                      </div>
                      {org && org.prjs
                        .sort((projA, projB) => (projB.isCrnt ? 1 : 0) - (projA.isCrnt ? 1 : 0)) // Sort to bring currently working entries to the top
                        .map((project, projIndex) => (
                          <div className="mb-1" key={`${projIndex.toString()}`}>
                            <div className="align-start">
                              <span className="template-header">
                                {project.prjNme} {project.isCrnt ? <span className="template-about-text-current">- Currently working</span> : null}
                              </span>
                            </div>
                            <div className="align-start align-text-justify">
                              <span className="template-resume-project-header">
                                {project.desc}
                              </span>
                            </div>
                            <div className="align-start pt-3">
                              <span className="text-lite">Skills Achived: </span>
                              {org.prjs[projIndex].skls.map((skill, index) => (
                                <span key={`prjskls-${index.toString()}`} className="template-resume-project-header ml">
                                  <FontAwesomeIcon icon={faCircleDot} className="template-resume-project-role-icon mx-1" />
                                  {skill}
                                </span>
                              ))}
                            </div>
                            <div className="pt-2">
                              <div className="">
                                <span className="text-lite mr">My Roles & Responsibilities</span>
                              </div>
                              <div className="ml">
                                {org.prjs[projIndex].myRls.map((role, index) => (
                                  <div key={`prjrols-${index.toString()}`} className="align-text-justify my-1">
                                    <span className="xcelian-resume-project-header">
                                      <FontAwesomeIcon icon={faCircleDot} className="xcelian-resume-project-role-icon mr" />
                                      {role}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        ) : null}
      {previewResume && previewResume.certData && previewResume.certData.length > 0
        ? (
          <Row className="mx-1">
            <Col xs="12">
              <div className="pt-4">
                <span className="template-header-content">Certifications & Achievements</span>
              </div>
            </Col>
            <Col className="mx-1 pt-2">
              {previewResume && previewResume.certData && previewResume.certData
                .map((cert, certIndex) => (
                  <div className="mb-3" key={`${certIndex.toString()}`}>
                    <div className="align-start">
                      <span className="template-header">
                        <FontAwesomeIcon icon={faCertificate} className="mr template-exp-icon" />
                        {cert.certNme}
                      </span>
                    </div>
                    {cert.sDte !== '' && cert.eDte !== ''
                      ? (
                        <div className="align-start ml">
                          <span className="template-resume-project-header">
                            {cert.sDte !== '' ? formatUserDateNoHr(cert.sDte) : 'NA'} - {cert.eDte !== '' ? formatUserDateNoHr(cert.eDte) : 'NA'}
                          </span>
                        </div>
                      ) : null}
                    <div className="align-start ml">
                      <span className="template-resume-project-header">
                        {cert.desc}
                      </span>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        ) : null}
      {previewResume && previewResume.langData && previewResume.langData.length > 0
        ? (
          <Row className="mx-1">
            <Col xs="12">
              <div className="pt-4">
                <span className="template-header-content">Language Proficiency</span>
              </div>
            </Col>
            <Col className="mx-1 pt-2">
              {previewResume && previewResume.langData && previewResume.langData
                .map((lang, langIndex) => (
                  <div className="mb-3" key={`${langIndex.toString()}`}>
                    <div className="align-start">
                      <span className="template-header">
                        <FontAwesomeIcon icon={faGlobe} className="mr template-exp-icon" />
                        {lang.langNme}
                      </span>
                      <span className="mx-2 text-lite">-</span>
                      <span className="template-resume-project-header">
                        {lang.level}
                      </span>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        ) : null}
      {previewResume && previewResume.eduData && previewResume.eduData.length > 0
        ? (
          <Row className="mx-1">
            <Col xs="12">
              <div className="pt-4">
                <span className="template-header-content">Education</span>
              </div>
            </Col>
            <Col className="mx-1 pt-2">
              {previewResume && previewResume.eduData && previewResume.eduData
                .map((edu, eduIndex) => (
                  <div className="mb-3" key={`${eduIndex.toString()}`}>
                    <div className="align-start">
                      <span className="template-header">
                        <FontAwesomeIcon icon={faGraduationCap} className="mr template-exp-icon" />
                        {edu.cmpsNme}
                      </span>
                    </div>
                    <div className="align-start ml">
                      <span className="template-resume-project-header">
                        {edu.spec}
                      </span>
                    </div>
                    <div className="align-start ml">
                      <span className="template-resume-project-header">
                        {edu.sDte !== '' ? formatUserDateNoHr(edu.sDte) : 'NA'} - {edu.eDte !== '' ? formatUserDateNoHr(edu.eDte) : 'NA'}
                      </span>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        ) : null}
      <Row className="mx-1">
        {previewResume
  && previewResume.dob !== '' && previewResume.dob !== undefined
  && previewResume.addr !== '' && previewResume.addr !== undefined
  && previewResume.city !== '' && previewResume.city !== undefined
  && previewResume.st !== '' && previewResume.st !== undefined
  && previewResume.pCd !== '' && previewResume.pCd !== undefined
          ? (
            <Col xs="12">
              <div className="pt-4">
                <span className="template-header-content">Personal Information</span>
              </div>
            </Col>
          ) : null}
        {previewResume.dob !== '' && previewResume.dob !== undefined
          ? (
            <Col xs="12">
              <div className="pt-1">
                <span className="text-lite">DOB: </span>
                {/* <span className="template-resume-project-header">{previewResume.dob !== '' ? formatUserDateNoHr(previewResume.dob) : 'NA'}</span> */}
              </div>
            </Col>
          ) : null}
        {previewResume
  && previewResume.addr !== '' && previewResume.addr !== undefined
  && previewResume.city !== '' && previewResume.city !== undefined
  && previewResume.st !== '' && previewResume.st !== undefined
  && previewResume.pCd !== '' && previewResume.pCd !== undefined
          ? (
            <Col xs="12">
              <div className="pt-1">
                <span className="text-lite">Address: </span>
                <span className="template-resume-project-header">{previewResume.addr}, {previewResume.city}, {previewResume.st} - {previewResume.pCd}</span>
              </div>
            </Col>
          ) : null}
      </Row>
      <div className="pt-4" />
    </Card>
  </div>
);
export default PreviewTemplate1;
