import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardImg,
  Col, Row, TabContent, TabPane,
} from 'reactstrap';
import '../admin.scss';
import { ListPostResult } from '../../../../../services/campus/dashboard/post/get-all-posts/list-post.types';
import { getPostListReq } from '../../../../../store/campusXcel/actions';
import { errorAlert } from '../../../../../utils/alert';
import { APIHeader, ApiLIMIT, UserType } from '../../../../../utils/constants';
import LoaderData from '../../../../../utils/loader';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../utils/service/localstorage-service';
import College from '../../common/college/college.component';

export const CMPSAdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userProfile = getUserProfileLocalStorage();
  const [alertMsg, setAlertMsg] = useState('');
  const [campusId, setCampusId] = useState('');
  const tokenData = getTokenFromLocalStorage();
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [activeTab] = React.useState('college');
  const [loaderDashB, setLoaderDashB] = useState(false);
  const postListData = useSelector((state: RootState) => state.campusXcel.getPostList);
  const [prevPostDataLengthRef, setPrevPostDataLengthRef] = useState(1);
  const [myPost, setMyPost] = useState<ListPostResult>({
    tCnt: 0,
    fCnt: 0,
    postData: [],
    isError: false,
    message: '',
  });

  React.useEffect(() => {
    setCampusId(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
  }, [userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  React.useEffect(() => {
    if (activeTab === 'college' && prevPostDataLengthRef === 1 && campusId !== '') {
      setLoaderDashB(true);
      dispatch(getPostListReq({
        requestType: APIHeader.REQ_GET_ALL_POST,
        limit: ApiLIMIT.LIMIT,
        uTp: UserType.CMPS,
        searchData: '',
        token: tokenData,
        acdId: campusId,
      }));
    }
  }, [activeTab, prevPostDataLengthRef, campusId]);

  React.useEffect(() => {
    if (postListData.isError && postListData.message !== '') {
      setLoaderDashB(false);
      setAlertMsg(postListData.message);
      setPrevPostDataLengthRef(0);
      setErrorShowAlert(true);
    }
  }, [postListData.isError, postListData.message]);

  React.useEffect(() => {
    if (!postListData.isError && postListData.message !== '') {
      Promise.resolve(
        postListData.postData !== undefined
          ? setMyPost(postListData)
          : setMyPost({ ...myPost, postData: [] }),
      ).then(() => {
        setPrevPostDataLengthRef(0);
      }).then(() => {
        setLoaderDashB(false);
      });
    }
  }, [postListData.isError, postListData.message]);

  return (
    <div className="border-tabs margin-mob-top">
      { loaderDashB ? <LoaderData /> : null}
      {/* {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null} */}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="allign-start ml">
        <h2 className="text-line"><CardImg className="cx-main-header-img-card" src="/images/icons/dashboard.png" alt="batches" /> My Campus</h2>
      </div>
      <Row className="mx-1 pt-2">
        <Col lg="8" xs="12">
          <div className="pt-1">
            {/* <Nav className="cx-admin-tabs">
              <NavItem className={activeTab === 'college' ? 'cx-tab active' : 'cx-tab'} onClick={handleGetCollege}>
                <CardImg src="/images/icons/graduate-cap.png" className="pt-2" alt="Icon 1" />
                Campus
              </NavItem>
            </Nav> */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="college">
                <College myPost={myPost} setPrevPostDataLengthRef={setPrevPostDataLengthRef} />
              </TabPane>
            </TabContent>
          </div>
        </Col>
        <Col lg="4" xs="12">
          <div className="align-center my-2">
            Advertisements info
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CMPSAdminDashboard;
