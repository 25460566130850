import React from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import './cx.scss';
import { WDSFooter } from '../../components/public/home/footer';
import { PublicCXHomeComponent } from '../../components/public/home/campus-home.component';
import IntroATS from '../../components/public/ats/ats-home';
import { LoginXcelian } from '../../components/public/login/xcelian/login';
import IntroJobsCampusXcel from '../../components/public/jobs/jobs-intro';
import RegisterXcelian from '../../components/public/register/xcelian/register-xcelian';
import { IntroCampus } from '../../components/public/campus/campus-home';
import { LoginCampusXcel } from '../../components/public/login/campus/login';
import { RegisterCampusXcel } from '../../components/public/register/campus/register-campus';
import { LoginRecruiter } from '../../components/public/login/staffing/recruiter-login';
import { NewsLetterComponent } from '../../components/public/newsletter/newsletter.component';
import PublicNewsShare from '../../components/public/newsletter/public.news.share';
import PublicJobShare from '../../components/public/jobs/public.job.share';
import PublicListInterviewComponent from '../../components/public/resume/resume.component';
import EResumeShareComponent from '../../components/public/e-resume/e.resume.component';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="" element={<PublicCXHomeComponent />} />
    <Route path="jobs" element={<IntroJobsCampusXcel />} />
    <Route path="job-details/:jobCd/:jobId" element={<PublicJobShare />} />
    <Route path="interview/:jobOrg/:jobId/:uniqCd" element={<PublicListInterviewComponent />} />
    <Route path=":resCd/:xclnTp/:xclnId" element={<EResumeShareComponent />} />
    <Route path="xcelian/login" element={<LoginXcelian />} />
    <Route path="xcelian/register" element={<RegisterXcelian />} />
    <Route path="staffing" element={<IntroATS />} />
    <Route path="recruit/login" element={<LoginRecruiter />} />
    <Route path="campus" element={<IntroCampus />} />
    <Route path="campus/login" element={<LoginCampusXcel />} />
    <Route path="campus/register" element={<RegisterCampusXcel />} />
    <Route path="news" element={<NewsLetterComponent />} />
    <Route path="news-article/:newsTp/:newsId" element={<PublicNewsShare />} />
  </Routes>
);

export const PublicCXComponent: React.FC = () => (
  <div className="page-container">
    <div className="content">
      <ExclusiveRoutes />
    </div>
    <footer className="footer">
      <WDSFooter />
    </footer>
  </div>
);

export default PublicCXComponent;
