import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicCXComponent from '../../scenes/public/cx.component';
import { CampusUserPrivateComponent } from '../../scenes/exclusive/campus.user.component';
import { CampusAdminPrivateComponent } from '../../scenes/exclusive/campus.admin.component';
import { CXUnauthorized } from '../../scenes/unauthorized/unauthorized.component';
import { SuperAdminPrivateComponent } from '../../scenes/exclusive/super.admin.component';
import { LoginSAdmin } from '../public/login/sadmin/login';
import { RecruitAdminPrivateComponent } from '../../scenes/exclusive/recruit.admin.component';
import XcelianPrivateComponent from '../../scenes/exclusive/xcelian.component';
import { PrivPolicy } from '../public/home/priv-policy';
import { TermsAndCond } from '../public/home/tandc';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/*" element={<PublicCXComponent />} />
    {/* <Route path="/recruit/exclusive/*" element={<RecruiterPrivateComponent />} /> */}
    <Route path="/recruit/admin/*" element={<RecruitAdminPrivateComponent />} />
    <Route path="/campus/exclusive/*" element={<CampusUserPrivateComponent />} />
    <Route path="/xcelian/exclusive/*" element={<XcelianPrivateComponent />} />
    <Route path="/cx/admin/*" element={<CampusAdminPrivateComponent />} />
    <Route path="/unauthorized" element={<CXUnauthorized />} />
    <Route path="/t&c" element={<TermsAndCond />} />
    <Route path="/priv-policies" element={<PrivPolicy />} />
    <Route path="/unauthorized" element={<CXUnauthorized />} />
    <Route path="/sadmin/login" element={<LoginSAdmin />} />
    <Route path="/wds/sadmin/*" element={<SuperAdminPrivateComponent />} />
  </Routes>
);

export default AppBase;
