import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Input, InputGroup, Label, Row,
} from 'reactstrap';
import {
  clearLoginToken,
  getClientListReq,
  getLoginToken,
} from '../../../../../../store/campusXcel/actions';
import { DialCountrInfo } from '../../../../../../utils/MetaData/country';
import { RecruiterProps } from '../../onboard.type';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { AllClientsResult } from '../../../../../../services/sadmin/list-client/list-client.types';

export const AddRecruitUser: React.FC<RecruiterProps> = ({
  ordTp, tabTp, clientTp, recruiter, setRecruiter,
}) => {
  const dispatch = useDispatch();
  const [loaderListClient, setLoaderListClient] = useState(false);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const clientListResponse = useSelector((state: RootState) => state.campusXcel.getClientList);
  const [clientList, setClientList] = React.useState<AllClientsResult>({
    tCnt: 0,
    fCnt: 0,
    clntData: [],
    lastEvalKey: {
      ClntTp: '',
      ClntId: '',
    },
  });

  React.useEffect(() => {
    if (ordTp === 'recruit' && tabTp === 'addRecruitUser' && clientTp === 'RCRT') {
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  }, [ordTp, tabTp, clientTp]);

  React.useEffect(() => {
    if (ordTp === 'recruit' && tabTp === 'addRecruitUser' && clientTp === 'RCRT' && !loginTokenData.isError && loginTokenData.token !== '') {
      setLoaderListClient(true);
      dispatch(getClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.RCRT,
        limit: 500,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        clntTp: clientTp,
        isMeta: true,
        token: loginTokenData.token,
        clntSts: 'ACTIVE',
      }));
    }
  }, [ordTp, tabTp, clientTp, loginTokenData.token]);

  React.useEffect(() => {
    if (!clientListResponse.isError && clientListResponse.message === 'executed') {
      setLoaderListClient(false);
      setClientList(clientListResponse.data);
      dispatch(clearLoginToken());
    }
  }, [clientListResponse.isError, clientListResponse.data]);

  const handleChangeClient = (event: any) => {
    setRecruiter({ ...recruiter, [event.target.name]: event.target.value });
  };

  const handleCheckedChange = (e: any) => {
    setRecruiter({ ...recruiter, [e.target.name]: e.target.checked });
  };

  const handleApproval = (info_data: string) => {
    setRecruiter({ ...recruiter, lgnSts: info_data });
  };

  const clntUserNameoptions = clientList.clntData.map((client) => ({
    label: `${client.nme}`,
    cTp: `${client.ClntTp}`,
    cId: `${client.ClntId}`,
  }));
  const selectedClientNameOptions = clntUserNameoptions && clntUserNameoptions.find(
    (option) => (option.cId === recruiter.ClntId),
  );

  const dialNameoptions = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));

  const selectedDialNameOptions = dialNameoptions && dialNameoptions.find(
    (option) => option.value === recruiter.dlCd,
  );

  const handleSelectDialData = (e:any) => {
    setRecruiter({ ...recruiter, dlCd: e.value });
  };

  const handleSelectCollegeData = (selectedOption: any) => {
    if (selectedOption) {
      setRecruiter({
        ...recruiter,
        ClntTp: selectedOption.cTp,
        ClntId: selectedOption.cId,
      });
    }
  };

  return (
    <div className="pt-3">
      <div className="mb-3 align-center">
        New Recruiter
      </div>
      <Row className="align-center pt-3">
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register">
            <Select
              value={selectedClientNameOptions}
              options={clntUserNameoptions}
              onChange={handleSelectCollegeData}
              styles={{
                control: (base: any) => ({
                  ...base,
                  '&:hover': { borderColor: '#8B0000' },
                  border: '1px solid #DDE0E4',
                  minHeight: '50px',
                  borderRadius: '10px',
                  padding: 1,
                  textAlign: 'left',
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#575656',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                  '&:focus': {
                    borderColor: '#E1EDF8',
                    boxShadow: 'none',
                    color: '#575656',
                    background: '#fff',
                  },
                }),
                option: (provided: any, state: { isSelected: any; }) => ({
                  ...provided,
                  color: state.isSelected ? '#383838' : '#212121',
                  padding: 15,
                  textAlign: 'left',
                  background: state.isSelected ? '#FAFCFB' : '#fff',
                  '&:hover': {
                    background: '#FAFCFB',
                  },
                }),
              }}
              noOptionsMessage={() => 'No Organization Found'}
              placeholder="Select Recruiter Organization..."
              isLoading={loaderListClient}
            />
          </div>
        </Col>
        <Col lg="11" xs="12">
          <Row>
            <Col lg="7" xs="12">
              <div className="text-right-login-campus-register pt-4">
                <Input
                  placeholder="First Name"
                  type="text"
                  name="fNme"
                  required
                  value={recruiter.fNme}
                  onChange={handleChangeClient}
                  className="campus-input"
                />
              </div>
            </Col>
            <Col lg="5" xs="12">
              <div className="text-right-login-campus-register pt-4">
                <Input
                  placeholder="Last Name"
                  type="text"
                  name="lNme"
                  required
                  value={recruiter.lNme}
                  onChange={handleChangeClient}
                  className="campus-input"
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <Input
              placeholder="Contact Email"
              type="text"
              name="eId"
              required
              value={recruiter.eId}
              onChange={handleChangeClient}
              className="campus-input"
            />
          </div>
        </Col>
        <Col lg="11" xs="12">
          <div className="text-right-login-campus-register pt-4 mx-1">
            <InputGroup>
              <Select
                value={recruiter.dlCd === '' ? null : selectedDialNameOptions}
                options={dialNameoptions}
                onChange={handleSelectDialData}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    // border: '1px solid #F9CB9C',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Dial Code Found'}
              />
              <Input
                placeholder="Phone Number"
                type="number"
                required
                name="ph"
                value={recruiter.ph}
                onChange={handleChangeClient}
                className="campus-input mx-1"
              />
            </InputGroup>
          </div>
        </Col>
        <Col lg="11" className="pt-4">
          <Row className="mx-2 bg-client-check-register">
            <Col lg="12">
              <div className="mb-2 align-start">
                <span className="text-lite-grey">User Status</span>
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="align-start">
                <Input
                  type="radio"
                  name="lgnSts"
                  checked={recruiter.lgnSts === 'APPR'}
                  className="campus-radio-box cursor-pointer"
                  onChange={() => handleApproval('APPR')}
                />
                <Label check className="form-check-text ml">Approve</Label>
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="align-start">
                <Input
                  type="radio"
                  name="lgnSts"
                  checked={recruiter.lgnSts === 'NACC'}
                  className="campus-radio-box cursor-pointer"
                  onChange={() => handleApproval('NACC')}
                />
                <Label check className="form-check-text ml">Block</Label>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg="11" className="pt-4 ml-1">
          <div className="align-start">
            <Label check>
              <Input
                type="checkbox"
                name="isAdm"
                onChange={handleCheckedChange}
                className="campus-check-box ml pt-2 cursor-pointer"
              />{' '}
              <span className="text-lite-grey">Would you like to grant admin privileges to the user?</span>
            </Label>
          </div>
        </Col>
        <Col lg="11" className="pt-4 ml-1">
          <div className="align-start">
            <Label check>
              <Input
                type="checkbox"
                name="isAcs"
                onChange={handleCheckedChange}
                className="campus-check-box ml pt-2 cursor-pointer"
              />{' '}
              <span className="text-lite-grey">Would you like to grant access to the user?</span>
            </Label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddRecruitUser;
